<template>
    <dialog-basic :value="shows" @input="(shows) => (this.shows = shows)" width="auto">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0 justify-center">게시글 삭제</v-card-title>
        </div>
        <v-card-text class="txt txt-sm pa-0 text-center">선택한 게시글을 삭제하시겠습니까?</v-card-text>

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row justify="end" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="() => (shows = false)">취소</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, disabled }" class="v-btn--width-fixed" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import api from '@/api';
import { btn_primary, btn_secondary } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

export default {
    components: {
        DialogBasic,
    },
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,

        btn_primary,
        btn_secondary,
    }),
    computed: {
        disabled() {
            return this.selected.length < 1;
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            else this.loading = true;

            for (const board of this.selected) {
                try {
                    await api.v1.boards.put({
                        _id: board._id,
                        meta: {
                            ...(board?.meta || {}),
                            mini: {
                                ...(board?.meta?.mini || {}),
                                code: null,
                            },
                        },
                    });
                } catch {}
            }

            alert("삭제되었습니다");
            this.$emit("updated");

            this.loading = false;
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>