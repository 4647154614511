var render = function render(){
  var _vm$query, _vm$query2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "hot-best-card fill-height d-flex align-center px-12px px-lg-36px py-20px",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-bold"
  }, [_vm._v("BEST")])])], 1)], 1), (_vm$query = _vm.query) !== null && _vm$query !== void 0 && _vm$query._seller ? _c('best-list-card', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [[_c('shop-product-items', _vm._l(products, function (product, index) {
          return _c('shop-product-item', {
            key: index,
            attrs: {
              "value": product,
              "sm": "6",
              "md": "6",
              "lg": "6",
              "rank": index + 1
            }
          });
        }), 1)]];
      }
    }], null, false, 1830621266)
  }, 'best-list-card', {
    query: {
      _seller: (_vm$query2 = _vm.query) === null || _vm$query2 === void 0 ? void 0 : _vm$query2._seller
    },
    limit: 2
  }, false)) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }