<template>
    <component :is="componentName" v-bind="$attrs">
        <slot v-bind="{ services, summary, loading }" />
    </component>
</template>

<script>
import api from "@/api";

export default {
    props: {
        query: { type: Object, default: () => ({}) },

        sort: { type: Object, default: () => ({ createdAt: -1 }) },
        skip: { type: [Number, String], default: 0 },
        limit: { type: [Number, String], default: 10 },

        componentName: { type: String, default: "v-card" },
    },
    data: () => ({
        services: [],
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        headers() {
            let {
                sort: { ...sort },
                skip,
                limit,
            } = this;

            try {
                sort = JSON.stringify(sort);
            } catch {
                sort = sort;
            }

            return { sort, skip, limit };
        },
        params() {
            let { ...query } = this.query;
            return { ...query };
        },
        // items() {
        //     return this.products.map();
        // },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { headers, params } = this;
                const { services, summary } = await api.v1.shop.services.gets({
                    headers,
                    params,
                });

                this.services = services;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        headers() {
            this.search();
        },
        params() {
            this.search();
        },
    },
};
</script>


<style lang="scss" scoped>
</style>