var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    staticClass: "notice-slide",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.boards, function (board) {
    return [_c('swiper-slide', {
      key: board._id
    }, [_c('slides-item', _vm._b({}, 'slides-item', {
      board
    }, false))], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  }), _c('div', {
    staticClass: "swiper-button-prev"
  }), _c('div', {
    staticClass: "swiper-button-next"
  })])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }