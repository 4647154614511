var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "ui-components-wrap"
  }, [_c('h3', [_vm._v("{breakpoint} : sm"), _c('small', [_vm._v("(min-width:576px)")]), _vm._v(", md"), _c('small', [_vm._v("(min-width:768px)")]), _vm._v(", lg"), _c('small', [_vm._v("(min-width:1024px)")]), _vm._v(", xl"), _c('small', [_vm._v("(min-width:1200px)")])]), _c('h3', [_vm._v("{size} : 2px씩 늘어납니다")]), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Colors")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px primary white--text"
  }, [_vm._v("primary")]), _c('div', {
    staticClass: "pa-10px primary lighten-5"
  }, [_vm._v("ligthen-5")]), _c('div', {
    staticClass: "pa-10px primary lighten-4"
  }, [_vm._v("ligthen-4")]), _c('div', {
    staticClass: "pa-10px primary lighten-3"
  }, [_vm._v("ligthen-3")]), _c('div', {
    staticClass: "pa-10px primary lighten-2 white--text"
  }, [_vm._v("ligthen-2")]), _c('div', {
    staticClass: "pa-10px primary lighten-1 white--text"
  }, [_vm._v("ligthen-1")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px secondary"
  }, [_vm._v("secondary")]), _c('div', {
    staticClass: "pa-10px secondary lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px secondary lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px secondary lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px secondary lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px secondary lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px secondary darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px secondary darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px secondary darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px secondary darken-4"
  }, [_vm._v("darken-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px accent1"
  }, [_vm._v("accent1")]), _c('div', {
    staticClass: "pa-10px accent2"
  }, [_vm._v("accent2")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px success"
  }, [_vm._v("success")]), _c('div', {
    staticClass: "pa-10px warning"
  }, [_vm._v("warning")]), _c('div', {
    staticClass: "pa-10px error"
  }, [_vm._v("error")]), _c('div', {
    staticClass: "pa-10px info"
  }, [_vm._v("info")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px grey"
  }, [_vm._v("grey")]), _c('div', {
    staticClass: "pa-10px grey lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px grey lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px grey lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px grey lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px grey lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px grey darken-1 white--text"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px grey darken-2 white--text"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px grey darken-3 white--text"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px grey darken-4 white--text"
  }, [_vm._v("darken-4")])])], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Typography")]), _c('br'), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Font Family")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--lg txt--dark"
  }, [_vm._v("한글 폰트")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--lg txt--dark font-secondary"
  }, [_vm._v("English Font (class=\"font-secondary\")")])])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Tit Wrap")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("tit-wrap--xs")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("tit-wrap--sm")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("tit-wrap default")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("tit-wrap--lg")])])])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Tit")]), _c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("tit--xxs")]), _c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("tit--xs")]), _c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("tit--sm")]), _c('h3', {
    staticClass: "tit"
  }, [_vm._v("tit Default")]), _c('h3', {
    staticClass: "tit tit--lg"
  }, [_vm._v("tit--lg")]), _c('h3', {
    staticClass: "tit tit--xl"
  }, [_vm._v("tit--xl")])])], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Txt Color")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "txt txt--lg"
  }, [_vm._v("txt Default")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "txt txt--lg txt--dark"
  }, [_vm._v("txt--Dark")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "txt txt--lg txt--light primary"
  }, [_vm._v("txt--light")])])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Button")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("btn_primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("btn_secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("btn_tertiary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_quaternary), false), [_vm._v("btn_quaternary")])], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Button Height")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("xx-small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("x-small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("Defalut")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("x-large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("xx-large")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Button Width Fixed")]), _c('p', {
    staticClass: "txt txt--lg"
  }, [_vm._v("class=\"v-btn--width-fixed\"")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("xx-small")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("x-small")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("small")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("Defalut")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("large")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("x-large")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("xx-large")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Button Txt")]), _c('p', {
    staticClass: "txt txt--lg"
  }, [_vm._v("v-bind=\"{ ...btn_txt }\"")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("Button Icon")]), _c('p', {
    staticClass: "txt txt--lg"
  }, [_vm._v("v-bind=\"{ ...btn_icon }\"")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Image Icon "), _c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-img', {
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-home.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" v-icon "), _c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small"
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large"
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("home")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("v-chip")]), _c('v-row', [_c('v-col', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_primary), false), [_vm._v("chip_primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_primary2), false), [_vm._v("chip_primary2")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("chip_secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary2), false), [_vm._v("chip_secondary2")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary3), false), [_vm._v("chip_secondary3")])], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Input")]), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("v-text-field")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "attrs_input"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "attrs_input_secondary"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("v-textarea")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "attrs_input"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "attrs_input_secondary"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input_secondary, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', {
    staticClass: "grey--text"
  }, [_vm._v("v-select")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "attrs_input"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "attrs_input_secondary"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input_secondary), false))], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("v-radio")]), _c('v-row', [_c('v-col', [_c('v-radio-group', {
    attrs: {
      "row": ""
    }
  }, [_c('v-radio', _vm._b({
    attrs: {
      "label": "Radio Primary",
      "value": "a"
    }
  }, 'v-radio', Object.assign({}, _vm.radio_primary), false)), _c('v-radio', _vm._b({
    attrs: {
      "label": "Radio Primary",
      "value": "b"
    }
  }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("v-checkbox")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "checkbox_primary",
      "value": "",
      "input-value": "true"
    }
  }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("v-rating")]), _c('v-rating', _vm._b({}, 'v-rating', Object.assign({}, _vm.attrs_rating), false)), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("v-switch")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "attrs_switch"
    }
  }, 'v-switch', Object.assign({}, _vm.attrs_switch), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Tab")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    }
  }, [_c('tab-primary', [_vm._v("Tab Primary")]), _c('tab-primary', [_vm._v("Tab Primary")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "grow": ""
    }
  }, [_c('tab-secondary', [_vm._v("Tab Secondary")]), _c('tab-secondary', [_vm._v("Tab Secondary")]), _c('tab-secondary', [_vm._v("Tab Secondary")]), _c('tab-secondary', [_vm._v("Tab Secondary")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "hide-slider": ""
    }
  }, [_c('tab-tertiary', [_vm._v("Tab Tertiary")]), _c('tab-tertiary', [_vm._v("Tab Tertiary")]), _c('tab-tertiary', [_vm._v("Tab Tertiary")]), _c('tab-tertiary', [_vm._v("Tab Tertiary")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20px"
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("SNB")])]), _c('snb'), _c('v-divider', {
    staticClass: "my-20px"
  })], 1)]), _c('v-container', [_c('h1', {
    staticClass: "secondary--text"
  }, [_vm._v("Sub Visual")]), _c('p', {
    staticClass: "txt txt--lg"
  }, [_vm._v("client-page > template#subHead")])]), _c('sub-visual-primary', {
    attrs: {
      "tit": "sub-visual-priamry",
      "bg": "/images/mypage/visual.jpg"
    },
    scopedSlots: _vm._u([{
      key: "path",
      fn: function () {
        return [_c('v-breadcrumbs', {
          attrs: {
            "items": _vm.breadcrumbsItems
          },
          scopedSlots: _vm._u([{
            key: "divider",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-chevron-right")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('sub-visual-secondary', {
    attrs: {
      "tit": "sub-visual-secondary",
      "bg": "/images/mypage/visual.jpg",
      "mobileBg": "/images/mypage/visual.jpg"
    },
    scopedSlots: _vm._u([{
      key: "path",
      fn: function () {
        return [_c('v-breadcrumbs', {
          attrs: {
            "items": _vm.breadcrumbsItems
          },
          scopedSlots: _vm._u([{
            key: "divider",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-chevron-right")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('sub-visual-secondary', {
    attrs: {
      "tit": "sub-visual-secondary (tabs)",
      "bg": "/images/mypage/visual.jpg",
      "mobileBg": "/images/mypage/visual.jpg"
    },
    scopedSlots: _vm._u([{
      key: "path",
      fn: function () {
        return [_c('v-breadcrumbs', {
          attrs: {
            "items": _vm.breadcrumbsItems
          },
          scopedSlots: _vm._u([{
            key: "divider",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-chevron-right")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "tab",
      fn: function () {
        return [_c('v-tabs', {
          attrs: {
            "grow": ""
          }
        }, [_c('tab-primary', [_vm._v("TAB1")]), _c('tab-primary', [_vm._v("TAB2")]), _c('tab-primary', [_vm._v("TAB3")]), _c('tab-primary', [_vm._v("TAB4")]), _c('tab-primary', [_vm._v("TAB5")])], 1)];
      },
      proxy: true
    }])
  }), _c('sub-visual-tertiary', {
    attrs: {
      "tit": "sub-visual-tertiary",
      "bg": "/images/mypage/visual.jpg",
      "mobileBg": "/images/mypage/visual.jpg"
    },
    scopedSlots: _vm._u([{
      key: "path",
      fn: function () {
        return [_c('v-breadcrumbs', {
          attrs: {
            "items": _vm.breadcrumbsItems
          },
          scopedSlots: _vm._u([{
            key: "divider",
            fn: function () {
              return [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-chevron-right")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-sheet', {
    attrs: {
      "height": "200"
    }
  })], 1), _c('v-container', [_c('v-divider', {
    staticClass: "my-20px"
  })], 1), _c('page-section', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("page-section")];
      },
      proxy: true
    }])
  }, [_c('v-sheet', {
    attrs: {
      "height": "100px",
      "color": "secondary"
    }
  })], 1), _c('page-section', {
    attrs: {
      "containerSize": "container--lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("page-section(containerSize=\"container--lg\")")];
      },
      proxy: true
    }])
  }, [_c('v-sheet', {
    attrs: {
      "height": "100px",
      "color": "secondary"
    }
  })], 1), _c('page-section-wide', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("page-section-wide")];
      },
      proxy: true
    }])
  }, [_c('v-sheet', {
    attrs: {
      "height": "100px",
      "color": "secondary"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }