<template>
    <mini-shop-page v-slot="{ user }">
        <v-sheet class="mb-16px mb-20px">
            <v-row justify="space-between" align="center" class="row--xxs">
                <v-col cols="auto">
                    <category-sort />
                </v-col>
                <v-col cols="auto">
                    <category-search />
                </v-col>
            </v-row>
        </v-sheet>

        <div class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--xxs">{{ category?.name }}</h2>
        </div>

        <div class="product-lists">
            <div class="product-lists-body">
                <category-list-card
                    v-if="user?._id && category"
                    v-bind="{
                        _seller: user?._id,
                        _category: category?._id,
                        skip,
                        limit,
                    }"
                    v-slot="{ products, summary }"
                >
                    <template>
                        <shop-product-items>
                            <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="3" />
                        </shop-product-items>
                    </template>

                    <div class="product-lists__pagination">
                        <v-pagination
                            v-model="page"
                            :length="Math.ceil(summary.totalCount / limit) || 1"
                            :total-visible="11"
                            @input="
                                (page) => {
                                    $router.push({ query: { ...$route.query, page } });
                                }
                            "
                        />
                    </div>
                </category-list-card>
            </div>
        </div>
    </mini-shop-page>
</template>

<script>
import { mapState } from "vuex";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import CategorySort from "@/components/client/miniShop/category/category-sort.vue";
import CategorySearch from "@/components/client/miniShop/category/category-search.vue";

import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import CategoryListCard from "@/components/client/miniShop/list/category-list-card.vue";

import PaginationComponent from "@/components/client/pagination-component.vue";

export default {
    components: {
        MiniShopPage,

        CategorySort,
        CategorySearch,

        ShopProductItems,
        ShopProductItem,

        PaginationComponent,
        CategoryListCard,
    },
    data: () => ({
        products: [],

        searchValue: null,
        limit: 8,
        summary: {
            totalCount: 0,
        },
    }),
    computed: {
        ...mapState(["categories", "areCategoriesLoaded"]),
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode);
        },
        page() {
            return +(this.$route.query.page || 1);
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            return this.$route.query.sort || "lastestDesc";
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    methods: {
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
    watch: {
        "$route.query"() {
            if (!this.$route.query.sort) {
                this.$router.replace({ query: { ...this.$route.query, sort: "lastestDesc" } });
            }
        },
    },
};
</script>