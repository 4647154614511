<template>
    <v-sheet v-if="value">
        <v-row class="ma-n8px ma-lg-n24px">
            <v-col cols="auto" class="pa-8px pa-lg-24px">
                <div class="profile-avatar">
                    <!-- S: Mobile Avatar -->
                    <v-avatar size="120" class="d-lg-none">
                        <v-img v-if="value?.thumb" :src="value?.thumb?.src"></v-img>
                        <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                    </v-avatar>
                    <!-- E: Mobile Avatar -->
                    <!-- S: PC Avatar -->
                    <v-avatar size="160" class="d-none d-lg-inline-flex">
                        <v-img v-if="value?.thumb" :src="value?.thumb?.src"></v-img>
                        <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                    </v-avatar>
                    <!-- E: PC Avatar -->
                    <mini-profile-edit v-if="isMe" :value="value" @input="emit">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" elevation="4" icon class="v-size--xx-small white">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                    </mini-profile-edit>
                </div>
            </v-col>
            <v-col class="pa-8px pa-lg-24px">
                <v-row class="ma-n4px ma-lg-n8px" align="center">
                    <v-col cols="12" lg="auto" class="pa-4px pa-lg-8px">
                        <!-- 닉네임 -->
                        <div class="tit tit--xxs">{{ value?.nickname }}</div>
                    </v-col>
                    <v-col cols="auto" order-lg="3" class="pa-4px pa-lg-8px">
                        <mini-seller-info v-if="isCompany" :value="value">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_secondary, attrs }" v-on="on" color="secondary" class="v-size--xx-small">AS 협력점 정보</v-btn>
                            </template>
                        </mini-seller-info>
                    </v-col>
                    <!-- S: Admin Button -->
                    <v-col v-if="hasCompanyScope && isMe" cols="auto" order-lg="4" class="pa-4px pa-lg-8px">
                        <v-btn href="/console" v-bind="{ ...btn_secondary }" class="v-size--xx-small">ADMIN</v-btn>
                    </v-col>
                    <!-- E: Admin Button -->
                    <v-col cols="12" lg="auto" class="pa-4px pa-lg-8px">
                        <v-row class="row--xxs txt txt--sm">
                            <v-col cols="auto">
                                <!-- TODAY 방문자 -->
                                <span class="mr-4px">TODAY</span>
                                <span class="txt--dark font-weight-bold">
                                    {{ (value?.mini?.todayCount || 0)?.format?.() }}
                                </span>
                            </v-col>
                            <v-col cols="auto">
                                <!-- TOTAL 방문자 -->
                                <span class="mr-4px">TOTAL</span>
                                <span class="txt--dark font-weight-bold">
                                    {{ (value?.mini?.totalCount || 0)?.format?.() }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <div class="pt-6px py-lg-12px">
                    <v-row class="row--xxs">
                        <v-col v-if="!isMe" cols="6" lg="auto">
                            <!-- 팔로우 버튼 -->
                            <follow-btn :params="{ _follower: value._id, followings }" @input="emit">
                                <template #activator="{ active, toggle, loading }">
                                    <v-btn v-if="!active" v-bind="{ ...btn_tertiary, loading }" class="product-item__favorite v-btn--width-fixed w-100" @click.stop.capture="toggle">
                                        <v-icon>mdi-plus</v-icon>
                                        팔로우
                                    </v-btn>
                                    <v-btn v-else v-bind="{ ...btn_tertiary, loading }" class="v-btn--width-fixed w-100" @click.stop.capture="toggle">팔로우 취소</v-btn>
                                </template>
                            </follow-btn>
                            <!-- <v-btn v-if="!isMe" v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="follow"> <v-icon>add</v-icon> 팔로우 </v-btn> -->
                        </v-col>
                        <v-col cols="6" lg="auto">
                            <!-- 팔로워 버튼 -->
                            <v-btn v-bind="{ ...btn_tertiary }" active-class="false" class="v-btn--width-fixed" @click="$router.push({ path: `/minishop/${value._id}/follow`, query: { type: 'follower' } })">팔로워 {{ value?.mini?.followCount }}</v-btn>
                        </v-col>
                    </v-row>
                </div>
                <!-- PC 인사말 -->
                <v-row class="row--xxs txt txt--sm d-none d-lg-block">
                    <v-col>
                        {{ value?.mini?.greeting }}
                    </v-col>
                </v-row>
                <!-- PC 태그 -->
                <v-row class="row--xxs txt txt--sm txt--dark d-none d-lg-flex">
                    <v-col v-for="(tag, index) in value.mini.tag.filter((item) => item)" :key="index" cols="auto"> #{{ tag }} </v-col>
                </v-row>
            </v-col>

            <!-- Mobile 인사말 -->
            <v-col cols="12" class="pa-8px pa-lg-24px d-lg-none">
                <p class="txt">
                    {{ value?.mini?.greeting }}
                </p>
            </v-col>
            <!-- PC 태그 -->
            <v-col cols="12" class="pa-8px pa-lg-24px d-lg-none">
                <v-row class="row--xxs txt txt--sm txt--dark">
                    <v-col v-for="(tag, index) in value.mini.tag.filter((item) => item)" :key="index" cols="auto"> #{{ tag }} </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import FollowBtn from "../follow/follow-btn.vue";
import { btn_secondary, btn_tertiary, btn_quaternary, USER_TYPES } from "@/assets/variables";
import MiniSellerInfo from "@/components/client/miniShop/dialog/mini-seller-info.vue";
import MiniProfileEdit from "@/components/client/miniShop/dialog/mini-profile-edit.vue";

export default {
    components: {
        MiniSellerInfo,
        MiniProfileEdit,
        FollowBtn,
    },
    props: {
        isMe: { type: Boolean, default: false },
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        summary: {
            totalCount: 0,
        },
        followings: [],

        btn_secondary,
        btn_tertiary,
        btn_quaternary,
    }),
    computed: {
        //접속자가 기업회원인지
        ...mapGetters(["hasCompanyScope"]),
        _me() {
            return this.$store.state.payload._user;
        },
        //미니숍인지
        isCompany() {
            return this.value?.type === USER_TYPES.COMPANY.value;
        },
    },
    methods: {
        init() {
            if (this._me) this.search();
        },
        async search() {
            try {
                //프로필 방문자의 팔로잉 목록
                let { summary, follows } = await api.v1.me.follows.gets({ params: { _user: this._me } });

                this.followings = follows;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },
        emit() {
            this.$emit("input");
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            if (this._me) this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-avatar {
    position: relative;
    .v-btn {
        position: absolute;
        right: 6px;
        bottom: 6px;
    }
}
</style>