var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }, [_c('story-form', _vm._b({
    on: {
      "updated": _vm.reroute
    },
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  }, 'story-form', {
    code: _vm.code,
    _board: _vm._board
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }