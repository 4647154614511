var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "w-md-300px",
    attrs: {
      "clearable": "",
      "dense": "",
      "placeholder": "상품명을 입력해주세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.setQuery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query['searchValue'],
      callback: function ($$v) {
        _vm.$set(_vm.query, 'searchValue', $$v);
      },
      expression: "query['searchValue']"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.setQuery
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("검색")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }