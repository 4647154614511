<template>
    <v-card width="333" rounded="sm" v-bind="{ loading }">
        <v-card-title>
            <v-text-field v-model="searchValue" dense outlined hide-details placeholder="상품명, 브랜드를 검색하세요" v-bind="{ loading }" @keydown.enter="search(1)" class="rounded-sm">
                <template #append-outer>
                    <v-icon @click="$emit('pullTag')">mdi-close</v-icon>
                </template>
            </v-text-field>
        </v-card-title>
        <v-card-text>
            <v-list height="286" style="overflow-y: auto">
                <template v-if="products.length < 1">
                    <v-list-item>
                        <v-list-item-title> 검색결과가 없습니다. </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-for="(product, index) in products">
                    <v-list-item class="pl-0" style="border: none" :key="index">
                        <v-list-item-avatar size="60" rounded="sm" class="border">
                            <v-img :src="product.thumb?.url">
                                <template #placeholder>
                                    <v-icon>mdi-image-broken</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle> {{ product?.seller?.nickname }} </v-list-item-subtitle>
                            <v-list-item-title> {{ product?.name }} </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn outlined x-small min-width="58" @click="$emit('input', product)">선택</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <template v-if="page < pageCount">
                    <v-sheet v-intersect="searchOnScroll" class="pa-4 d-flex justify-center">
                        <v-progress-circular size="50" indeterminate />
                    </v-sheet>
                </template>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";

export default {
    data: () => ({
        products: [],
        searchValue: null,

        summary: { totalCount: 0 },

        page: 1,
        limit: 10,

        loading: false,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    methods: {
        async search(page = this.page) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { limit } = this;
                const filter = {
                    headers: { skip: (page - 1) * limit, limit },
                    params: {},
                };
                if (this.searchValue) filter.params = {...filter.params, searchKey: "name", searchValue: this.searchValue };

                const { summary, products } = await api.v1.shop.products.gets(filter);

                this.page = page;
                this.summary = summary;

                if (page == 1) this.products = products;
                else this.products = this.products.concat(...products);
            } finally {
                this.loading = false;
            }
        },
        searchOnScroll([target]) {
            console.log({ target });
            if (target.isIntersecting) {
                this.search(this.page + 1);
            }
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>