var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.push
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }, 'v-select', {
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }