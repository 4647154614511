<template>
    <v-toolbar class="d-flex justify-center" flat>
        <v-btn text icon @click="$emit('prev')">
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="pa-0 mx-2 font-weight-bold" style="margin-top: -2px"> {{ title }} </v-toolbar-title>
        <v-btn text icon @click="$emit('next')">
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    props: {
        date: { type: [String, Number, Date], default: Date.now() },
    },
    computed: {
        title() {
            return this.$dayjs(this.date).format("YYYY년 M월");
        },
    },
};
</script>

<style>
</style>