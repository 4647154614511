var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isPrivate ? _c('v-sheet', {
    staticClass: "py-60px py-lg-120px d-flex justify-center align-center"
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "40",
      "src": "/images/icon/icon-lock.svg"
    }
  })], 1), _c('div', {
    staticClass: "tit tit--lg font-weight-light"
  }, [_vm._v("비공개 계정입니다.")])])]) : _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "900"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item, index) {
    var _item$thumb;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "to": `/minishop/${item._id}`
      }
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', [item !== null && item !== void 0 && item.thumb ? _c('v-img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url
      }
    }) : _c('v-img', {
      attrs: {
        "src": "/images/minishop/profile-default.png"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "txt txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.nickname) + " ")]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [(item === null || item === void 0 ? void 0 : item._id) !== _vm._user ? _c('follow-btn', {
      attrs: {
        "params": {
          _follower: item === null || item === void 0 ? void 0 : item._id,
          followings: _vm.followings
        }
      },
      on: {
        "input": _vm.emit
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var active = _ref.active,
            toggle = _ref.toggle,
            loading = _ref.loading;
          return [!active ? _c('v-btn', _vm._b({
            staticClass: "product-item__favorite v-btn--width-fixed",
            attrs: {
              "small": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                $event.preventDefault();
                return toggle.apply(null, arguments);
              }
            }
          }, 'v-btn', Object.assign({}, _vm.btn_quaternary, {
            loading
          }), false), [_vm._v("팔로우")]) : _c('v-btn', _vm._b({
            staticClass: "v-btn--width-fixed",
            attrs: {
              "small": ""
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                $event.preventDefault();
                return toggle.apply(null, arguments);
              }
            }
          }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
            loading
          }), false), [_vm._v(" 팔로우 취소 ")])];
        }
      }], null, true)
    }) : _vm._e()], 1)], 1)], 1)], 1);
  })], 2)], 1), _c('v-divider', {
    staticClass: "mt-30px"
  }), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-40px mt-lg-60px",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }