var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.componentName, _vm._b({
    tag: "component"
  }, 'component', _vm.$attrs, false), [_vm._t("default", null, null, {
    items: _vm.items,
    boards: _vm.boards,
    summary: _vm.summary,
    loading: _vm.loading,
    search: _vm.search
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }