<template>
    <story-page>
        <div class="tit-wrap">
            <h3 class="tit tit--xxs">라이더 이야기</h3>
        </div>

        <story-board-tab></story-board-tab>

        <riders-list-table></riders-list-table>
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";

import StoryBoardTab from "@/components/client/sub/story/story-board-tab.vue";
import RidersListTable from "@/components/client/sub/story/riders/riders-list-table.vue";

export default {
    components: {
        StoryPage,
        StoryBoardTab,
        RidersListTable,
    },
};
</script>