<template>
    <v-sheet>
        <v-row class="row--xxs">
            <v-col v-show="showGuide" cols="12">
                <showcase-guide />
            </v-col>
            <v-col cols="12">
                <showcase-head v-model="showcases" @input="emit" v-bind="{ tab, board }" v-on="{ setTab, pushOnContent, toggleGuide }">
                    <template #guide-button>
                        <v-btn v-bind="{ ...btn_secondary }" class="v-size--xx-large w-100 w-md-140px" @click="toggleGuide">작성 가이드</v-btn>
                    </template>
                </showcase-head>
            </v-col>
        </v-row>
        <v-tabs-items :value="tab" class="mt-6">
            <v-tab-item v-for="(item, index) in showcases" :key="item.tempId" :value="item.tempId">
                <showcase :ref="item.tempId" v-model="showcases[index]" editable editable_image @input="emit" v-on="{ pull }" />
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>

<script>
import { btn_secondary } from "@/assets/variables";
import { initCommonShowcase, initStoryBoard } from "@/store/story";

import Showcase from "@/components/dumb/showcase/showcase.vue";
import ShowcaseHead from "./showcase/showcase-head.vue";
import ShowcaseGuide from "./showcase/showcase-guide.vue";

export default {
    components: {
        Showcase,
        ShowcaseHead,
        ShowcaseGuide,
    },
    props: {
        value: { type: Array, default: () => [] },
        board: { type: Object, default: initStoryBoard },

        loading: { type: Boolean, default: false },
        isMobile: { type: Boolean, default: false },
    },
    data: () => ({
        showcases: [],

        showGuide: true,

        tab: null,

        btn_secondary,
    }),
    computed: {
        needsAlterTab() {
            if (this.tab) {
                const tabGone = !this.showcases.some(({ tempId }) => tempId == this.tab);
                if (tabGone) return true;
            } else {
                const hasTabs = 0 < this.showcases.length;
                if (hasTabs) return true;
            }

            return false;
        },
    },
    methods: {
        sync() {
            this.showcases = this.value.map(initCommonShowcase);
        },
        emit() {
            this.$emit("input", this.showcases);
        },
        pull(showcase) {
            this.showcases = this.showcases.filter(({ tempId }) => tempId != showcase.tempId);
            this.emit();
        },
        setTab(showcase) {
            this.tab = showcase.tempId;
        },
        pushOnContent() {
            this.$emit("pushOnContent", this.tab);
        },
        toggleGuide() {
            this.showGuide = !this.showGuide;
        },
    },
    watch: {
        value() {
            this.sync();
        },
        tab() {
            this.showcases.forEach(({ tempId }) => {
                this.$refs[tempId]?.[0]?.hideTags();
            });
        },
        needsAlterTab() {
            if (0 < this.showcases.length) {
                this.tab = this.showcases[0]?.tempId;
            } else {
                this.tab = null;
            }
        },
    },
    mounted() {
        this.sync();
    },
};
</script>