<template>
    <v-tabs grow class="mb-40px mb-lg-60px">
        <tab-primary>전체보기</tab-primary>
        <tab-primary>전기자전거</tab-primary>
        <tab-primary>전동스쿠터</tab-primary>
        <tab-primary>전동킥보드</tab-primary>
    </v-tabs>
</template>

<script>
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    components: {
        TabPrimary,
    },
};
</script>