<template>
    <v-card tile class="rider-story-card py-18px border-b border-dark">
        <v-row justify="space-between" align="center" class="row--sm my-0">
            <v-col cols="auto" lg="3">
                <v-row class="row--xxs cursor-pointer" align="center" @click="go('minishop')">
                    <v-col cols="auto" class="d-none d-lg-block">
                        <v-avatar size="62">
                            <img v-if="board?.writer?.thumb" :src="board.writer.thumb.url" alt="" />
                            <img v-else src="/images/minishop/profile-default.png" alt="" />
                        </v-avatar>
                    </v-col>
                    <v-col cols="auto">
                        <div class="txt txt--dark font-weight-medium">{{ board?.writer?.nickname }}</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto" lg="1" order-lg="3">
                <v-row class="row--xxs" align="center">
                    <v-col cols="auto" lg="12">
                        <p class="txt txt--xs">{{ board.createdAt.toAgo() }}</p>
                    </v-col>
                    <v-col cols="auto" lg="12">
                        <div>
                            <v-icon color="primary" class="material-icons-outlined mr-4px" small>thumb_up</v-icon>
                            <span class="txt txt--xs font-weight-medium primary--text">{{ board.likeCount }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="8" order-lg="2" class="border-lg-l--dark border-lg-r--dark cursor-pointer" @click="go('story')">
                <h4 class="txt txt--dark font-weight-bold mb-4px mb-lg-6px text-truncate">{{ board.subject }}</h4>
                <p class="txt txt--dark text-truncate">{{ board.contentText }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { initStoryBoard } from "@/store/story";

export default {
    props: {
        board: { type: Object, default: initStoryBoard },
    },
    methods: {
        go(type) {
            console.log(this.board);
            switch (type) {
                case "minishop":
                    this.$router.push({ path: `/minishop/${this.board?.writer?._id}` });
                    break;
                case "story":
                    this.$router.push({ path: `/story/${this.board?.code}/${this.board?._id}` });
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
