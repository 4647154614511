<template>
    <v-btn-toggle v-model="category" mandatory class="w-100 flex-wrap">
        <v-btn v-for="(type, index) in types" :key="index" :value="type.value" @click="$router.push({
            query: { ...params, category: type.value }
        })">
            {{type.text}}
        </v-btn>
    </v-btn-toggle>
</template>

<script>
import { COMPANY_CATEGORY_TYPES } from "@/assets/variables";
export default {
    data: () => ({
        category: null
    }),
    computed: {
        types(){
            let types = Object.values(COMPANY_CATEGORY_TYPES);
            return [
                { text: "전체보기", value: null },
                ...types
            ]
        },
        params(){
            let { ...query } = this.$route.query;
            delete query._location;
            delete query.isNew;
            return query;
        }
    }
};
</script>
<style lang="scss" scoped>
.v-btn {
    border-radius: 0 !important;
    width: 50%;
    background: #fff !important;
    color: var(--v-grey-base);
    &:nth-child(3) {
        border-left-width: 1px !important;
    }
    &:nth-child(n + 3) {
        border-top-width: 0;
    }

    &--active {
        background: var(--v-primary-base) !important;
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--v-primary-base) !important;
}
</style>