<template>
    <dialog-basic v-model="shows" width="auto">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0 justify-center">카테고리 삭제</v-card-title>
        </div>

        <v-card-text class="pa-0 txt text-center">선택한 카테고리를 삭제하시겠습니까?</v-card-text>

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row justify="center" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary }" class="v-btn--width-fixed" @click="$emit('remove')">확인</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import { attrs_input, btn_icon, btn_primary, btn_secondary } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

export default {
    components: {
        DialogBasic,
    },
    props: {
        value: { type: Object, default: () => {} },
    },
    data: () => ({
        shows: false,

        attrs_input,
        btn_icon,
        btn_primary,
        btn_secondary,
    }),
};
</script>

<style lang="scss" scoped>
::v-deep() {
    .v-dialog__content {
        width: auto !important;
    }
}
</style>