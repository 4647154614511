var render = function render(){
  var _vm$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.writable ? [_c('vertical-form-table', _vm._b({
    staticClass: "vertical-form-table--border",
    attrs: {
      "topBorderMdNone": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "해시태그",
      fn: function () {
        return [_c('story-form-hashtags', {
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.board,
            callback: function ($$v) {
              _vm.board = $$v;
            },
            expression: "board"
          }
        })];
      },
      proxy: true
    }], null, false, 1748502074),
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "py-12px py-md-20px"
  }, [_c('naver-smarteditor', {
    key: _vm.editorKey,
    attrs: {
      "bSkipXssFilter": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1), (_vm$category = _vm.category) !== null && _vm$category !== void 0 && _vm$category.showcase ? [_c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('story-form-showcase', _vm._g(_vm._b({
    staticClass: "my-6",
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.board.showcases,
      callback: function ($$v) {
        _vm.$set(_vm.board, "showcases", $$v);
      },
      expression: "board.showcases"
    }
  }, 'story-form-showcase', {
    board: _vm.board
  }, false), {
    pushOnContent: _vm.pushOnContent
  }))] : _vm._e(), _c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "btn-wrap mx-n12px mx-md-0px"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("저장")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("취소")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 d-md-none",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3, {
    loading: _vm.loading
  }), false), [_vm._v("취소")])], 1)], 1)], 1)], 1)], 1)] : [_vm._v("권한이 없습니다.")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }