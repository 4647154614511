<template>
    <v-sheet max-width="1008" class="mx-auto">
        <div class="tit-wrap tit-wrap--xs">
            <h3 class="tit tit--xxs">모아고 추천상품</h3>
        </div>
        <v-slide-group show-arrows multiple>
            <!-- <template v-for="(product, index) in products">
                <v-slide-item :key="index" :value="product">
                    <router-link :to="`/shop/products/${product._id}`" class="product-item__inner">
                        <div class="product-item__img" :style="`background-image:url(${product?.thumb?.src});`">
                            <div v-if="!product?.sells || product?.stock == 0" class="product-item__badge">Sold Out</div>
                        </div>
                        <div class="product-item__con">
                            <div class="txt txt--sm mb-4px">{{ product?.seller?.companyName }}</div>
                            <div class="product-item__tit">
                                <span>{{ product?.name }} {{ product?.code || "" }}</span>
                            </div>
                            <div class="my-6px my-md-8px">
                                <em v-if="!product?.sells || product?.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                                <v-row v-else align="center" class="row--xxs font-weight-bold txt">
                                    <v-col v-if="product?.discountRate" class="accent2--text">{{ product?.discountRate }}%</v-col>
                                    <v-col class="accent1--text">{{ product?.salePrice.format() }}원</v-col>
                                </v-row>
                            </div>
                        </div>
                    </router-link>
                </v-slide-item>
            </template> -->

            <!-- 임시 -->
            <template v-for="(product, index) in products">
                <v-slide-item :key="index" :value="product" class="product-item">
                    <router-link :to="`/shop/products/${product._id}`" class="product-item__inner">
                        <v-card class="product-item__img" :img="product?.thumb?.src" tile />
                        <div class="product-item__con">
                            <div class="txt txt--sm mb-4px">{{ product?.seller?.companyName }}</div>
                            <div class="product-item__tit h-auto">{{ product?.name }} {{ product?.code || "" }}</div>
                            <div class="my-6px my-md-8px">
                                <v-row align="center" class="row--xxs font-weight-bold txt">
                                    <v-col v-if="product.discountRate" cols="auto" class="accent2--text">{{ product?.discountRate }}%</v-col>
                                    <v-col cols="auto" class="accent1--text">{{ product?.salePrice.format() }}원</v-col>
                                </v-row>
                            </div>
                        </div>
                    </router-link>
                </v-slide-item>
            </template>
        </v-slide-group>
    </v-sheet>
</template>

<script>
import api from "@/api";
export default {
    data: () => ({
        products: [],
    }),
    methods: {
        async init() {
            this.search();
        },
        async search() {
            try {
                let { summary, tags } = await api.v1.shop.tagEvents.gets();

                //랜덤 20개 상품
                this.products = tags
                    .map(({ product }) => product)
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 20);
                this.summary = summary;

                this.$emit("load", this.products);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
<style lang="scss" scoped>
.v-slide-group {
    ::v-deep() {
        .v-slide-group__wrapper {
            margin: 0 -10px;
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 30px;
                height: 100%;
            }
            // &::before {
            //     z-index: 1;
            //     left: 0;
            //     background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, transparent 100%);
            // }
            // &::after {
            //     right: 0;
            //     background-image: linear-gradient(to left, rgb(255, 255, 255) 50%, transparent 100%);
            // }
        }
        .v-slide-group__prev,
        .v-slide-group__next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: var(--v-primary-base);
            width: 32px;
            height: 32px;
            border-radius: 999px;
            min-width: initial !important;
            .v-icon {
                color: #fff !important;
            }
            &--disabled {
                background-color: var(--v-grey-lighten3);
            }
        }
        .v-slide-group__prev {
            position: absolute;
            top: 50%;
            left: -48px;
            width: 32px;
            height: 32px;
            min-width: initial !important;
            transform: translateY(-50%);
        }
        .v-slide-group__next {
            right: -48px;
        }
        .v-slide-group__prev + .v-slide-group__wrapper {
            &::after {
                display: none;
            }
        }
        .v-slide-group__prev--disabled + .v-slide-group__wrapper {
            &::after {
                display: block;
            }
            &::before {
                display: none;
            }
        }
    }
}

.product-item {
    margin: 0 10px;
    &__inner {
        width: 100%;
        min-width: 186px;
        max-width: calc(50vw - 20px);
    }
}

@media (min-width: 1200px) {
    .product-item {
        max-width: 252px;
    }
}
</style>