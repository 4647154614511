<template>
    <div>
        <section class="sub-visual">
            <div v-if="mobileBg" class="sub-visual__bg d-md-none" :style="`background-image: url(${mobileBg})`" />
            <div class="sub-visual__bg" :style="`background-image: url(${bg})`" />
            
            <h2 class="tit white--text" :class="titClass">{{ tit }}</h2>

            <v-container class="sub-visual__path container--lg" v-if="$slots['path']">
                <slot name="path" />
            </v-container>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        tit: { type: String, default: "" },
        titClass: { type: String, default: "" },
        bg: { type: String, default: "" },
        mobileBg: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
.sub-visual {
    height: 360px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(-1 * var(--page-section-padding-y) - 42px);
    .tit {
        margin-bottom: calc(var(--page-section-padding-y) + 42px);
    }
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    &__path {
        position: absolute;
        bottom: calc(var(--page-section-padding-y) + 42px);
        display: flex;
        justify-content: flex-end;
        color: #fff;
        ::v-deep() {
            .v-icon {
                color: #fff !important;
            }
            .v-breadcrumbs {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}
::v-deep {
    .theme--light.v-breadcrumbs .v-breadcrumbs__divider,
    .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
        color: #fff;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        height: 540px;
    }
}
</style>
