var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.componentName, _vm._b({
    tag: "component"
  }, 'component', _vm.$attrs, false), [_vm._t("default", null, null, {
    services: _vm.services,
    summary: _vm.summary,
    loading: _vm.loading
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }