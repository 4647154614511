var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    staticClass: "w-100 flex-wrap",
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }, _vm._l(_vm.types, function (type, index) {
    return _c('v-btn', {
      key: index,
      attrs: {
        "value": type.value
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push({
            query: Object.assign({}, _vm.params, {
              category: type.value
            })
          });
        }
      }
    }, [_vm._v(" " + _vm._s(type.text) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }