<template>
    <story-page>
        <v-row>
            <v-col>
                <v-row>
                    <!-- S: 라이딩 -->
                    <v-col cols="12" lg="6">
                        <div class="tit-wrap tit-wrap--sm d-flex">
                            <v-btn x-large v-bind="{ ...btn_txt }" to="/sub/story/bicycle/riding">
                                <span class="font-weight-bold">#라이딩</span>
                                <v-icon class="ml-8px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-row class="row--xs" align="center">
                                        <v-col cols="4" xl="3">
                                            <v-img src="/images/test-img.jpg">
                                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                                            </v-img>
                                        </v-col>
                                        <v-col cols="8" xl="9">
                                            <div class="tit-wrap tit-wrap--xs d-flex align-center">
                                                <v-avatar size="20">
                                                    <v-img src="/images/test-img.jpg" />
                                                </v-avatar>
                                                <div class="txt txt--xs ml-6px">작성자</div>
                                            </div>
                                            <div class="tit-wrap tit-wrap--xs">
                                                <h3 class="tit font-size-16 font-weight-medium text-truncate">게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.</h3>
                                                <p class="txt txt--xs text-truncate">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                                            </div>
                                            <v-row class="row--xxs">
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">visibility</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- E: 라이딩 -->

                    <!-- S: 튜닝관리 -->
                    <v-col cols="12" lg="6">
                        <div class="tit-wrap tit-wrap--sm d-flex">
                            <v-btn x-large v-bind="{ ...btn_txt }" to="/sub/story/bicycle/tuning">
                                <span class="font-weight-bold">#튜닝관리</span>
                                <v-icon class="ml-8px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-row class="row--xs" align="center">
                                        <v-col cols="4" xl="3">
                                            <v-img src="/images/test-img.jpg">
                                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                                            </v-img>
                                        </v-col>
                                        <v-col cols="8" xl="9">
                                            <div class="tit-wrap tit-wrap--xs d-flex align-center">
                                                <v-avatar size="20">
                                                    <v-img src="/images/test-img.jpg" />
                                                </v-avatar>
                                                <div class="txt txt--xs ml-6px">작성자</div>
                                            </div>
                                            <div class="tit-wrap tit-wrap--xs">
                                                <h3 class="tit font-size-16 font-weight-medium text-truncate">게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.</h3>
                                                <p class="txt txt--xs text-truncate">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                                            </div>
                                            <v-row class="row--xxs">
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">visibility</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- E: 튜닝관리 -->

                    <!-- S: 제품후기 -->
                    <v-col cols="12" lg="6">
                        <div class="tit-wrap tit-wrap--sm d-flex">
                            <v-btn x-large v-bind="{ ...btn_txt }" to="/sub/story/bicycle/review">
                                <span class="font-weight-bold">#제품후기</span>
                                <v-icon class="ml-8px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-row class="row--xs" align="center">
                                        <v-col cols="4" xl="3">
                                            <v-img src="/images/test-img.jpg">
                                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                                            </v-img>
                                        </v-col>
                                        <v-col cols="8" xl="9">
                                            <div class="tit-wrap tit-wrap--xs d-flex align-center">
                                                <v-avatar size="20">
                                                    <v-img src="/images/test-img.jpg" />
                                                </v-avatar>
                                                <div class="txt txt--xs ml-6px">작성자</div>
                                            </div>
                                            <div class="tit-wrap tit-wrap--xs">
                                                <h3 class="tit font-size-16 font-weight-medium text-truncate">게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.</h3>
                                                <p class="txt txt--xs text-truncate">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                                            </div>
                                            <v-row class="row--xxs">
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">visibility</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- E: 제품후기 -->

                    <!-- S: 번개/모임 -->
                    <v-col cols="12" lg="6">
                        <div class="tit-wrap tit-wrap--sm d-flex">
                            <v-btn x-large v-bind="{ ...btn_txt }" to="/sub/story/bicycle/impromptu">
                                <span class="font-weight-bold">#번개/모임</span>
                                <v-icon class="ml-8px">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-row class="row--xs" align="center">
                                        <v-col cols="4" xl="3">
                                            <v-img src="/images/test-img.jpg">
                                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                                            </v-img>
                                        </v-col>
                                        <v-col cols="8" xl="9">
                                            <div class="tit-wrap tit-wrap--xs d-flex align-center">
                                                <v-avatar size="20">
                                                    <v-img src="/images/test-img.jpg" />
                                                </v-avatar>
                                                <div class="txt txt--xs ml-6px">작성자</div>
                                            </div>
                                            <div class="tit-wrap tit-wrap--xs">
                                                <h3 class="tit font-size-16 font-weight-medium text-truncate">게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.</h3>
                                                <p class="txt txt--xs text-truncate">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                                            </div>
                                            <v-row class="row--xxs">
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">visibility</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="d-flex">
                                                        <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                                        <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- E: 번개/모임 -->
                </v-row>

                <v-divider dark class="my-40px my-md-70px my-lg-90px" />

                <!-- S: 소식/정보 -->
                <div class="tit-wrap tit-wrap--sm">
                    <v-row no-gutters justify="space-between">
                        <v-col cols="auto"><h3 class="tit tit--xxs">소식/정보</h3></v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_txt }" to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="row--xxs">
                    <v-col cols="12" md="6" lg="3">
                        <v-card tile class="pb-30px pb-xl-0">
                            <v-img src="/images/test-img.jpg">
                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                            </v-img>
                            <h4 class="tit tit--xxs mt-12px mb-6px line-height-15 text-truncate">게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.</h4>
                            <p class="txt txt--dark text-truncate-2">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <v-card tile>
                            <v-img src="/images/test-img.jpg">
                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                            </v-img>
                            <h4 class="tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate">게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.</h4>
                            <p class="txt txt--dark text-truncate-2">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <v-card tile>
                            <v-img src="/images/test-img.jpg">
                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                            </v-img>
                            <h4 class="tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate">게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.</h4>
                            <p class="txt txt--dark text-truncate-2">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <v-card tile>
                            <v-img src="/images/test-img.jpg">
                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                            </v-img>
                            <h4 class="tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate">게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.</h4>
                            <p class="txt txt--dark text-truncate-2">게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- E: 소식/정보 -->

                <v-divider dark class="my-40px my-md-70px my-lg-90px" />

                <!-- S: 라이더 이야기 -->
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit tit--xxs">라이더 이야기</h3>
                </div>

                <riders-story-slide></riders-story-slide>
                <!-- E: 라이더 이야기 -->
            </v-col>
        </v-row>
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";

import RidersStorySlide from "@/components/client/sub/story/riders-story-slide";

import { btn_txt } from "@/assets/variables";

export default {
    components: {
        StoryPage,
        RidersStorySlide,
    },
    data: () => ({
        btn_txt,
    }),
};
</script>