var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var user = _ref.user;
        return [_c('v-card', {
          staticClass: "d-none d-lg-flex justify-center px-12px py-20px py-lg-24px",
          attrs: {
            "tile": "",
            "color": "grey lighten-5"
          }
        }, [_c('v-card-title', {
          staticClass: "tit tit--xs pa-0"
        }, [_vm._v(" MINI SHOP "), _c('span', {
          staticClass: "accent2--text"
        }, [_vm._v("BEST ITEM")])])], 1), _c('h3', {
          staticClass: "d-lg-none tit tit--xxs"
        }, [_vm._v("BEST")]), user !== null && user !== void 0 && user._id ? _c('best-list-card', _vm._b({
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var products = _ref2.products;
              return [[_c('shop-product-items', _vm._l(products, function (product, index) {
                return _c('shop-product-item', {
                  key: index,
                  attrs: {
                    "value": product,
                    "sm": "6",
                    "md": "6",
                    "lg": "3",
                    "rank": index + 1
                  }
                });
              }), 1)]];
            }
          }], null, true)
        }, 'best-list-card', {
          query: {
            _seller: user === null || user === void 0 ? void 0 : user._id
          },
          limit: 10
        }, false)) : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }