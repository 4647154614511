var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-date-picker', _vm._b({
    attrs: {
      "events": _vm.events,
      "weekday-format": _vm.getWeekDay,
      "full-width": "",
      "no-title": "",
      "color": "secondary"
    },
    on: {
      "click:date": _vm.emit
    },
    model: {
      value: _vm.picker,
      callback: function ($$v) {
        _vm.picker = $$v;
      },
      expression: "picker"
    }
  }, 'v-date-picker', {
    dayFormat: _vm.dayFormat,
    allowedDates: _vm.allowedDates
  }, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }