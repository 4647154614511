var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.isPc ? [_c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    },
    on: {
      "change": _vm.push
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('tab-primary', {
      key: item._id,
      attrs: {
        "tab-value": item.value
      }
    }, [_vm._v(_vm._s(item.text))])];
  })], 2)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }