<template>
    <section class="mini-shop-visual" v-if="value">
        <v-img v-if="value.banner" :src="value?.banner?.url" class="mini-shop-visual__bg">
            <div v-if="value.mini.title" class="overlay" />
        </v-img>
        <v-img v-else src="/images/minishop/skin-default.jpg" class="mini-shop-visual__bg">
            <div v-if="value.mini.title" class="overlay" />
        </v-img>

        <v-container class="container--lg">
            <h2 class="tit white--text">{{ value?.mini?.title }}</h2>
            <mini-skin-edit v-if="isMe" :value="value" @input="emit">
                <template #activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" elevation="4" icon class="v-size--xx-small white">
                        <v-icon>image</v-icon>
                    </v-btn>
                </template>
            </mini-skin-edit>
        </v-container>
    </section>
</template>

<script>
import { btn_icon } from "@/assets/variables";
import MiniSkinEdit from "@/components/client/miniShop/dialog/mini-skin-edit.vue";

export default {
    components: {
        MiniSkinEdit,
    },
    props: {
        isMe: { type: Boolean, default: false },
        value: { type: Object, default: () => {} },
    },
    data: () => ({
        btn_icon,
    }),
    methods: {
        emit() {
            this.$emit("input");
        },
    },
};
</script>

<style lang="scss" scoped>
.mini-shop-visual {
    position: relative;
    height: 300px;
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}
.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .v-btn {
        position: absolute;
        top: var(--container-gutter);
        left: var(--container-gutter);
    }
}
</style>