<template>
    <v-sheet class="pa-12px pa-lg-32px">
        <v-row class="row--sm">
            <v-col cols="12">
                <v-row class="row--xxs">
                    <v-col>
                        <v-text-field v-model="location" v-bind="{ ...attrs_input }" dense @keydown.enter="search"/>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary }" small @click="search">검색</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <store-map-filter/>
            </v-col>
            <v-col cols="12">
                <store-map-list/>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StoreMapFilter from "@/components/client/sub/store/store-map-filter.vue";
import StoreMapList from "@/components/client/sub/store/store-map-list.vue";

import { attrs_input, btn_primary } from "@/assets/variables";

export default {
    components: {
        StoreMapFilter,
        StoreMapList,
    },
    data: () => ({
        attrs_input,
        btn_primary,
        
        location: ""
    }),
    computed:{
        query(){
            let { ... query } = this.$route.query;
            delete query._location;
            delete query.isNew;
            return query;
        }
    },
    methods:{
        ...mapActions("location", ["getAddressByKeyword"]),
        ...mapMutations("location", ["setLvl"]),
        search(){
            try{
                const callback = (results) => {
                    this.setLvl({ lvl: 7 });
                    this.$nextTick(() => this.$router.push({
                        query:{ ...this.query, lat: results?.y, lng: results?.x, searchValue: this.location }
                    }));
                };
                this.getAddressByKeyword({ keyword: this.location, callback})
            }catch(error){
                this.$handleError(error);
            }
        }
    }
};
</script>