var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1008"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("모아고 추천상품")])]), _c('v-slide-group', {
    attrs: {
      "show-arrows": "",
      "multiple": ""
    }
  }, [_vm._l(_vm.products, function (product, index) {
    var _product$thumb, _product$seller;
    return [_c('v-slide-item', {
      key: index,
      staticClass: "product-item",
      attrs: {
        "value": product
      }
    }, [_c('router-link', {
      staticClass: "product-item__inner",
      attrs: {
        "to": `/shop/products/${product._id}`
      }
    }, [_c('v-card', {
      staticClass: "product-item__img",
      attrs: {
        "img": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.src,
        "tile": ""
      }
    }), _c('div', {
      staticClass: "product-item__con"
    }, [_c('div', {
      staticClass: "txt txt--sm mb-4px"
    }, [_vm._v(_vm._s(product === null || product === void 0 ? void 0 : (_product$seller = product.seller) === null || _product$seller === void 0 ? void 0 : _product$seller.companyName))]), _c('div', {
      staticClass: "product-item__tit h-auto"
    }, [_vm._v(_vm._s(product === null || product === void 0 ? void 0 : product.name) + " " + _vm._s((product === null || product === void 0 ? void 0 : product.code) || ""))]), _c('div', {
      staticClass: "my-6px my-md-8px"
    }, [_c('v-row', {
      staticClass: "row--xxs font-weight-bold txt",
      attrs: {
        "align": "center"
      }
    }, [product.discountRate ? _c('v-col', {
      staticClass: "accent2--text",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(product === null || product === void 0 ? void 0 : product.discountRate) + "%")]) : _vm._e(), _c('v-col', {
      staticClass: "accent1--text",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(product === null || product === void 0 ? void 0 : product.salePrice.format()) + "원")])], 1)], 1)])], 1)], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }