var render = function render(){
  var _vm$reservation, _vm$reservation$user, _vm$reservation2, _vm$reservation2$info, _vm$reservation3, _vm$reservation3$info, _vm$reservation4, _vm$reservation4$info, _vm$seller, _vm$seller2, _vm$reservation5, _vm$reservation5$serv, _vm$reservation6, _vm$reservation6$serv, _vm$reservation7, _vm$reservation8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.reservation ? _c('dialog-basic', {
    attrs: {
      "value": _vm.showsDialog,
      "width": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0"
  }, [_vm._v("예약정보")])], 1), _c('v-divider', {
    staticStyle: {
      "border-width": "0 0 2px"
    },
    attrs: {
      "dark": ""
    }
  }), _c('v-sheet', {
    staticClass: "my-22px"
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("접수자")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation = _vm.reservation) === null || _vm$reservation === void 0 ? void 0 : (_vm$reservation$user = _vm$reservation.user) === null || _vm$reservation$user === void 0 ? void 0 : _vm$reservation$user.username) + " 님")])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("예약날짜")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation2 = _vm.reservation) === null || _vm$reservation2 === void 0 ? void 0 : (_vm$reservation2$info = _vm$reservation2.information) === null || _vm$reservation2$info === void 0 ? void 0 : _vm$reservation2$info.date.toDate()))])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("예약시간")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation3 = _vm.reservation) === null || _vm$reservation3 === void 0 ? void 0 : (_vm$reservation3$info = _vm$reservation3.information) === null || _vm$reservation3$info === void 0 ? void 0 : _vm$reservation3$info.startsAt) + " ~ " + _vm._s((_vm$reservation4 = _vm.reservation) === null || _vm$reservation4 === void 0 ? void 0 : (_vm$reservation4$info = _vm$reservation4.information) === null || _vm$reservation4$info === void 0 ? void 0 : _vm$reservation4$info.endsAt) + " ")])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("방문주소")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$seller = _vm.seller) === null || _vm$seller === void 0 ? void 0 : _vm$seller.address1) + _vm._s((_vm$seller2 = _vm.seller) === null || _vm$seller2 === void 0 ? void 0 : _vm$seller2.address2))])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("서비스명")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation5 = _vm.reservation) === null || _vm$reservation5 === void 0 ? void 0 : (_vm$reservation5$serv = _vm$reservation5.service) === null || _vm$reservation5$serv === void 0 ? void 0 : _vm$reservation5$serv.name))])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("서비스요금")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation6 = _vm.reservation) === null || _vm$reservation6 === void 0 ? void 0 : (_vm$reservation6$serv = _vm$reservation6.service) === null || _vm$reservation6$serv === void 0 ? void 0 : _vm$reservation6$serv.price.format()) + "원 (추가요금 발생시 현장결제)")])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("모델명")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation7 = _vm.reservation) === null || _vm$reservation7 === void 0 ? void 0 : _vm$reservation7.model))])], 1), _c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("내용")]), _c('v-col', {
    staticClass: "txt--dark"
  }, [_vm._v(": " + _vm._s((_vm$reservation8 = _vm.reservation) === null || _vm$reservation8 === void 0 ? void 0 : _vm$reservation8.content))])], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "btn-wrap px-0 pb-0"
  }, [_vm.isMe ? _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.isPaymentCompleted && _vm.isReservationAwaiting ? _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.reservationConfirm
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 예약확정 ")]) : _vm._e(), _vm.isPaymentCompleted && _vm.isReservationCompleted ? _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.cancel
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 예약취소 ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function () {
        return _vm.showsDialog = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("닫기")])], 1)], 1) : _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.isPaymentCompleted && _vm.isReservationAwaiting ? _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.cancel
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 예약취소 ")]) : _vm._e(), _vm.isPaymentAwaiting && _vm.isReservationAwaiting ? _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.pay
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 결제하기 ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function () {
        return _vm.showsDialog = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("닫기")])], 1)], 1)], 1), _c('nice-payments', _vm._g({
    ref: "nicePay"
  }, {
    complete: _vm.complete
  }))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }