<template>
    <v-col cols="auto">
        <v-row class="row--xxs">
            <v-col cols="auto">
                <v-text-field v-model="filter.searchValue" dense v-bind="{ ...attrs_input, loading }" placeholder="검색어를 입력해주세요." class="w-300px" @keydown.enter="search" />
            </v-col>
            <v-col cols="auto">
                <v-btn small v-bind="{ ...btn_primary, ...$attrs }" @click="search">검색</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { attrs_input, btn_primary, btn_secondary } from "@/assets/variables";
export default {
    data: () => ({
        attrs_input,
        btn_primary,
        btn_secondary,
        loading: false,

        filter:{
            searchKey: "nickname",
            searchValue: null
        }
    }),
    methods:{
        search(){
            this.$router.push({query: this.filter})
        }
    }
}
</script>

<style>

</style>