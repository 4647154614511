<template>
    <mini-shop-page v-slot="{ user }">
        <mypage-coupons-down v-if="user?._id" :_seller="user?._id" />
    </mini-shop-page>
</template>

<script>
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import MypageCouponsDown from "@/components/client/mypage/coupons/mypage-coupons-down.vue";

export default {
    components: {
        MiniShopPage,

        MypageCouponsDown,
    },
};
</script>
