<template>
    <v-tabs v-model="query.sort" height="auto" hide-slider @change="emit">
        <template v-for="item in items">
            <tab-tertiary v-bind="item" :key="item.text">
                {{ item.title }}
            </tab-tertiary>
        </template>
    </v-tabs>
</template>

<script>
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";

const initQuery = (query = {}) => ({
    ...query,

    ["sort"]: query?.["sort"] || "date",
});

export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        query: initQuery(),

        items: [
            { title: "등록순", tabValue: "date" },
            { title: "추천순", tabValue: "like" },
        ],
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;
            if (query["sort"] == "date") delete query["sort"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>