var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('main-header', _vm._b({}, 'main-header', {
    disableScrollTop: _vm.disableScrollTop
  }, false)), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("default")], 2), _c('main-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }