<template>
    <!-- 일시정지 -->
    <!-- <v-card outlined tile class="request-card request-card--pause"> -->
    <v-card outlined tile class="request-card" @click="go()">
        <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
            <div class="text-center">
                <v-card-title class="tit--xs justify-center tit">{{ value?.name }}</v-card-title>
                <v-card-subtitle class="txt txt--dark text-center">{{ value?.description }}</v-card-subtitle>
                <v-card-text class="tit text-center">{{ value.price ? `${value.price.format()} 원` : "무료" }}</v-card-text>
            </div>
        </v-responsive>
    </v-card>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props:{
        value: { type: Object, default: ()=> ({}) },
        to: { type: String, default: "" }
    },
    computed: {
        ...mapState(["accessToken"]),
        _user(){
            return this.$route.params._user
        }
    },
    methods:{
        go(){
            if(!this.accessToken){
                alert("로그인이 필요한 서비스입니다.");
                this.$router.push("/login");
            } else{
                this.$router.push(this.to);
                // this.$router.push(`./${this._user}/request/service/${this.value._id}`);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.request-card {
    &--pause {
        position: relative;
        &::after {
            content: "서비스\A일시정지";
            white-space: pre;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.6);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            font-size: var(--tit-font-size);
            font-weight: 700;
        }
    }
}
</style>