var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-x": true
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small"
        }, 'v-btn', Object.assign({}, _vm.btn_icon, {
          attrs
        }), false), on), [_c('v-icon', [_vm._v("mdi-cog-outline")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "pa-0"
  }, [_c('mini-post-move', _vm._b({
    on: {
      "updated": function ($event) {
        return _vm.$emit('updated');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "transparent"
          }
        }, 'v-btn', Object.assign({}, attrs, {
          disabled: _vm.disabled
        }), false), on), [_vm._v("게시물 이동")])];
      }
    }])
  }, 'mini-post-move', {
    selected: _vm.selected,
    storyCodes: _vm.storyCodes
  }, false))], 1), _c('v-list-item', {
    staticClass: "pa-0"
  }, [_c('mini-post-delete', _vm._b({
    on: {
      "updated": function ($event) {
        return _vm.$emit('updated');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "transparent"
          }
        }, 'v-btn', Object.assign({}, attrs, {
          disabled: _vm.disabled
        }), false), on), [_vm._v("게시물 삭제")])];
      }
    }])
  }, 'mini-post-delete', {
    selected: _vm.selected
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }