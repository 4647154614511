<template>
    <div class="tit-wrap" v-if="isPc">
        <v-row align="center" class="my-n6">
            <v-col cols="auto">
                <h3 class="tit tit--xxs">{{ title }}</h3>
            </v-col>
            <template v-if="$slots['left']">
                <v-col cols="auto">
                    <slot name="left" />
                </v-col>
            </template>
            <template v-if="$slots['right']">
                <v-spacer />
                <v-col cols="auto">
                    <slot name="right" />
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import detect from "@/plugins/detect";

export default {
    props: {
        title: { type: String, default: "게시판" },
    },
    setup: () => ({
        detect,
    }),
    computed: {
        isPc() {
            return this.detect == "pc";
        },
    },
};
</script>

<style>
</style>