<template>
    <mini-shop-page v-slot="{ user }">
        <!-- PC only -->
        <v-card tile color="grey lighten-5" class="d-none d-lg-flex justify-center px-12px py-20px py-lg-24px">
            <v-card-title class="tit tit--xs pa-0"> MINI SHOP&nbsp;<span class="accent2--text">BEST ITEM</span> </v-card-title>
        </v-card>

        <!-- Mobile -->
        <h3 class="d-lg-none tit tit--xxs">BEST</h3>

        <best-list-card v-if="user?._id" v-bind="{ query:{ _seller: user?._id }, limit: 10 }" v-slot="{ products }">
            <template>
                <shop-product-items>
                    <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="3" :rank="index + 1" />
                </shop-product-items>
            </template>
        </best-list-card>
    </mini-shop-page>
</template>

<script>
import MiniShopPage from '@/components/client/templates/mini-shop-page.vue';

import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import BestListCard from "@/components/client/miniShop/list/best-list-card.vue";

export default {
    components: {
        MiniShopPage,

        ShopProductItems,
        ShopProductItem,
        BestListCard
    },
    data: () => ({
    }),
};
</script>