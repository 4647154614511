var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-toolbar', {
    staticClass: "d-flex justify-center",
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('prev');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-toolbar-title', {
    staticClass: "pa-0 mx-2 font-weight-bold",
    staticStyle: {
      "margin-top": "-2px"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('next');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }