var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pb-30px pb-xl-0",
    attrs: {
      "tile": "",
      "to": `${_vm.path}/${_vm.value._id}`
    }
  }, [_vm.value.storyThumbSrc ? _c('v-card', {
    attrs: {
      "img": _vm.value.storyThumbSrc,
      "tile": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1) : _c('v-card', {
    attrs: {
      "img": "/images/sub/story/storyDefault.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('h4', {
    staticClass: "tit tit--xxs mt-12px mb-6px line-height-15 text-truncate"
  }, [_vm.value.isNotice ? _c('v-icon', {
    staticClass: "icon icon-pin ml-4px"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.value.subject) + " ")], 1), _c('p', {
    staticClass: "txt txt--dark text-truncate-2"
  }, [_vm._v(_vm._s(_vm.value.contentText))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }