<template>
    <v-card tile :to="`${$route.path}/${value._id}`">
        <div class="tit-wrap tit-wrap--xs">
            <v-row no-gutters justify="space-between">
                <v-col cols="auto" v-if="!hidesNickname">
                    <div class="d-flex align-center">
                        <v-avatar size="20">
                            <v-img v-if="value.writer.thumb" :src="value.writer.thumb.url" />
                            <v-img v-else src="/images/minishop/profile-default.png" />
                        </v-avatar>
                        <div class="txt txt--xs ml-6px">{{ value?.writer?.nickname || "" }}</div>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <div class="d-flex">
                                <v-icon small class="material-icons-outlined">visibility</v-icon>
                                <p class="txt txt--xs font-weight-medium ml-4px">{{ value.viewCount }}</p>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <div class="d-flex">
                                <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                <p class="txt txt--xs font-weight-medium ml-4px">{{ value.likeCount }}</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-card v-if="value.storyThumbSrc" :img="value.storyThumbSrc" tile flat>
            <v-responsive :aspect-ratio="1 / 1">
                <template v-if="showsCheckbox">
                    <!-- <v-checkbox color="white" hide-details="auto"/> -->
                    <input v-model="$data.__selected" multiple :value="value" type="checkbox" class="ma-8px ma-lg-12px w-lg-20px h-lg-20px" @click.stop @change="emit" />
                </template>
            </v-responsive>
        </v-card>
        <v-card v-else img="/images/sub/story/storyDefault.jpg" tile flat>
            <v-responsive :aspect-ratio="1 / 1">
                <template v-if="showsCheckbox">
                    <input v-model="$data.__selected" multiple :value="value" type="checkbox" class="ma-8px ma-lg-12px w-lg-20px h-lg-20px" @click.stop @change="emit" />
                </template>
            </v-responsive>
        </v-card>
        <div class="txt txt--dark font-weight-medium text-truncate-2 mt-8px mt-lg-12px">
            <v-icon v-if="value.isNotice" class="icon icon-pin ml-4px"></v-icon>
            <!-- <v-icon v-if="value.isNotice" class="material-icons-outlined">keep</v-icon> -->
            {{ value.subject }}
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // boards
        selected: { type: Array, default: () => [] },
        hidesNickname: { type: Boolean, default: false },
        showsCheckbox: { type: Boolean, default: false },
    },
    data: () => ({
        __selected: [],
    }),
    methods: {
        sync() {
            this.$data.__selected = [...this.selected];
        },
        emit() {
            this.$nextTick(() => this.$emit("update:selected", this.$data.__selected));
        },
    },
    watch: {
        selected() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>