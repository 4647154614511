<template>
    <story-page>
        <riding-view />
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";

import ridingView from '@/components/client/sub/story/riding-view.vue';

export default {
    components: {
        StoryPage,
        
        ridingView,
    },
}
</script>