<template>
    <v-btn-toggle v-model="mini.followPrivate" mandatory>
        <v-btn :value= false tile x-small class="v-btn--width-fixed px-4px" @click="edit">목록 공개</v-btn>
        <v-btn :value= true tile x-small class="v-btn--width-fixed px-4px" @click="edit">목록 비공개</v-btn>
    </v-btn-toggle>
</template>

<script>
import api from "@/api";
import { initMini } from '../miniShop/dialog/mini-profile-edit.vue'

export default{
    props:{
        value: { type: Object, default: ()=> ({}) }
    },
    data: () => ({
        mini: initMini()
    }),
    methods:{
        sync(){
            this.mini = initMini(this.value?.mini);
        },
        async edit(){
            try{
                await api.v1.me.put({ mini: this.mini});
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.sync();
    },
    watch: {
        value(){
            this.sync();
        }
    }

}
</script>
<style lang="scss" scoped>
.v-btn {
    background-color: #fff !important;
    color: var(--v-primary-base);
    &--active {
        background-color: var(--v-primary-base) !important;
        border: none;
    }
}
</style>