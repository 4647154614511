<template>
    <dialog-basic v-if="reservation" :value="showsDialog" @input="emit" width="auto">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">예약정보</v-card-title>
        </div>

        <v-divider dark style="border-width: 0 0 2px" />

        <v-sheet class="my-22px">
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">접수자</v-col>
                <v-col class="txt--dark">: {{ reservation?.user?.username }} 님</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">예약날짜</v-col>
                <v-col class="txt--dark">: {{ reservation?.information?.date.toDate() }}</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">예약시간</v-col>
                <v-col class="txt--dark">: {{ reservation?.information?.startsAt }} ~ {{ reservation?.information?.endsAt }} </v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">방문주소</v-col>
                <v-col class="txt--dark">: {{ seller?.address1 }}{{ seller?.address2 }}</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">서비스명</v-col>
                <v-col class="txt--dark">: {{ reservation?.service?.name }}</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">서비스요금</v-col>
                <v-col class="txt--dark">: {{ reservation?.service?.price.format() }}원 (추가요금 발생시 현장결제)</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">모델명</v-col>
                <v-col class="txt--dark">: {{ reservation?.model }}</v-col>
            </v-row>
            <v-row no-gutters class="txt txt--sm">
                <v-col cols="3">내용</v-col>
                <v-col class="txt--dark">: {{ reservation?.content }}</v-col>
            </v-row>
        </v-sheet>

        <v-divider />

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row v-if="isMe" justify="center" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-if="isPaymentCompleted && isReservationAwaiting" v-bind="{ ...btn_secondary, loading }" class="v-btn--width-fixed" @click="reservationConfirm"> 예약확정 </v-btn>
                    <v-btn v-if="isPaymentCompleted && isReservationCompleted" v-bind="{ ...btn_secondary, loading }" class="v-btn--width-fixed" @click="cancel"> 예약취소 </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="() => (showsDialog = false)">닫기</v-btn>
                </v-col>
            </v-row>
            <v-row v-else justify="center" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-if="isPaymentCompleted && isReservationAwaiting" v-bind="{ ...btn_secondary, loading }" class="v-btn--width-fixed" @click="cancel"> 예약취소 </v-btn>
                    <v-btn v-if="isPaymentAwaiting && isReservationAwaiting" v-bind="{ ...btn_secondary, loading }" class="v-btn--width-fixed" @click="pay"> 결제하기 </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="() => (showsDialog = false)">닫기</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

        <!-- 결제 연동용(삭제 예정) -->
        <!-- <nice-pay ref="nicePay" /> -->
        <nice-payments ref="nicePay" v-on="{ complete }" />
    </dialog-basic>
</template>

<script>
// 결제 연동용(삭제 예정)
import { mapActions } from "vuex";
import api from "@/api";

import { attrs_input, btn_icon, btn_txt, btn_primary, btn_secondary, RESERVATION_STATUS, NICEPAY_PURCAHSE_TYPES, RESERVATION_PAYMENT_STATUS } from "@/assets/variables";
import DialogBasic from "@/components/client/dumb/dialog-basic.vue";
import NicePayments from "@/components/plugins/nice/nice-payments.vue";
import NicePay from "@/components/plugins/nice/nice-pay.vue";

export default {
    components: {
        NicePay,
        DialogBasic,
        NicePayments,
    },
    props: {
        value: { type: Object, default: () => {} },
        shows: { type: Boolean, default: false },
        seller: { type: Object, default: () => {} },
    },
    data: () => ({
        RESERVATION_STATUS,
        showsDialog: false,
        isValid: true,

        attrs_input,
        btn_icon,
        btn_txt,
        btn_primary,
        btn_secondary,
        loading: false,

        reservation: {},
    }),
    computed: {
        isMe() {
            //미니샵의 주인인지
            return this.$route.params._user === this.$store.state.payload._user;
        },
        user() {
            return this.$store.state.user;
        },
        isReservationAwaiting() {
            return this.reservation.reservationStatus === RESERVATION_STATUS.RESERVATION_AWAITING.value;
        },
        isReservationCompleted() {
            return this.reservation.reservationStatus === RESERVATION_STATUS.RESERVATION_COMPLETED.value;
        },
        isPaymentAwaiting() {
            return this.reservation.paymentStatus === RESERVATION_PAYMENT_STATUS.PAYMENT_AWAITING.value;
        },
        isPaymentCompleted() {
            return this.reservation.paymentStatus === RESERVATION_PAYMENT_STATUS.PAYMENT_COMPLETED.value;
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        init() {
            if (this.shows) this.search();
            this.sync();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { reservation } = await api.v1.shop.reservations.get({ _id: this.value._id });
                this.reservation = reservation;

                this.loading = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        // 결제 연동용(삭제 예정)
        async pay() {
            if (this.loading) return;
            else this.loading = false;

            try {
                if (!this.validates()) return;
                let { service, ...reservation } = this.reservation;
                await this.getUser();
                this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_SERVICES.value;
                this.$refs.nicePay.orderNo = reservation.orderNo;
                this.$refs.nicePay.orderName = service.name;
                this.$refs.nicePay.paymentMethod = "card";
                this.$refs.nicePay.paymentGateway = "";
                this.$refs.nicePay.paymentAmount = service.price;
                this.$refs.nicePay.buyerName = this.user.name;
                this.$refs.nicePay.buyerPhone = this.user.phone;
                this.$refs.nicePay.buyerEmail = this.user.email;
                this.$refs.nicePay.pay();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async reservationConfirm() {
            if (this.loading) return;
            else this.loading = false;

            try {
                if (confirm("예약을 확정하시겠습니까?")) {
                    await api.v1.shop.reservations.put({
                        ...this.reservation,
                        reservationStatus: RESERVATION_STATUS.RESERVATION_COMPLETED.value,
                    });
                }
                this.showsDialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async cancel() {
            if (this.loading) return;
            else this.loading = false;

            try {
                if (confirm("예약을 취소하시겠습니까?")) {
                    await api.v1.shop.reservations.put({
                        ...this.reservation,
                        reservationStatus: RESERVATION_STATUS.RESERVATION_CANCELED.value,
                        paymentStatus: RESERVATION_PAYMENT_STATUS.PAYMENT_CANCELED.value,
                    });

                    alert("예약이 취소되었습니다.");
                }
                this.showsDialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.showsDialog = this.shows;
            // this.form = initProfile(this.value);
        },
        emit(value) {
            this.showsDialog = value;
            this.$emit("input", this.showsDialog);
        },
        // 결제 연동용(삭제 예정)
        validates() {
            if (!this.validates.reservationStatus === RESERVATION_STATUS.RESERVATION_AWAITING.value) throw new Error("예약대기 상태에서만 결제가 가능합니다.");
            if (!this.validates.paymentStatus === RESERVATION_PAYMENT_STATUS.PAYMENT_AWAITING.value) throw new Error("결제대기 상태에서만 결제가 가능합니다.");

            return true;
        },
        // 결제 연동용(삭제 예정)
        complete({ resCode, resMessage, _order }) {
            alert(["0000", "3001"].includes(resCode) ? "결제가 완료되었습니다." : resMessage);
            this.$router.push(`/minishop/${this.$route.params._user}/request/schedule`);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped></style>