var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.mini.followPrivate,
      callback: function ($$v) {
        _vm.$set(_vm.mini, "followPrivate", $$v);
      },
      expression: "mini.followPrivate"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed px-4px",
    attrs: {
      "value": false,
      "tile": "",
      "x-small": ""
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("목록 공개")]), _c('v-btn', {
    staticClass: "v-btn--width-fixed px-4px",
    attrs: {
      "value": true,
      "tile": "",
      "x-small": ""
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("목록 비공개")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }