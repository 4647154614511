var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "py-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("게시글 제목이 옵니다.")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group tit--xxs"
  }, [_c('ul', [_c('li', [_vm._v("2024.02.02")]), _c('li', [_vm._v("조회수 13회")]), _c('li', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _vm._v("75")], 1)])])])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "30"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--sm ml-6px"
  }, [_vm._v("작성자")])], 1)])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-chip-group', [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#태그")]), _c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#가")]), _c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#옵니다")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("글 내용이 옵니다.")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', {
    staticClass: "material-icons-outlined"
  }, [_vm._v("thumb_up")])], 1), _c('span', {
    staticClass: "font-weight-medium txt txt--sm"
  }, [_vm._v("75")])], 1)])], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("삭제")])], 1), _c('v-col', [_c('v-spacer')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("목록")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }