var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "hide-slider": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.query['sort'],
      callback: function ($$v) {
        _vm.$set(_vm.query, 'sort', $$v);
      },
      expression: "query['sort']"
    }
  }, [_c('tab-tertiary', {
    attrs: {
      "tab-value": "date"
    }
  }, [_vm._v("최신순")]), _c('tab-tertiary', {
    attrs: {
      "tab-value": "view"
    }
  }, [_vm._v("인기순")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }