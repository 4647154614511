<template>
    <v-card tile :to="`${path}/${value._id}`">
        <v-row class="row--xs" align="center">
            <v-col cols="4" xl="3">
                <v-card v-if="value.storyThumbSrc" tile flat :img="value.storyThumbSrc">
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-card>
                <v-card v-else img="/images/sub/story/storyDefault.jpg" tile flat>
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-card>
                <!-- <v-img :src="value.storyThumbSrc">
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-img> -->
            </v-col>
            <v-col cols="8" xl="9">
                <div class="tit-wrap tit-wrap--xs d-flex align-center">
                    <v-avatar size="20">
                        <v-img v-if="value.writer.thumb" :src="value.writer.thumb.url" />
                        <v-img v-else src="/images/minishop/profile-default.png" />
                    </v-avatar>
                    <div class="txt txt--xs ml-6px">{{ value?.writer?.nickname || "" }}</div>
                </div>
                <div class="tit-wrap tit-wrap--xs">
                    <h3 class="tit font-size-16 font-weight-medium text-truncate">
                        <v-icon v-if="value.isNotice" class="icon icon-pin ml-4px"></v-icon>
                        {{ value.subject }}
                    </h3>
                    <p class="txt txt--xs text-truncate">{{ value.contentText }}</p>
                </div>
                <v-row class="row--xxs">
                    <v-col cols="auto">
                        <div class="d-flex">
                            <v-icon small class="material-icons-outlined">visibility</v-icon>
                            <p class="txt txt--xs font-weight-medium ml-4px">{{ value.viewCount }}</p>
                        </div>
                    </v-col>
                    <v-col cols="auto">
                        <div class="d-flex">
                            <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                            <p class="txt txt--xs font-weight-medium ml-4px">{{ value.likeCount }}</p>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default {
    props: {
        path: { type: String, default: "/story/:code" },
        value: { type: Object, default: () => ({}) }, // boards
    },
};
</script>

<style>
</style>