var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var user = _ref.user;
        return [user !== null && user !== void 0 && user._id ? _c('mypage-coupons-down', {
          attrs: {
            "_seller": user === null || user === void 0 ? void 0 : user._id
          }
        }) : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }