var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    }
  }, _vm._l(_vm.bestTabs, function (tab, index) {
    return _c('tab-primary', {
      key: index,
      attrs: {
        "exact": "",
        "to": tab.to
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, _vm._l(_vm.bestTabs, function (tab, index) {
    return _c('v-tab-item', {
      key: index,
      attrs: {
        "value": tab.to.query.category
      }
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, _vm._l(_vm.shops, function (store, index) {
      return _c('v-col', {
        key: index,
        attrs: {
          "cols": "6",
          "xl": "4"
        }
      }, [_c('store-profile', {
        attrs: {
          "value": store,
          "best": ""
        },
        scopedSlots: _vm._u([{
          key: "avatar",
          fn: function () {
            return [store.thumb ? _c('v-img', {
              attrs: {
                "src": store.thumb.src
              }
            }) : _c('v-img', {
              attrs: {
                "src": "/images/minishop/profile-default.png"
              }
            })];
          },
          proxy: true
        }], null, true)
      })], 1);
    }), 1), _c('div', {
      staticClass: "btn-wrap btn-wrap--lg d-flex justify-center"
    }, [_c('v-btn', _vm._b({
      staticClass: "v-btn--width-fixed",
      attrs: {
        "x-large": ""
      },
      on: {
        "click": function () {
          return _vm.$router.push(_vm.goPath);
        }
      }
    }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v(" 전체보기 ")])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("이달의 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("BEST")]), _vm._v(" 협력사")])]);

}]

export { render, staticRenderFns }