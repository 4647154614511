<template>
    <v-data-table :items="notifications" v-bind="{ headers, itemClass }" dense hide-default-footer disable-sort disable-filtering disable-pagination @click:row="(any, { item }) => showBoard(item)" no-data-text="등록된 게시물이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.number`]="{ item }">
            <div data-idx="5" class="text-truncate">{{ item.isNotice ? "공지" : summary.totalCount - (page - 1) * limit - notifications.indexOf(item) + 1 }}</div>
        </template>
        <template #[`item.subject`]="{ item }">
            <div data-idx="5" class="text-truncate text-start" :class="{ 'font-weight-black': item.isNotice }">{{ item.subject }}</div>
        </template>

        <template #footer>
            <!-- S: 검색 -->
            <div class="mt-20px mt-40px">
                <v-row align="center" justify="space-between" class="row--sm">
                    <v-col cols="12" sm="auto">
                        <v-row align="center" class="row--xxs">
                            <v-col cols="auto">
                                <!-- <v-select v-bind="{ ...attrs_input }" item-text="text" item-value="type" placeholder="선택" class="w-120px" /> -->
                                <v-select v-model="filter.searchKey" v-bind="{ ...attrs_input }" dense :items="searchItems" class="w-120px" />
                            </v-col>
                            <v-col cols="">
                                <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input }" dense placeholder="검색어를 입력하세요" />
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <v-btn v-bind="{ ...btn_primary }" small @click="search">검색</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" sm="auto">
                        <v-btn v-bind="{ ...btn_primary }" small class="v-btn--width-fixed">글쓰기</v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- E: 검색 -->

            <!-- S: 페이징 -->
            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
            <!-- E: 페이징 -->
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { attrs_input, btn_primary } from "@/assets/variables";

import PaginationComponent from "@/components/client/pagination-component.vue";

const headers = [
    { width: "10%", align: "center", text: "번호", value: "number" },
    {
        width: "10%",
        align: "center",
        text: " 카테고리",
        value: "category",
        class: "grey lighten-5",
        cellClass: "grey lighten-5",
    },
    { align: "center", text: "제목", value: "subject" },
    { width: "16%", align: "center", text: "작성자", value: "writer", formatter: (value) => value?.name || "-" },
    { width: "10%", align: "center", text: "등록일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "10%", align: "center", text: "조회수", value: "viewCount" },
];

export default {
    components: {
        PaginationComponent,
    },
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        fixed: [],
        nonFixed: [],
        notifications: [],

        limit: 10,
        summary: { totalCount: 0 },
        searchItems: [
            { text: "전체", value: null },
            { text: "제목", value: "subject" },
            { text: "내용", value: "content" },
        ],
        filter: {
            isNotice: false,
            searchKey: null,
            searchValue: "",
        },

        headers,
        btn_primary,
        attrs_input,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.notifications].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.notifications = [];
            this.summary = { totalCount: 0 };
        },
        async search() {
            try {
                const { skip, limit, params } = this;
                const { notifications: fixed } = await api.v1.center.notifications.getNotifications({ headers: { limit: 0 }, params: { isNotice: true } });
                const { summary, notifications: nonFixed } = await api.v1.center.notifications.getNotifications({ headers: { skip, limit }, params: this.filter });

                this.fixed = fixed;
                this.summary = summary;
                this.nonFixed = nonFixed;
                this.notifications = [...fixed, ...nonFixed];
            } catch (error) {
                this.$handleError(error);
            }
        },
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
        itemClass({ isNotice }) {
            return isNotice ? "accent3" : "";
        },
    },
    watch: {
        $route() {
            this.init().then(this.search);
        },
    },
};
</script>