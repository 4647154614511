<template>
    <client-simple-page>
        <v-row no-gutters>
            <!-- S: PC 검색 -->
            <v-col lg="4" xl="3" class="d-none d-lg-block border-r">
                <store-map-search />
            </v-col>
            <!-- E: PC 검색 -->
            <v-col cols="12" lg="">
                <store-map-sheet />
            </v-col>
        </v-row>
        <!-- S: Mobile 검색 -->
        <v-sheet class="d-lg-none">
            <v-btn color="primary" class="search-drawer-btn" @click.stop="searchDrawer = !searchDrawer">
                검색
                <v-icon class="ml-4px">mdi-chevron-up</v-icon>
            </v-btn>
            <v-navigation-drawer v-model="searchDrawer" bottom class="search-drawer">
                <div class="search-drawer-closer" @click.stop="searchDrawer = !searchDrawer">
                    <v-img src="/images/sub/store/map/drawer-button-horizon.png" class="search-drawer-closer__bg" />
                    <v-icon>mdi-chevron-down</v-icon>
                </div>
                <store-map-search />
            </v-navigation-drawer>
        </v-sheet>
        <!-- E: Mobile 검색 -->

        <!-- S: 이달의 NEW STORE -->
        <store-new-drawer />
        <!-- E: 이달의 NEW STORE -->
    </client-simple-page>
</template>

<script>
import StoreMapSheet from "@/components/client/sub/store/store-map-sheet.vue";
import ClientSimplePage from "@/components/client/templates/client-simple-page.vue";
import StoreMapSearch from "@/components/client/sub/store/store-map-search.vue";
import StoreNewDrawer from "@/components/client/sub/store/store-new-drawer.vue";

export default {
    components: {
        StoreMapSheet,
        ClientSimplePage,
        StoreMapSearch,
        StoreMapSheet,
        StoreNewDrawer,
    },
    data: () => ({
        searchDrawer: false,
    }),
};
</script>

<style lang="scss" scoped>
.search-drawer-btn {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.search-drawer-closer {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 88px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.search-drawer {
    z-index: 999;
    max-width: 100%;
    max-height: 90% !important;
    position: fixed;
    bottom: 0;
    overflow: visible;
}
</style>