var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "mb-16px mb-lg-34px d-flex"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": _vm.path
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("#" + _vm._s(_vm.category.name))]), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.boards, function (value) {
    return [_c('v-col', {
      key: value._id,
      attrs: {
        "cols": "12"
      }
    }, [_c('boards-item', _vm._b({}, 'boards-item', {
      path: _vm.path,
      value
    }, false))], 1)];
  }), _vm.boards.length < 1 && !_vm.loading ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("게시글이 없습니다")])])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }