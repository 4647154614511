<template>
    <div>
        <div class="tit-wrap">
            <v-row no-gutters justify="space-between">
                <v-col cols="auto"><h3 class="tit tit--xxs">라이딩</h3></v-col>
                <v-col cols="auto">
                    <v-tabs hide-slider>
                        <tab-tertiary>최신순</tab-tertiary>
                        <tab-tertiary>인기순</tab-tertiary>
                    </v-tabs>
                </v-col>
            </v-row>
        </div>

        <v-row class="row--sm">
            <v-col cols="12" md="6" lg="3">
                <v-card tile>
                    <div class="tit-wrap tit-wrap--xs">
                        <v-row no-gutters justify="space-between">
                            <v-col cols="auto">
                                <div class="d-flex align-center">
                                    <v-avatar size="20">
                                        <v-img src="/images/test-img.jpg" />
                                    </v-avatar>
                                    <div class="txt txt--xs ml-6px">작성자</div>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">
                                        <div class="d-flex">
                                            <v-icon small class="material-icons-outlined">visibility</v-icon>
                                            <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="d-flex">
                                            <v-icon small class="material-icons-outlined">thumb_up</v-icon>
                                            <p class="txt txt--xs font-weight-medium ml-4px">1,234</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-img src="/images/test-img.jpg">
                        <v-responsive :aspect-ratio="1 / 1" />
                    </v-img>
                    <div class="txt txt--dark font-weight-medium text-truncate-2 mt-8px mt-lg-12px">게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.</div>
                </v-card>
            </v-col>
        </v-row>

        <v-sheet class="mt-40px mt-lg-60px">
            <v-row no-gutters justify="space-between">
                <v-col cols="auto">
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <v-select v-bind="{ ...attrs_input, loading }" dense class="w-100px"></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field v-bind="{ ...attrs_input, loading }" dense placeholder="검색어를 입력해주세요." class="w-300px" />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn small v-bind="{ ...btn_primary, loading }">검색</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed">글쓰기</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
    </div>
</template>

<script>
import { attrs_input, btn_primary, btn_secondary } from "@/assets/variables";
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";

export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        attrs_input,
        btn_primary,
        btn_secondary,
    }),
};
</script>
