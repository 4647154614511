var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isPc ? _c('div', {
    staticClass: "tit-wrap"
  }, [_c('v-row', {
    staticClass: "my-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s(_vm.title))])]), _vm.$slots['left'] ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("left")], 2)] : _vm._e(), _vm.$slots['right'] ? [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("right")], 2)] : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }