<template>
    <v-sheet>
        <div class="mb-16px mb-lg-34px d-flex">
            <v-btn x-large v-bind="{ ...btn_txt }" :to="path">
                <span class="font-weight-bold">#{{ category.name }}</span>
                <v-icon class="ml-8px">mdi-chevron-right</v-icon>
            </v-btn>
        </div>
        <v-row class="row--sm">
            <template v-for="value in boards">
                <v-col cols="12" :key="value._id">
                    <boards-item v-bind="{ path, value }" />
                </v-col>
            </template>
            <template v-if="boards.length < 1 && !loading">
                <v-col cols="12">
                    <span>게시글이 없습니다</span>
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { btn_txt } from "@/assets/variables";

import BoardsItem from "./boards/boards-item.vue";

export default {
    components: {
        BoardsItem,
    },
    props: {
        category: { type: Object, default: () => ({}) },
        limit: { type: [String, Number], default: 3 },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        btn_txt,

        loading: false,
    }),
    computed: {
        path() {
            return `/story/${this.category.code}`;
        },
        sort() {
            return JSON.stringify({ isNotice: -1, createdAt: -1, _id: -1 });
        },
    },
    methods: {
        async search() {
            if (!this.category.code) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                const { boards, summary } = await api.v1.boards.gets({
                    headers: { limit: this.limit, sort: this.sort },
                    params: { code: JSON.stringify({ $in: [...this.category.codes] }) },
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        category() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>