<template>
    <v-sheet class="py-6">
        <showcase v-bind="{ value, activeTag }" />
        <template v-if="0 < tags?.length || 0">
            <v-card-actions class="justify-center pa-0 mt-4">
                <v-slide-group v-model="activeTag" show-arrows class="ma-n4px">
                    <template v-for="{ tempId, product } in tags">
                        <v-slide-item :value="tempId" :key="tempId" v-slot="{ active, toggle }" class="ma-4px">
                            <v-card outlined rounded="sm" class="overflow-hidden" height="88" width="88" :color="active ? 'secondary' : undefined" @click="toggle">
                                <v-img :src="product?.thumb?.src" :aspect-ratio="1 / 1" class="grey lighten-5">
                                    <template #placeholder>
                                        <v-layout justify-center align-center fill-height>
                                            <v-icon>mdi-image-broken</v-icon>
                                        </v-layout>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-slide-item>
                    </template>
                </v-slide-group>
            </v-card-actions>
        </template>
    </v-sheet>
</template>

<script>
import Showcase from "@/components/dumb/showcase/showcase.vue";
import { initCommonShowcase } from "@/store/story";

export default {
    components: {
        Showcase,
    },
    props: {
        value: { type: Object, default: initCommonShowcase },
    },
    data: () => ({
        activeTag: null,
    }),
    computed: {
        tags() {
            return this.value.tags?.filter?.(({ _product }) => _product);
        },
    },
};
</script>

<style>
</style>