var render = function render(){
  var _vm$board, _vm$board$meta, _vm$board$meta$tags, _vm$board2, _vm$board2$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    staticClass: "row--xxs"
  }, [0 < (((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$meta = _vm$board.meta) === null || _vm$board$meta === void 0 ? void 0 : (_vm$board$meta$tags = _vm$board$meta.tags) === null || _vm$board$meta$tags === void 0 ? void 0 : _vm$board$meta$tags.length) || 0) ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hashtag-search', _vm._b({}, 'hashtag-search', {
    tags: ((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$meta = _vm$board2.meta) === null || _vm$board2$meta === void 0 ? void 0 : _vm$board2$meta.tags) || []
  }, false))], 1)] : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.items, function (item, i) {
    return [item.type == 'html' ? [_c(item.is, {
      key: i,
      tag: "component",
      staticClass: "board-content",
      domProps: {
        "innerHTML": _vm._s(item.html)
      }
    })] : _vm._e(), item.type == 'showcase' && (item.showcase._id || item.showcase.tempId) ? [_c('story-view-showcase', {
      key: i,
      attrs: {
        "value": item.showcase
      }
    })] : _vm._e()];
  })], 2), _vm.$scopedSlots['foot'] ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_vm._t("foot")], 2)]) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }