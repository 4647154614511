<template>
    <mini-shop-page>
        <!-- <v-sheet class="mb-16px mb-20px">
            <v-row no-gutters align="center">
                <v-spacer />
                <v-col cols="auto">
                    <common-filter hideSearchKeys />
                </v-col>
            </v-row>
        </v-sheet> -->
        <div class="tit-wrap tit-wrap--sm">
            <v-row class="row--xxs" align="center">
                <v-col cols="12" md="auto" class="tit tit--xxs">Chat</v-col>
            </v-row>
        </div>

        <v-divider dark style="border-width: 0 0 2px" class="mb-6" />

        <comment-input v-bind="{ isLoggedIn }" relative privatable v-on="{ save }" />

        <v-divider dark class="mt-6" />

        <comments v-model="comments" replyable privatable v-on="{ save, remove }" />

        <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-6" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
    </mini-shop-page>
</template>

<script>
import api from "@/api";
import { checkForBannedKeywords } from "@/plugins/vue-filter-contents";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import CommonFilter from "@/components/client/story/main/common/common-filter.vue";

import Comments from "@/components/dumb/comments/comments.vue";
import CommentInput from "@/components/dumb/comments/comment-input.vue";

export default {
    components: {
        MiniShopPage,

        CommonFilter,

        Comments,
        CommentInput,
    },
    props: {
        _user: { type: String, default: null },
    },
    data: () => ({
        comments: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        isLoggedIn() {
            return !!this.$store?.state?.payload?._user;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            return { ...query };
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _user, skip, limit, params } = this;
                const { comments, summary } = await api.v1.users.comments.gets({
                    _user,
                    headers: { skip, limit },
                    params,
                });

                this.comments = comments;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
        async save({ callback = () => {}, ...item } = {}) {
            try{
                const { _user } = this;

                const form = {
                    _id: item._id,
                    _user,
                    _comment: item?._comment || null,
                    content: item?.content || null,
                    mention: item?.mention || null,
                    isPrivate: item?.isPrivate || false,
                };
                if (!form._id) delete form._id;

                if(!this.validates(form)) return;

                const isCreate = !item?._id;
                const { post, put } = api.v1.users.comments;
                const { comment } = await (isCreate ? post : put)(form);

                if (!isCreate) {
                    const index = this.comments.findIndex(({ _id }) => _id == item?._id);
                    this.comments.splice(index, 1, comment);
                } else {
                    this.comments = [...this.comments, comment];
                    this.summary.totalCount += 1;
                }

                callback();
                this.search();
            } catch(error){
                this.$handleError(error);
            }
        },
        async remove(item) {
            if (confirm("삭제하시겠습니까?")) {
                if (this.loading) return;
                else this.loading = true;
                try {
                    await api.v1.users.comments.delete(item);

                    const targetIndex = this.comments.findIndex(({ _id }) => _id == item._id);
                    if (-1 < targetIndex) {
                        this.comments.splice(targetIndex, 1);
                        this.summary.totalCount -= 1;
                    }
                    this.loading = false;
                    await this.search();
                } finally {
                    if (this.loading) this.loading = false;
                }
            }
        },
        validates(form){
            const bannedKeywords = this.$store.state.setting.bannedKeywords;
            checkForBannedKeywords(form.content, bannedKeywords);
            return true;
        }
    },
    watch: {
        _user() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>