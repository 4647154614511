import { render, staticRenderFns } from "./riders-story-card.vue?vue&type=template&id=75e58b6d&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75e58b6d",
  null
  
)

export default component.exports