var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("Service")])]), _c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabItems, function (tab, index) {
    return _c('tab-primary', {
      key: index,
      attrs: {
        "to": tab.to,
        "exact": ""
      }
    }, [_vm._v(_vm._s(tab.text))]);
  }), 1), _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.services, function (service, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('mini-request-card', _vm._b({}, 'mini-request-card', {
      value: service,
      to: `./service/${service._id}`
    }, false))], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }