var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-tertiary', {
          attrs: {
            "tit": "Service",
            "bg": "/images/sub/store/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('visual-breadcrumbs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('store-category-nav')], 1), _c('page-section', {
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('store-hot')], 1), _c('page-section', {
    staticClass: "page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('store-best')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }