<template>
    <v-sheet>
        <calendar-head v-bind="{ date }" v-on="{ prev, next }" />
        <v-calendar
            v-model="date"
            v-bind="{
                weekdayFormat,
                events,
            }"
            :show-month-on-first="false"
            color="secondary"
            ref="calendar"
            @input="emit"
        >
            <template #day-label="props">
                <calendar-item
                    v-model="date"
                    v-bind="{
                        props,
                        loading,
                        holidays,
                        eventsArray,
                        excludedDates,
                    }"
                    @input="emit"
                    @holidayToggle="$emit('holidayToggle')"
                />
            </template>
        </v-calendar>
    </v-sheet>
</template>

<script>
import CalendarHead from "./calendar/calendar-head.vue";
import CalendarItem from "./calendar/calendar-item.vue";

export default {
    components: {
        CalendarHead,
        CalendarItem,
    },
    props: {
        loading: { type: Boolean, default: false },
        holidays: { type: Array, default: () => [] },
        eventsArray: { type: Array, default: () => [] },
        excludedDates: { type: Array, default: () => [] },
    },
    data: () => ({
        date: Date.now().toDate(),
    }),
    computed: {
        events() {
            return [...new Set(this.eventsArray.map(({ date }) => date))];
        },
    },
    methods: {
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        weekdayFormat(value) {
            return this.$dayjs().locale("en").set("day", value.weekday).format("ddd");
        },
        emit() {
            this.$emit("input", this.date);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-calendar-monthly,
    .v-calendar-weekly__day,
    .v-calendar-weekly__head-weekday {
        border: none !important;
    }
    .v-outside {
        background-color: transparent !important;
    }
    .v-past,
    .v-present,
    .v-future {
        color: var(--v-grey-lighten2) !important;
    }
    .v-calendar-weekly__head-weekday {
        text-transform: capitalize;
    }
    .v-calendar-weekly__day {
        overflow: visible;
    }
}
</style>