<template>
    <div>
        <v-divider style="border-width: 2px 0 0" dark />
        <div class="py-20px">
            <v-row class="row--xxs" justify="space-between" align="center">
                <v-col cols="auto">
                    <h4 class="tit tit--xs">게시글 제목이 옵니다.</h4>
                </v-col>
                <v-col cols="auto">
                    <div class="divider-group tit--xxs">
                        <ul>
                            <li>2024.02.02</li>
                            <li>조회수 13회</li>
                            <li class="d-flex align-center"><v-icon small class="material-icons-outlined mr-4px">thumb_up</v-icon>75</li>
                        </ul>
                    </div>
                </v-col>
            </v-row>
            <v-row class="row--xxs">
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <v-avatar size="30">
                            <v-img src="/images/test-img.jpg" />
                        </v-avatar>
                        <div class="txt txt--sm ml-6px">작성자</div>
                    </div>
                </v-col> 
            </v-row>
        </div>
        <v-divider />
        <div class="py-20px">
            <v-row class="row--xxs">
                <v-col cols="12">
                    <v-chip-group>
                        <v-chip v-bind="{ ...chip_secondary }">#태그</v-chip>
                        <v-chip v-bind="{ ...chip_secondary }">#가</v-chip>
                        <v-chip v-bind="{ ...chip_secondary }">#옵니다</v-chip>
                    </v-chip-group>
                </v-col>
                <v-col cols="12">글 내용이 옵니다.</v-col>
                <v-col cols="12">
                    <div class="d-flex justify-center align-center">
                        <v-btn v-bind="{ ...btn_icon }">
                            <v-icon class="material-icons-outlined">thumb_up</v-icon>
                        </v-btn>
                        <span class="font-weight-medium txt txt--sm">75</span>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="btn-wrap">
            <v-row class="row--xxs">
                <v-col cols="auto">
                    <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">수정</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">삭제</v-btn>
                </v-col>
                <v-col><v-spacer></v-spacer></v-col>
                <v-col cols="auto">
                    <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">목록</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { btn_secondary, btn_icon, btn_txt, chip_secondary } from "@/assets/variables";

export default {
    data: () => ({
        btn_secondary,
        chip_secondary,
        btn_icon,
        btn_txt,
    }),
};
</script>
