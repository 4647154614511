<template>
    <div>
        <div class="tit-wrap tit-wrap--lg text-center">
            <h2 class="tit">이달의 <span class="red--text">BEST</span> 협력사</h2>
        </div>
        <v-tabs grow class="mb-40px mb-lg-60px">
            <tab-primary exact v-for="(tab, index) in bestTabs" :key="index" :to="tab.to">
                {{ tab.text }}
            </tab-primary>
        </v-tabs>
        <v-tabs-items :value="tab">
            <v-tab-item v-for="(tab, index) in bestTabs" :key="index" :value="tab.to.query.category">
                <v-row class="row--xs">
                    <v-col v-for="(store, index) in shops" :key="index" cols="6" xl="4">
                        <store-profile :value="store" best>
                            <template #avatar>
                                <v-img v-if="store.thumb" :src="store.thumb.src" />
                                <v-img v-else src="/images/minishop/profile-default.png" />
                            </template>
                        </store-profile>
                    </v-col>
                </v-row>
                <div class="btn-wrap btn-wrap--lg d-flex justify-center">
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" x-large class="v-btn--width-fixed" @click="()=>$router.push(goPath)"> 전체보기 </v-btn>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import api from "@/api";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import { btn_primary, COMPANY_CATEGORY_TYPES } from "@/assets/variables";
import StoreProfile from "@/components/client/sub/store/store-profile.vue";
export default {
    components:{
        TabPrimary,
        StoreProfile
    },
    data: () => ({
        btn_primary,
        shops: [],

        COMPANY_CATEGORY_TYPES
    }),
    computed:{
        bestTabs(){
            return [
               { text: "전기자전거", to: { path: "/sub/store", query: { category: COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value }}},
               { text: "전동스쿠터", to: { path: "/sub/store", query: { category: COMPANY_CATEGORY_TYPES.ELC_SCOOTER.value }}},
               { text: "전동킥보드", to: { path: "/sub/store", query: { category: COMPANY_CATEGORY_TYPES.ELC_KICKBOARD.value }}}
           ]
        },
        tab(){
            return this.$route.query.category || COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value;
        },
        goPath(){
            switch(this.tab){
                case COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value:
                    return "/sub/store/bicycle";
                case COMPANY_CATEGORY_TYPES.ELC_SCOOTER.value:
                    return "/sub/store/scooter";
                case COMPANY_CATEGORY_TYPES.ELC_KICKBOARD.value:
                    return "/sub/store/kickboard";
            }
        }
    },
    methods: {
        init() {
            if(!this.$route.query.category) this.$route.query.category= COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value;
            this.search();
        },
        async search(){
            try{
                let { stores } = await api.v1.shop.stores.gets({
                    headers: { skip: 0, limit: 3, sort: JSON.stringify({ index: -1 })},
                    params: { category: this.$route.query.category } 
                });

                this.shops = stores;
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.init();
    },
    watch:{
        "$route.query.category"(){
            this.search();
        }
    }
};
</script>

<style>

</style>