<template>
    <v-sheet>
        <template v-for="(category, i) in categories">
            <template v-if="category.itemType == STORY_ITEM_TYPES.SLIDE.value">
                <article-slides v-bind="{ category }" :class="{ 'pt-40px pt-lg-60px': 0 < i }" :key="category._id" />
            </template>
            <template v-if="category.itemType == STORY_ITEM_TYPES.THUMB.value">
                <common-board v-bind="{ category }" :class="{ 'pt-40px pt-lg-60px': 0 < i }" :key="category._id" />
            </template>
        </template>
    </v-sheet>
</template>

<script>
import { btn_txt } from "@/assets/variables";
import { initStoryCategory, STORY_ITEM_TYPES } from "@/store/story";

import ArticleSlides from "./article/article-slides.vue";
import CommonBoard from "./common/common-board.vue";

export default {
    components: {
        ArticleSlides,
        CommonBoard,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
    },
    data: () => ({
        btn_txt,
        STORY_ITEM_TYPES,
    }),
    computed: {
        categories() {
            return this.category?.children || [];
        },
    },
    methods: {},
};
</script>

<style>
</style>