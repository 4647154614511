<template>
    <v-sheet width="100%" style="position: relative" :class="className" class="fill-height">
        <v-fade-transition leave-absolute>
            <v-hover v-if="imageSrc" v-slot="{ hover }">
                <v-img ref="showcase-area" :src="imageSrc" v-bind="$attrs" style="position: relative; cursor: pointer" @click="pushTag" @resize="setArea" @load="setArea" :key="value.image.name">
                    <template v-if="editable_image">
                        <v-overlay absolute color="transparent" :dark="false" style="pointer-events: none" class="d-block">
                            <v-row class="ma-3 row--xxs">
                                <v-col cols="auto">
                                    <v-tooltip bottom>
                                        <template #activator="{ attrs, on }">
                                            <v-fade-transition>
                                                <v-btn v-show="hover" v-bind="attrs" v-on="on" fab x-small color="white" elevation="4" style="pointer-events: auto" @click.stop="getImage">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-btn>
                                            </v-fade-transition>
                                        </template>
                                        <span>변경</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-tooltip bottom>
                                        <template #activator="{ attrs, on }">
                                            <v-fade-transition>
                                                <v-btn v-show="hover" v-bind="attrs" v-on="on" fab x-small color="white" elevation="4" style="pointer-events: auto" @click.stop="$emit('pull', value)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-fade-transition>
                                        </template>
                                        <span>삭제</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-overlay>
                    </template>
                    <template v-if="isImageLoaded">
                        <template v-for="(item, z) in showcase.tags">
                            <v-fade-transition :key="item._id">
                                <showcase-tag v-show="showsTags" v-model="showcase.tags[z]" v-bind="{ activeTag, area, editable, z }" v-on="{ pullTag, input: emit }" :ref="item._id" />
                            </v-fade-transition>
                        </template>
                    </template>
                </v-img>
            </v-hover>
        </v-fade-transition>
        <v-file-input ref="image-input" v-show="false" @change="setImage" />
    </v-sheet>
</template>

<script>
import { initCommonShowcase, initCommonShowcaseTag } from "@/store/story";

import ShowcaseTag from "./showcase-tag.vue";

export default {
    components: {
        ShowcaseTag,
    },
    props: {
        value: { type: Object, default: initCommonShowcase },
        editable: { type: Boolean, default: false },
        editable_image: { type: Boolean, default: false },
        activeTag: { type: [String, Number], default: null },
        className: { type: String, default: "" },
    },
    data: () => ({
        showcase: initCommonShowcase(),

        area: { w: 100, h: 100 },

        showsTags: true,
        isImageLoaded: false,
    }),
    computed: {
        imageSrc() {
            if (this.showcase.image instanceof File) {
                return URL.createObjectURL(this.showcase.image);
            } else {
                return this.showcase.image?.url;
            }
        },
    },
    methods: {
        getImage() {
            this.$refs["image-input"]?.$el?.getElementsByTagName("input")?.[0]?.click();
        },
        setImage(file) {
            this.showcase.image = file;
            this.$refs["image-input"]?.$on?.click?.clear?.();
            this.emit();
        },
        pushTag(event) {
            if (!this.editable) {
                this.showsTags = !this.showsTags;
                return;
            }

            if (!confirm("아이템을 추가하시겠습니까?")) return;

            this.setArea();

            const { w, h } = this.area;
            const { layerX, layerY } = event;
            const x = (layerX / w) * 100;
            const y = (layerY / h) * 100;
            this.showcase.tags.push(initCommonShowcaseTag({ x, y }));
            this.emit();
        },
        pullTag(target) {
            this.showcase.tags = this.showcase.tags.filter((item) => item !== target);
            this.emit();
        },
        emit() {
            this.$emit("input", this.showcase);
        },
        setArea() {
            const { clientWidth: w, clientHeight: h } = this.$refs["showcase-area"]?.$el || {};
            this.area = { w, h };
            if (!this.isImageLoaded) this.isImageLoaded = true;
        },
        hideTags() {
            this.showcase.tags.forEach(({ _id }) => (this.$refs[_id][0].shows = false));
        },
    },
    created() {
        window.addEventListener("resize", this.setArea);
    },
    mounted() {
        this.showcase = initCommonShowcase({
            ...this.value,
            tags: (this.value?.tags || []).map(initCommonShowcaseTag),
        });

        const interval = setInterval(() => {
            const { clientWidth: w, clientHeight: h } = this.$refs["showcase-area"]?.$el || {};
            this.area = { w, h };
            if (100 < w && 100 < h) clearInterval(interval);
        }, 100);
    },
    destroyed() {
        window.removeEventListener("resize", this.setArea);
    },
    watch: {
        value() {
            this.showcase = {
                ...this.showcase,
                ...this.value,
            };
        },
    },
};
</script>

<style></style>
