<template>
    <span>
        <div id="map">
            <actions-current />
            <actions-search v-if="showsSearch" v-bind="{ lat, lng, lvl, queryLat, queryLng, queryLvl }" @search="search" />
            <!-- <map-sheet-kakaomap v-bind="{ lat, lng, lvl, maxLevel: 7, locations: clusters }" @search="search" /> -->
            <map-sheet-kakaomap v-bind="{ lat, lng, lvl, maxLevel: 8, locations }" @search="search" />
        </div>
    </span>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ActionsCurrent from "@/components/plugins/kakao/actions-current.vue";
import ActionsSearch from "@/components/plugins/kakao/actions-search.vue";
import MapSheetKakaomap from "@/components/client/sub/store/map-sheet-kakaomap.vue";
export default {
    components: {
        ActionsCurrent,
        ActionsSearch,
        MapSheetKakaomap,
    },
    data: () => ({
        // clusters: [],
        locations: [],
        showsSearch: false,
    }),
    
    computed: {
        ...mapState("location", ["lat", "lng", "lvl", "bounds"]),
        ...mapGetters("location", ["geolocation", "geometry"]),
        queryLat() {
            return +this.$route.query.lat || null;
        },
        queryLng() {
            return +this.$route.query.lng || null;
        },
        queryLvl() {
            return +this.$route.query.lvl || null;
        },
        searchValue() {
            return this.$route.query.searchValue || null;
        },
        coordinates() {
            const { lat, lng } = this.$route.query;
            return `${lat}${lng}`;
        },
        isNew() {
            return this.$route.query.isNew;
        },
    },
    methods: {
        ...mapMutations("location", ["setCoordinates", "setLvl", "setBounds"]),
        ...mapActions("location", ["getCurrentLocation", "getSidoGugun", "getAddressLocation"]),
        async search({ withoutRouting = false } = {}) {
            try {
                this.showsSearch = false;
                const { lat, lng, lvl } = this;
                if (!withoutRouting) this.$router.push({ query: { ...this.$route.query, lat, lng, lvl } });

                const { bounds, geolocation: near } = this;
                // const { clusters } = await api.v1.map.getMapClusters({ params: { bounds, near } });
                // this.clusters = clusters;
                const { locations } = await api.v1.map.getMapClusters({ params: { bounds, near } });
                this.locations = locations;

                this.showsSearch = true;
                // this.$nextTick(() => {
                //     console.log(this.isNew)
                //     if(!this.isNew){
                //         const query = { ...this.$route.query };
                //         delete query._location;
                //         this.$router.push({ query });
                //     }
                // })
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        if (!!this.queryLat && !!this.queryLng) {
            const lat = this.queryLat;
            const lng = this.queryLng;
            const lvl = this.queryLvl || 5;

            this.setLvl({ lvl });
            this.setCoordinates({ lat, lng });
        } else this.getCurrentLocation();
    },
    watch: {
        coordinates() {
            if (this.isNew || !this.$route.query._location)
                this.$nextTick(() => {
                    this.search({ withoutRouting: true });
                });
        },
    },
};
</script>

<style>
#map {
    position: relative;
    height: calc(100vh - var(--header-body-height));
}
@media (min-width: 1024px) {
    #map {
        height: 100%;
    }
}
</style>