var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', _vm._b({
    on: {
      "dragging": _vm.onDragging,
      "dragstop": _vm.onDragStop
    }
  }, 'vue-draggable-resizable', _vm.attrs, false), [_c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "close-on-content-click": false,
      "offset-y": "",
      "content-class": "showcase-menu"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return _c('v-btn', _vm._g(_vm._b({
          staticStyle: {
            "transform": "translate(-50%, -50%)"
          },
          attrs: {
            "icon": "",
            "x-small": "",
            "text": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-img', {
          attrs: {
            "max-width": "24",
            "src": "/images/icon/icon-showcase.svg"
          }
        })], 1);
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.editable && !_vm.tag.product ? [_c('showcase-tag-edit', _vm._g({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.tag.product,
      callback: function ($$v) {
        _vm.$set(_vm.tag, "product", $$v);
      },
      expression: "tag.product"
    }
  }, {
    setShows: _vm.setShows,
    pullTag: _vm.pullTag
  }))] : [_c('showcase-tag-view', _vm._g({
    on: {
      "iinput": _vm.emit
    },
    model: {
      value: _vm.tag.product,
      callback: function ($$v) {
        _vm.$set(_vm.tag, "product", $$v);
      },
      expression: "tag.product"
    }
  }, {
    pullTag: _vm.pullTag
  }))]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }