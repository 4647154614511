var render = function render(){
  var _vm$tags;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "py-6"
  }, [_c('showcase', _vm._b({}, 'showcase', {
    value: _vm.value,
    activeTag: _vm.activeTag
  }, false)), 0 < ((_vm$tags = _vm.tags) === null || _vm$tags === void 0 ? void 0 : _vm$tags.length) || 0 ? [_c('v-card-actions', {
    staticClass: "justify-center pa-0 mt-4"
  }, [_c('v-slide-group', {
    staticClass: "ma-n4px",
    attrs: {
      "show-arrows": ""
    },
    model: {
      value: _vm.activeTag,
      callback: function ($$v) {
        _vm.activeTag = $$v;
      },
      expression: "activeTag"
    }
  }, [_vm._l(_vm.tags, function (_ref) {
    var tempId = _ref.tempId,
      product = _ref.product;
    return [_c('v-slide-item', {
      key: tempId,
      staticClass: "ma-4px",
      attrs: {
        "value": tempId
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var _product$thumb;
          var active = _ref2.active,
            toggle = _ref2.toggle;
          return [_c('v-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "outlined": "",
              "rounded": "sm",
              "height": "88",
              "width": "88",
              "color": active ? 'secondary' : undefined
            },
            on: {
              "click": toggle
            }
          }, [_c('v-img', {
            staticClass: "grey lighten-5",
            attrs: {
              "src": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.src,
              "aspect-ratio": 1 / 1
            },
            scopedSlots: _vm._u([{
              key: "placeholder",
              fn: function () {
                return [_c('v-layout', {
                  attrs: {
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": ""
                  }
                }, [_c('v-icon', [_vm._v("mdi-image-broken")])], 1)];
              },
              proxy: true
            }], null, true)
          })], 1)];
        }
      }], null, true)
    })];
  })], 2)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }