var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("Wishlist")])]), _c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "wishlist": "",
        "value": product,
        "sm": "6",
        "md": "6",
        "lg": "3"
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }