var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "model-value": "",
      "height": "auto",
      "hide-slider": ""
    },
    model: {
      value: _vm.sort,
      callback: function ($$v) {
        _vm.sort = $$v;
      },
      expression: "sort"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('tab-tertiary', {
      key: item.text,
      attrs: {
        "value": _vm.sort
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }