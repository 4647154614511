<template>
    <v-card :to="`/story/${board.code}/${board._id}`" class="notice-slide-card">
        <v-row>
            <v-col cols="12" lg="5" order-lg="2">
                <v-card v-if="board.storyThumbSrc" :img="board.storyThumbSrc" tile flat>
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-card>
                <v-card v-else img="/images/sub/story/storyDefault.jpg" tile flat>
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-card>
            </v-col>
            <v-col cols="12" lg="7">
                <div class="tit-wrap">
                    <h4 class="tit font-weight-regular text-truncate-2">{{ board.subject }}</h4>
                </div>
                <p class="txt text-truncate-3">{{ board.contentText }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { initStoryBoard } from "@/store/story";

export default {
    props: {
        board: { type: Object, default: initStoryBoard },
    },
};
</script>

<style lang="scss" scoped>
</style>