<template>
    <vue-draggable-resizable v-bind="attrs" @dragging="onDragging" @dragstop="onDragStop">
        <v-menu v-model="shows" open-on-hover :close-on-content-click="false" offset-y content-class="showcase-menu">
            <v-btn slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" @click.stop icon x-small style="transform: translate(-50%, -50%)" text>
                <v-img max-width="24" src="/images/icon/icon-showcase.svg" />
            </v-btn>
            <template v-if="editable && !tag.product">
                <showcase-tag-edit v-model="tag.product" v-on="{ setShows, pullTag }" @input="emit" />
            </template>
            <template v-else>
                <showcase-tag-view v-model="tag.product" v-on="{ pullTag }" @iinput="emit" />
            </template>
        </v-menu>
    </vue-draggable-resizable>
</template>

<script>
import { initCommonShowcaseTag } from "@/store/story";

import VueDraggableResizable from "vue-draggable-resizable";

import ShowcaseTagEdit from "./showcase-tag-edit.vue";
import ShowcaseTagView from "./showcase-tag-view.vue";

export default {
    components: {
        VueDraggableResizable,

        ShowcaseTagEdit,
        ShowcaseTagView,
    },
    props: {
        value: { type: Object, default: initCommonShowcaseTag },

        activeTag: { type: [String, Number], default: null },
        area: { type: Object, default: () => ({ w: 100, h: 100 }) },
        editable: { type: Boolean, default: false },
        z: { type: Number, default: 0 },
        hover: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        tag: initCommonShowcaseTag(),
    }),
    computed: {
        attrs() {
            const { z, value, area, editable } = this;
            const x = (value.x / 100) * area.w;
            const y = (value.y / 100) * area.h;
            return { x, y, z, w: 1, h: 1, resizable: false, draggable: editable };
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.tag = initCommonShowcaseTag(this.value);
        },
        emit() {
            this.$emit("input", this.tag);
        },
        onDragging() {
            if (this.shows) this.shows = false;
        },
        onDragStop(x, y) {
            const { w, h } = this.area;
            x = (x / w) * 100;
            y = (y / h) * 100;

            this.tag = { ...this.tag, x, y };
            this.shows = true;
            this.emit();
        },
        setShows() {
            this.shows = false;
        },
        pullTag() {
            this.$emit("pullTag", this.value);
        },
    },
    watch: {
        "value.name"() {
            this.sync();
        },
        "value.detail"() {
            this.sync();
        },
        "value.url"() {
            this.sync();
        },
        activeTag() {
            if (this.activeTag == this.tag.tempId) {
                if (!this.shows) this.shows = true;
            } else {
                if (this.shows) this.shows = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.showcase-menu {
    border: none;
    background-color: transparent;

    overflow: visible;
    contain: none;

    .v-btn.v-btn--icon {
        width: auto !important;
        height: auto !important;
    }
}
.showcase-menu .v-card {
    margin-top: 6px;
}
.showcase-menu .v-card--link:focus:before,
.showcase-menu .v-card .v-ripple__container {
    display: none !important;
}
@media (min-width: 768px) {
    .showcase-menu {
        transform: translateX(-135px);
    }
}
</style>
