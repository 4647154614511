<template>
    <v-row class="row--xxs">
        <v-col cols="12">
            <v-img src="/images/test.jpg" alt="" />
        </v-col>
        <v-col cols="12">
            <v-sheet class="mx-auto w-100 w-lg-70">
                <v-slide-group show-arrows>
                    <template v-for="(item, index) in items">
                        <v-slide-item :key="index">
                            <v-img :src="item.src" class="border rounded rounded-xs mx-4px w-60px w-lg-90px">
                                <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                            </v-img>
                        </v-slide-item>
                    </template>
                </v-slide-group>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        items: [{ src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }, { src: "/images/test-img.jpg" }],
    }),
};
</script>

<style lang="scss" scoped>
.v-slide-group {
    ::v-deep() {
        .v-slide-group__wrapper {
            margin: 0 -4px;
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 30px;
                height: 100%;
            }
            &::before {
                z-index: 1;
                left: 0;
                background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, transparent 100%);
            }
            &::after {
                right: 0;
                background-image: linear-gradient(to left, rgb(255, 255, 255) 50%, transparent 100%);
            }
        }
        .v-slide-group__prev,
        .v-slide-group__next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: var(--v-primary-base);
            width: 32px;
            height: 32px;
            border-radius: 999px;
            min-width: initial !important;
            .v-icon {
                color: #fff !important;
            }
            &--disabled {
                background-color: var(--v-grey-lighten3);
            }
        }
        .v-slide-group__prev {
            position: absolute;
            top: 50%;
            left: -48px;
            width: 32px;
            height: 32px;
            min-width: initial !important;
            transform: translateY(-50%);
        }
        .v-slide-group__next {
            right: -48px;
        }
        .v-slide-group__prev + .v-slide-group__wrapper {
            &::after {
                display: none;
            }
        }
        .v-slide-group__prev--disabled + .v-slide-group__wrapper {
            &::after {
                display: block;
            }
            &::before {
                display: none;
            }
        }
    }
}
</style>