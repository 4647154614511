var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test.jpg",
      "alt": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "mx-auto w-100 w-lg-70"
  }, [_c('v-slide-group', {
    attrs: {
      "show-arrows": ""
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-slide-item', {
      key: index
    }, [_c('v-img', {
      staticClass: "border rounded rounded-xs mx-4px w-60px w-lg-90px",
      attrs: {
        "src": item.src
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    })], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }