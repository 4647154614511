<template>
    <mini-shop-page v-model="user" @input="save">
        <div v-if="user?.type === USER_TYPES.COMPANY.value">
            <v-row>
                <!-- BEST -->
                <v-col cols="12" lg="6">
                    <div class="tit-wrap tit-wrap--xs">
                        <v-row no-gutters justify="space-between" align="center">
                            <v-col cols="auto">
                                <h3 class="txt txt--dark font-weight-bold">BEST</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn v-bind="{ ...btn_txt }" x-small :to="`./${user?._id}/best`">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <best-list-card v-if="user?._id" v-bind="attrs__bestListCard" v-slot="{ products }">
                        <template>
                            <shop-product-items>
                                <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="6" :rank="index + 1" />
                            </shop-product-items>
                        </template>
                    </best-list-card>
                </v-col>
                <!-- // BEST -->

                <!-- Request -->
                <v-col cols="12" lg="6">
                    <div class="tit-wrap tit-wrap--xs">
                        <v-row no-gutters justify="space-between" align="center">
                            <v-col cols="auto">
                                <h3 class="txt txt--dark font-weight-bold">Request</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn v-bind="{ ...btn_txt }" x-small :to="{ path: `./${user?._id}/request/service`, query: { type: COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value, page: 1 } }">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <request-list-card v-if="user?._id" v-bind="attrs__requestListCard" v-slot="{ services }">
                        <v-row class="row--sm">
                            <v-col cols="6" v-for="(service, index) in services" :key="index">
                                <template>
                                    <mini-request-card :to="`./${user._id}/request/service/${service._id}`" :value="service" />
                                </template>
                            </v-col>
                        </v-row>
                    </request-list-card>
                </v-col>
                <!-- // Request -->
            </v-row>

            <v-divider class="my-40px my-lg-60px" />
        </div>

        <!-- 일상 -->
        <v-row>
            <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="auto">
                            <h3 class="txt txt--dark font-weight-bold">일상</h3>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_txt }" x-small :to="`${$route.path}/story/daily`">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>

                <story-list-card v-if="user?._id" tag="v-row" class="row--sm" v-bind="attrs__storyListCard" v-slot="{ boards }">
                    <template>
                        <v-col cols="6" lg="3" v-for="board in boards" :key="board._id">
                            <mini-daily-card v-bind="{ board }" />
                        </v-col>
                    </template>
                </story-list-card>
            </v-col>
        </v-row>
        <!-- // 일상 -->

        <v-divider class="my-40px my-lg-60px" />

        <!-- 라이더 이야기 -->
        <v-row>
            <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="auto">
                            <h3 class="txt txt--dark font-weight-bold">라이더 이야기</h3>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_txt }" x-small :to="`${$route.path}/story/rider`">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>

                <story-list-card v-if="user?._id" tag="v-simple-table" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit rounded-0" v-bind="attrs__riderListCard" v-slot="{ items }">
                    <tbody>
                        <template v-if="items.length < 1">
                            <tr>
                                <td>등록된 게시글이 없습니다.</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-for="item in items" :key="item._id" class="cursor-pointer" @click="$router.push(`${$route.path}/story/rider/${item._id}`)">
                                <th class="w-md-20 w-xl-10 text-center">[{{ item.categoryText }}]</th>
                                <td class="w-xl-80 text-truncate text-center text-md-start">{{ item.subject }}</td>
                                <td class="w-md-20 w-xl-10 text-center">{{ $dayjs(item.createdAt).format("YY.MM.DD") }}</td>
                            </tr>
                        </template>
                    </tbody>
                </story-list-card>
            </v-col>
        </v-row>
        <!-- // 라이더 이야기 -->
        <!-- 추가 카테고리 -->
        <div v-if="mini?.storyCodes.length">
            <v-row v-for="(code, index) in mini.storyCodes" :key="index">
                <v-col cols="12">
                    <div class="tit-wrap tit-wrap--xs">
                        <v-row no-gutters justify="space-between" align="center">
                            <v-col cols="auto">
                                <h3 class="txt txt--dark font-weight-bold">{{ code?.name }}</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn v-bind="{ ...btn_txt }" x-small :to="`${$route.path}/story/${code.path}`">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <story-list-card v-if="user?._id" tag="v-row" class="row--sm" v-bind="additionalStoryListCard(code)" v-slot="{ boards }">
                        <template>
                            <v-col cols="6" lg="3" v-for="board in boards" :key="board._id">
                                <mini-daily-card v-bind="{ board }" />
                            </v-col>
                        </template>
                    </story-list-card>
                </v-col>
            </v-row>
        </div>
        <!-- //추가 카테고리 -->
    </mini-shop-page>
</template>

<script>
import api from "@/api";
import { btn_txt, COMMON_LOG_TYPES, USER_TYPES, COMPANY_CATEGORY_TYPES, initUser } from "@/assets/variables";

import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import MiniRequestCard from "@/components/client/miniShop/mini-request-card.vue";
import MiniDailyCard from "@/components/client/miniShop/mini-daily-card.vue";

import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

import StoryListCard from "@/components/client/story/list/story-list-card.vue";
import BestListCard from "@/components/client/miniShop/list/best-list-card.vue";
import RequestListCard from "@/components/client/miniShop/list/request-list-card.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        MiniShopPage,

        MiniRequestCard,
        MiniDailyCard,

        ShopProductItems,
        ShopProductItem,
        StoryListCard,
        BestListCard,
        RequestListCard,
    },
    data: () => ({
        user: initUser(),
        btn_txt,
        USER_TYPES,
        COMPANY_CATEGORY_TYPES,
    }),
    computed: {
        ...mapGetters("story", ["getCategory", "categories__flatMapped"]),
        ownerCodes() {
            return this.getCategory("owner")?.codes;
        },
        riderCodes() {
            return this.getCategory("rider")?.codes;
        },
        attrs__bestListCard() {
            return { query: { _seller: this.user?._id }, limit: 2 };
        },
        attrs__requestListCard() {
            return { query: { _user: this.user?._id }, limit: 2 };
        },
        attrs__storyListCard() {
            const scope = this.$store.state.scope || [];
            let codes = this.categories__flatMapped.filter((category) => category?.scope__main?.length);
            //(비로그인 시, 권한 설정된 게시글 모두 비공개)
            codes = (scope.length ? codes.filter((category) => !scope?.some((el) => (category?.scope__main || []).includes(el))) : codes).map((category) => category?.code || "");

            return { query: { _writer: this.user?._id, code: JSON.stringify({ $nin: [...codes, ...this.riderCodes, ...this.ownerCodes] }) }, limit: 4 };
        },
        attrs__riderListCard() {
            return { query: { _writer: this.user?._id, code: JSON.stringify({ $in: this.riderCodes }) }, limit: 4 };
        },
        mini() {
            return this.user.mini;
        },
    },
    methods: {
        async save(user) {
            try {
                //태그 저장
                if (user?.mini?.tag.length > 0) {
                    if (this.$store.state?.payload?._user !== user?._id) {
                        await Promise.all(
                            user.mini.tag.map((value) => {
                                api.v1.shop.logs.saving({ value, type: COMMON_LOG_TYPES.TAG.value, _shop: user._id });
                            })
                        );
                    }
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
        additionalStoryListCard(code) {
            return { query: { _writer: this.user?._id, "meta.mini.code": code.code, code: JSON.stringify({ $nin: [...this.riderCodes, ...this.ownerCodes] }) }, limit: 4 };
        },
    },
};
</script>