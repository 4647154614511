var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('story-view', _vm._b({
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  }, 'story-view', {
    code: _vm.code,
    _board: _vm._board
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }