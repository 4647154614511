<template>
    <div id="newDivLayer" ref="newDivLayer">
        <iframe id="newPayIframe" name="newPayIframe" marginwidth="0" marginheight="0" frameborder="no" scrolling="no" allowtransparency="true"  ref="newPayIframe" />

        <form name="newPayForm" ref="newPayForm">
            <input name="NPjsVersion" type="hidden" :value="NPjsVersion" />
            <input name="NPappType" type="hidden" :value="NPappType" />
            <input name="NPbrowser" type="hidden" :value="NPbrowser" />
            <input name="NPos" type="hidden" :value="NPos" />
            <input name="NPsvcType" type="hidden" :value="NPsvcType" />
            <input name="clientId" type="hidden" :value="clientId" />
            <input name="orderId" type="hidden" :value="orderId" />
            <input name="goodsName" type="hidden" :value="goodsName" />
            <input name="amount" type="hidden" :value="amount" />
            <input name="method" type="hidden" :value="method" />
            <input name="vbankHolder" type="hidden" :value="vbankHolder" />
            <input name="returnUrl" type="hidden" :value="returnUrl" />
        </form>
    </div>
</template>
<script>
import api from "@/api";
export default {
    data(){
        let { collection, orderNo, orderName, paymentMethod, paymentAmount, vbankHolder  } = this.$route.query;

        return {
            collection,

            NPjsVersion: "nicepay-start.1.0",
            NPappType: "",
            NPbrowser: "",
            NPos: "",
            NPsvcType: "",

            clientId: "",
            orderId: orderNo,
            goodsName: orderName,
            amount: paymentAmount,
            method: paymentMethod,
            vbankHolder,
            returnUrl: ""
        };
    },
    mounted(){
        window.nicepayComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.close();
        };

        this.request();
    },
    methods: {
        close(){
            parent?.window?.nicepayClose?.();
            opener?.window?.nicepayClose?.();
        },
        async request(){
            let { clientId } = await api.plugins.nice.nicepayments.config();
            let { NPappType, nicePaymentDomain } = this.parse(clientId);
			console.log(NPappType, nicePaymentDomain)

            this.clientId = clientId;
            this.NPappType = NPappType;
            this.NPbrowser = this.platform.name +" , " + this.platform.version,
            this.NPos = this.platform.platform,
            this.NPsvcType = (this.platform.mobile || this.platform_mobile.mobile) ? "02" : "01",
            this.returnUrl = this.returnURL;

            this.$nextTick(() => {
                var newDivLayer = this.$refs.newDivLayer;
                var newPayForm = this.$refs.newPayForm; 
                var newPayIframe = this.$refs.newPayIframe;
                newDivLayer.style.display = "block";
                newPayForm.acceptCharset = "UTF-8";
                newPayForm.method="post";
                newPayForm.action = nicePaymentDomain + "/v1/pay";

                // 네이버페이의 경우, iframe 새 창에서 띄우기 (sameorigin 에러 방지)
                if(this.method === "naverpayCard"){
                    newPayForm.target = "_parent";
                }
    
                if(this.platform.mobile || this.platform_mobile.mobile){ 
                    newDivLayer.appendChild(newPayForm); 
                }else {
                    newPayForm.target = "newPayIframe"; 
                    newPayIframe.appendChild(newPayForm);  
                }
    
                if(window.addEventListener){
                    window.addEventListener("message", (e) => {
                        if(e.origin.indexOf("nicepay") < 0) return;
    
                        var rsp = JSON.parse(e.data);
                        // 호출이 안된다면 확인
                        // console.log("rsp", rsp.result);
                        if(rsp.type == "callback.submit"){
                            // console.log("rsp", rsp);
                            var newResultForm = document.createElement("form") ;
                            newResultForm.method="post";
                            newResultForm.acceptCharset=rsp.charset;
                            newResultForm.target = "_self"; 
                            newResultForm.action = this.returnURL;  
                            for(var key in rsp.result) {
                                var formEl = document.createElement("input");
                                formEl.type = "hidden";
                                formEl.name = key;
                                if(key == "mallReserved"){
                                    formEl.value = JSON.stringify(rsp.result[key]).replace(/\"/gi,"").replace(/&#034;/g,"\"").replace(/&#039;/g,"\'").replace(/&lt;/g,"<").replace(/&gt;/g,">");
                                }else{
                                    formEl.value = rsp.result[key];	
                                }
                                newResultForm.appendChild(formEl);
                            }
                            document.body.appendChild(newResultForm);
                            newResultForm.submit();
                        }
                        else this.close();
                    }, false); 
                }
                newPayForm.submit(); 
                if(document.body && document.body.scrollTop){
                    document.body.style.overflowX = "hidden";
                }else if(document.documentElement){
                    document.documentElement.style.overflowX = "hidden";
                }
            });
        },

        parse(clientId){
            if(clientId.substr(0,3) == "R1_"){
				return {
                    NPappType: "APPR",
                    nicePaymentDomain: "https://pay.nicepay.co.kr"
                };
			}else if(clientId.substr(0,3) == "R2_"){
                return {
                    NPappType: "AUTH",
                    nicePaymentDomain: "https://pay.nicepay.co.kr",
                }
			}else if(clientId.substr(0,3) == "S1_"){
                return {
                    NPappType: "APPR",
                    nicePaymentDomain: "https://sandbox-pay.nicepay.co.kr",
                }
			}else if(clientId.substr(0,3) == "S2_"){
                return {
                    NPappType: "AUTH",
                    nicePaymentDomain: "https://sandbox-pay.nicepay.co.kr",
                }
			}
        },

        uaMatch(ua) {
            if (ua === undefined) {
                ua = window.navigator.userAgent;
            }
            ua = ua.toLowerCase();
            var match = /(edge)\/([\w.]+)/.exec(ua)
                    || /(opr)[\/]([\w.]+)/.exec(ua)
                    || /(chrome)[ \/]([\w.]+)/.exec(ua)
                    || /(iemobile)[\/]([\w.]+)/.exec(ua)
                    || /(version)(applewebkit)[ \/]([\w.]+).*(safari)[ \/]([\w.]+)/.exec(ua)
                    || /(webkit)[ \/]([\w.]+).*(version)[ \/]([\w.]+).*(safari)[ \/]([\w.]+)/.exec(ua) 
                    || /(webkit)[ \/]([\w.]+)/.exec(ua)
                    || /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua)
                    || /(msie) ([\w.]+)/.exec(ua)
                    || ua.indexOf("trident") >= 0 && /(rv)(?::| )([\w.]+)/.exec(ua)
                    || ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) 
                    || [];
            var platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua)
                    || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua)
                    || /(kindle)/.exec(ua) || /(silk)/.exec(ua)
                    || /(android)/.exec(ua) || /(win)/.exec(ua)
                    || /(mac)/.exec(ua) || /(linux)/.exec(ua)
                    || /(cros)/.exec(ua) || /(playbook)/.exec(ua)
                    || /(bb)/.exec(ua) || /(blackberry)/.exec(ua) 
                    || [];
            var browser = {}, matched = {
                browser : match[5] || match[3] || match[1] || "",
                version : match[2] || match[4] || "0",
                versionNumber : match[4] || match[2] || "0",
                platform : platform_match[0] || ""
            };
            if (matched.browser) {
                browser[matched.browser] = true;
                browser.version = matched.version;
                browser.versionNumber = parseInt(matched.versionNumber, 10);
            }
            if (matched.platform) {
                browser[matched.platform] = true;
            }
            if (browser.android || browser.bb || browser.blackberry
                    || browser.ipad || browser.iphone || browser.ipod
                    || browser.kindle || browser.playbook || browser.silk
                    || browser["windows phone"]) {
                browser.mobile = true;
            }
            if (browser.cros || browser.mac || browser.linux || browser.win) {
                browser.desktop = true;
            }
            if (browser.chrome || browser.opr || browser.safari) {
                browser.webkit = true;
            }
            if (browser.rv || browser.iemobile) {
                var ie = "msie";
                matched.browser = ie;
                browser[ie] = true;
            }
            if (browser.edge) {
                delete browser.edge;
                var msedge = "msedge";
                matched.browser = msedge;
                browser[msedge] = true;
            }
            if (browser.safari && browser.blackberry) {
                var blackberry = "blackberry";
                matched.browser = blackberry;
                browser[blackberry] = true;
            }
            if (browser.safari && browser.playbook) {
                var playbook = "playbook";
                matched.browser = playbook;
                browser[playbook] = true;
            }
            if (browser.bb) {
                var bb = "blackberry";
                matched.browser = bb;
                browser[bb] = true;
            }
            if (browser.opr) {
                var opera = "opera";
                matched.browser = opera;
                browser[opera] = true;
            }
            if (browser.safari && browser.android) {
                var android = "android";
                matched.browser = android;
                browser[android] = true;
            }
            if (browser.safari && browser.kindle) {
                var kindle = "kindle";
                matched.browser = kindle;
                browser[kindle] = true;
            }
            if (browser.safari && browser.silk) {
                var silk = "silk";
                matched.browser = silk;
                browser[silk] = true;
            }
            browser.name = matched.browser;
            browser.platform = matched.platform;
            return browser;
        },
        isMobile(ua){
            var ie= '' ,                      
                fireFox= '',              
                opera= '',                  
                webkit= '',  
                chrome= '',                
                ieCompatibilityMode= '',    
                osMac= '',                
                osWindows= '',          
                osLinux= '',            
                android= '',           
                ie64= '',                  
                iPhone= '',           
                iPad= '',                   
                nativeApp= '',         
                mobile= '',    
                g = /(?:MSIE.(\d+\.\d+))|(?:(?:Firefox|GranParadiso|Iceweasel).(\d+\.\d+))|(?:Opera(?:.+Version.|.)(\d+\.\d+))|(?:AppleWebKit.(\d+(?:\.\d+)?))|(?:Trident\/\d+\.\d+.*rv:(\d+\.\d+))/.exec(ua),
                h = /(Mac OS X)|(Windows)|(Linux)/.exec(ua);
                iPhone = /\b(iPhone|iP[ao]d)/.exec(ua);
                iPad = /\b(iP[ao]d)/.exec(ua);
                android = /Android/i.exec(ua);
                nativeApp = /FBAN\/\w+;/i.exec(ua);
                mobile = /Mobile/i.exec(ua);
                ie64 = !!/Win64/.exec(ua);
                if (g) {
                (ie = g[1] ? parseFloat(g[1]) : g[5] ? parseFloat(g[5]) : NaN) && document && document.documentMode && (ie = document.documentMode);
                var Trident = /(?:Trident\/(\d+.\d+))/.exec(ua);
                ieCompatibilityMode = Trident ? parseFloat(Trident[1]) + 4 : this.ie;
                fireFox = g[2] ? parseFloat(g[2]) : NaN;
                opera = g[3] ? parseFloat(g[3]) : NaN;
                chrome = (webkit = g[4] ? parseFloat(g[4]) : NaN) ? ((g = /(?:Chrome\/(\d+\.\d+))/.exec(ua)) && g[1] ? parseFloat(g[1]) : NaN) : NaN;
                } else ie = fireFox = opera = chrome = webkit = NaN;
                h ? ((this.osMac = h[1] ? ((ua = /(?:Mac OS X (\d+(?:[._]\d+)?))/.exec(ua)) ? parseFloat(ua[1].replace("_", ".")) : !0) : !1), (osWindows = !!h[2]), (osLinux = !!h[3])) : (osMac = osWindows = osLinux = !1);
            return {
                "iPhone" : iPhone == undefined ? false : true,
                "iPad" : iPad == undefined ? false : true,
                "android" : android == undefined ? false : true,
                "mobile" : mobile == undefined ? false : true,
            }
        },
    },
    computed: {
        platform(){
            return this.uaMatch(navigator.userAgent);
        },
        platform_mobile(){
            return this.isMobile(navigator.userAgent);
        },
        returnURL(){
            return `${window.location.protocol}//${window.location.host}/api/plugins/nice/nicepayments/approve/${this.$route.query.collection}`;
        },

        charSet(){
            if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1){
                return "euc-kr";
            }
            return "utf-8";
        }
    }
}
</script>

<style scoped>
#newDivLayer {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
}
#newPayIframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>