<template>
    <v-card tile :to="`${$route.path}/story/daily/${board._id}`" class="mini-daily-card">
        <v-img :src="board?.storyThumbSrc" :aspect-ratio="1 / 1">
            <template #placeholder>
                <v-overlay absolute>
                    <v-icon large> mdi-image-broken-variant </v-icon>
                </v-overlay>
            </template>
        </v-img>
        <v-card-title>{{ board.subject }}</v-card-title>
    </v-card>
</template>

<script>
import { initStoryBoard } from "@/store/story";

export default {
    props: {
        board: { type: Object, default: initStoryBoard },
    },
};
</script>

<style lang="scss" scoped>
.mini-daily-card {
    .v-card__title {
        font-size: var(--txt-font-size-sm);
        font-weight: 500;
        padding: 12px 0 0 0;
    }
}
</style>