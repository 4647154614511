var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.isPc ? [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "등록된 게시물이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.isNotice ? _c('v-icon', {
          staticClass: "icon icon-pin ml-4px"
        }) : _c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.index))])];
      }
    }, {
      key: `item.subject`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-truncate text-start",
          class: {
            'font-weight-black': item.isNotice
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    itemClass: _vm.itemClass
  }, false))] : _vm._e(), _vm.isMobile ? [_c('v-list', [_vm._l(_vm.items, function (item) {
    var _item$writer, _item$createdAt, _item$createdAt$toDat, _item$viewCount, _item$viewCount$forma, _item$likeCount, _item$likeCount$forma, _item$_comments;
    return [_c('v-list-item', {
      key: item._id,
      on: {
        "click": function ($event) {
          return _vm.showBoard(item);
        }
      }
    }, [item.storyThumbSrc ? _c('v-list-item-avatar', {
      attrs: {
        "tile": "",
        "size": "50",
        "color": "grey lighten-4"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.storyThumbSrc
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-icon', {
            attrs: {
              "color": "grey lighten-2"
            }
          }, [_vm._v("mdi-image-broken-variant")])];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "font-size-14"
    }, [_vm._v(" " + _vm._s(item.subject) + " ")]), _c('v-list-item-subtitle', {
      staticStyle: {
        "font-size": "11px"
      }
    }, [_c('v-row', {
      staticClass: "mx-n1 mt-1 mb-0"
    }, [_c('v-col', {
      staticStyle: {
        "padding": "5px"
      },
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$writer = item.writer) === null || _item$writer === void 0 ? void 0 : _item$writer.nickname) + " ")]), _c('v-col', {
      staticStyle: {
        "padding": "5px"
      },
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$createdAt = item.createdAt) === null || _item$createdAt === void 0 ? void 0 : (_item$createdAt$toDat = _item$createdAt.toDate) === null || _item$createdAt$toDat === void 0 ? void 0 : _item$createdAt$toDat.call(_item$createdAt)) + " ")]), _c('v-col', {
      staticStyle: {
        "padding": "5px"
      },
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v(" 조회 ")]), _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$viewCount = item.viewCount) === null || _item$viewCount === void 0 ? void 0 : (_item$viewCount$forma = _item$viewCount.format) === null || _item$viewCount$forma === void 0 ? void 0 : _item$viewCount$forma.call(_item$viewCount)) + " ")])]), _c('v-col', {
      staticStyle: {
        "padding": "5px"
      },
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v(" 추천 ")]), _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$likeCount = item.likeCount) === null || _item$likeCount === void 0 ? void 0 : (_item$likeCount$forma = _item$likeCount.format) === null || _item$likeCount$forma === void 0 ? void 0 : _item$likeCount$forma.call(_item$likeCount)) + " ")])])], 1)], 1)], 1), _c('v-list-item-action', {
      staticClass: "mt-3 mb-0 align-self-start"
    }, [_c('v-card', {
      staticClass: "d-flex justify-center align-center",
      attrs: {
        "outlined": "",
        "rounded": "xl",
        "height": "30",
        "width": "30"
      }
    }, [_c('span', {
      staticClass: "font-size-10"
    }, [_vm._v(" " + _vm._s(((item === null || item === void 0 ? void 0 : (_item$_comments = item._comments) === null || _item$_comments === void 0 ? void 0 : _item$_comments.length) || 0).format()) + " ")])])], 1)], 1), _c('v-divider', {
      key: `${item._id}-divider`
    })];
  })], 2)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }