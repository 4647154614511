var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "store-map-list"
  }, _vm._l(_vm.locations, function (location, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.toggleItem(location);
        }
      }
    }, [_c('v-row', {
      staticClass: "row--xxs my-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      attrs: {
        "size": "60"
      }
    }, [location.thumb ? _c('v-img', {
      attrs: {
        "src": location.thumb.src
      }
    }) : _c('v-img', {
      attrs: {
        "src": "/images/minishop/profile-default.png"
      }
    })], 1)], 1), _c('v-col', [_c('div', {
      staticClass: "txt txt--lg txt--dark font-weight-bold"
    }, [_vm._v(_vm._s(location === null || location === void 0 ? void 0 : location.nickname))]), _c('div', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(_vm._s(location === null || location === void 0 ? void 0 : location.address1) + " " + _vm._s(location === null || location === void 0 ? void 0 : location.address2))]), _c('div', {
      staticClass: "txt txt--sm"
    }, [_vm._v(_vm._s(location === null || location === void 0 ? void 0 : location.tel))])])], 1), _c('v-divider')], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }