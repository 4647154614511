var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    }
  }, [_c('tab-primary', [_vm._v("전체보기")]), _c('tab-primary', [_vm._v("전기자전거")]), _c('tab-primary', [_vm._v("전동스쿠터")]), _c('tab-primary', [_vm._v("전동킥보드")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }