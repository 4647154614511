<template>
    <v-row class="row--xxs">
        <v-col cols="auto" md="auto">
            <v-text-field v-model="query['searchValue']" v-bind="{ ...attrs_input, loading }" clearable dense placeholder="상품명을 입력해주세요." class="w-md-300px" @keydown.enter="setQuery"/>
        </v-col>
        <v-col cols="auto">
            <v-btn small v-bind="{ ...btn_primary }" @click="setQuery">검색</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input, btn_primary } from "@/assets/variables";

let initQuery = (query = {}) => ({
    ...query,
    ["searchValue"]: query?.["searchValue"] || null,
    ["sort"]: query?.["sort"] || "lastestDesc",
    ['category']: query?.['category'] || "electric-bicycle"
});
export default {
    data: () => ({
        attrs_input,
        btn_primary,
        loading: false,

        query: initQuery(),
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};
            delete query.page;

            if(!query?.['searchValue']) delete query['searchValue'];
            if(!query?.['sort']) query['sort'] = 'lastestDesc';

            this.$router.push({ query });
        }
    },
    watch: {
        "$route.query"() {
            this.sync();
        }
    }
}
</script>

<style>

</style>