<template>
    <v-sheet>
        <template v-if="isPc">
            <v-data-table v-bind="{ headers, items, itemClass }" dense hide-default-footer disable-sort disable-filtering disable-pagination @click:row="(any, { item }) => showBoard(item)" no-data-text="등록된 게시물이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.index`]="{ item }">
                    <v-icon v-if="item.isNotice" class="icon icon-pin ml-4px"></v-icon>
                    <div v-else data-idx="5" class="text-truncate">{{ item.index }}</div>
                </template>
                <template #[`item.subject`]="{ item }">
                    <div class="text-truncate text-start" :class="{ 'font-weight-black': item.isNotice }">{{ item.subject }}</div>
                </template>
            </v-data-table>
        </template>
        <template v-if="isMobile">
            <v-list>
                <template v-for="item in items">
                    <v-list-item @click="showBoard(item)" :key="item._id">
                        <v-list-item-avatar tile size="50" color="grey lighten-4" v-if="item.storyThumbSrc">
                            <v-img :src="item.storyThumbSrc">
                                <template #placeholder>
                                    <v-icon color="grey lighten-2">mdi-image-broken-variant</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-size-14"> {{ item.subject }} </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 11px">
                                <v-row class="mx-n1 mt-1 mb-0">
                                    <v-col cols="auto" style="padding: 5px"> {{ item?.writer?.nickname }} </v-col>
                                    <v-col cols="auto" style="padding: 5px"> {{ item?.createdAt?.toDate?.() }} </v-col>
                                    <v-col cols="auto" style="padding: 5px">
                                        <span class="mr-1"> 조회 </span>
                                        <span> {{ item?.viewCount?.format?.() }} </span>
                                    </v-col>
                                    <v-col cols="auto" style="padding: 5px">
                                        <span class="mr-1"> 추천 </span>
                                        <span> {{ item?.likeCount?.format?.() }} </span>
                                    </v-col>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mt-3 mb-0 align-self-start">
                            <v-card outlined rounded="xl" height="30" width="30" class="d-flex justify-center align-center">
                                <span class="font-size-10">
                                    {{ (item?._comments?.length || 0).format() }}
                                </span>
                            </v-card>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`${item._id}-divider`" />
                </template>
            </v-list>
        </template>
    </v-sheet>
</template>

<script>
import detect from "@/plugins/detect";

const headers = [
    { width: "10%", align: "center", text: "번호", value: "index" },
    {
        width: "10%",
        align: "center",
        text: "카테고리",
        value: "categoryText",
        class: "grey lighten-5",
        cellClass: "grey lighten-5",
    },
    { align: "center", text: "제목", value: "subject" },
    { width: "16%", align: "center", text: "작성자", value: "writer", formatter: (value) => value?.nickname || "-" },
    { width: "10%", align: "center", text: "등록일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "10%", align: "center", text: "조회수", value: "viewCount" },
];

export default {
    props: {
        items: { type: Array, default: () => [] }, // boards
    },
    setup: () => ({
        headers,
        detect,
    }),
    computed: {
        isPc() {
            return this.detect == "pc";
        },
        isMobile() {
            return this.detect == "mobile";
        },
    },
    methods: {
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
        itemClass({ isNotice }) {
            return isNotice ? "accent3" : "";
        },
    },
};
</script>
