<template>
    <story-page>
        <riding-list />
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";

import ridingList from '@/components/client/sub/story/riding-list.vue';

export default {
    components: {
        StoryPage,
        
        ridingList,
    },
}
</script>