<template>
    <client-page>
        <template #subHead>
            <mini-shop-visual v-bind="{ value: user, isMe }" @input="init" />
        </template>
        <page-section containerSize="container--lg" class="page-section--first page-section--last">
            <mini-shop-profile v-bind="{ value: user, isMe }" @input="init"></mini-shop-profile>
            <mini-shop-nav v-bind="{ value: user, isMe }" @input="init" />

            <slot v-bind="{ user, isMe }" />

        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initUser } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import MiniShopVisual from "@/components/client/miniShop/mini-shop-visual.vue";
import MiniShopProfile from "@/components/client/miniShop/mini-shop-profile.vue";
import MiniShopNav from "@/components/client/miniShop/mini-shop-nav.vue";
import { mapState } from "vuex";

export default {
    components: {
        ClientPage,
        PageSection,

        MiniShopVisual,
        MiniShopProfile,
        MiniShopNav,
    },
    props: {
        value: { type: Object, default: initUser },
    },
    data: () => ({
        user: null,

        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        // 블로그 주인
        _user() {
            return this.$route.params._user;
        },
        // 접속자
        isMe() {
            return this._user === this.$store.state.payload._user;
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { user } = await api.v1.users.get({ _id: this._user });
                if(!user) {
                    alert("존재하지 않는 회원입니다.");
                    this.$router.go(-1);
                } else {
                    this.user = user;
                    this.$emit("input", user);
                    // if (this.accessToken) await api.v1.me.visits.post({ _home: user._id });
                    await api.v1.me.visits.post({ _home: user._id });
                }
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _user() {
            this.init();
        }
    }
};
</script>