<template>
    <mini-shop-page v-model="user" v-slot="{ isMe }">
        <story-list-card v-if="user" componentName="div" v-bind="{ query, sort, skip, limit }" v-slot="{ items, summary, loading, search }">
            <v-sheet class="mb-16px mb-20px">
                <v-row align="center" class="row--xxs">
                    <v-col cols="auto" v-if="isMe">
                        <story-edit v-bind="{ selected, user }" @updated="search" />
                    </v-col>
                    <v-col cols="auto">
                        <story-sort />
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <common-filter hideSearchKeys />
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="tit-wrap tit-wrap--sm">
                <h3 class="tit tit--xxs">{{ title }}</h3>
            </div>
            <v-divider dark style="border-width: 0 0 2px" class="mb-20px mb-lg-40px" />
            <v-row>
                <template v-for="item in items">
                    <v-col cols="6" md="3" :key="item._id">
                        <gallery-item :value="item" hidesNickname :showsCheckbox="isMe" v-bind="{ selected }" @update:selected="(__selected) => (selected = __selected)" />
                    </v-col>
                </template>
                <template v-if="items.length < 1 && !loading">
                    <v-col>
                        <v-card-text class="text-center"> 등록된 게시글이 없습니다 </v-card-text>
                    </v-col>
                </template>
            </v-row>
            <div class="pagination-wrap">
                <v-pagination :value="page" :length="Math.ceil(summary?.totalCount / limit) || 1" :total-visible="11" color="primary" class="mt-4" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </story-list-card>
    </mini-shop-page>
</template>

<script>
import { mapGetters } from "vuex";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import CommonFilter from "@/components/client/story/main/common/common-filter.vue";
import GalleryItem from "@/components/client/story/main/gallery/gallery-item.vue";
import StoryListCard from "@/components/client/story/list/story-list-card.vue";

import StoryEdit from "@/components/client/miniShop/story/story-edit.vue";
import StorySort from "@/components/client/miniShop/story/story-sort.vue";

export default {
    components: {
        MiniShopPage,

        CommonFilter,
        GalleryItem,
        StoryListCard,

        StoryEdit,
        StorySort,
    },
    props: {
        path: { type: String, default: null },
    },
    data: () => ({
        selected: [],

        user: undefined,

        limit: 12,
    }),
    computed: {
        ...mapGetters("story", ["getCategory", "categories__flatMapped"]),
        code() {
            return (this.user?.mini?.storyCodes || []).find(({ path }) => path == this.path);
        },
        query() {
            let { ...query } = this.$route.query;
            const scope = this.$store.state.payload.scope || [];
            
            //권한이 없는 게시글 (비로그인 시, 권한 설정된 게시글 모두 비공개)
            let codes = this.categories__flatMapped.filter((category) => category?.scope__main?.length);
            codes = (scope.length ? codes.filter((category) => !scope?.some((el) => (category?.scope__main || []).includes(el))) : codes).map((category) => category?.code || "");
            
            //라이더, 사장님 이야기
            codes = [...codes, ...this.getCategory("rider")?.codes, ...this.getCategory("owner")?.codes];

            query = {
                ...query,
                code: JSON.stringify({ $nin: codes }),
                _writer: this.user?._id,
                ["meta.mini.code"]: this.code?.code,
            };
            if (!query["meta.mini.code"]) delete query["meta.mini.code"];

            query.searchKey = "subject";
            if (!query["searchValue"]) delete query["searchValue"];

            delete query.page;

            return query;
        },
        title() {
            if (this.code) return this.code.name;
            else return "일상 이야기";
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            let { sort } = this.$route.query;

            switch (sort) {
                case "like": {
                    sort = { likeCount: -1, createdAt: -1, _id: -1 };
                    break;
                }
                case "view": {
                    sort = { viewCount: -1, createdAt: -1, _id: -1 };
                    break;
                }
                default: {
                    sort = { createdAt: -1, _id: -1 };
                    break;
                }
            }

            return sort;
        },
    },
};
</script>