var render = function render(){
  var _vm$value, _vm$value$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "to": `${_vm.$route.path}/${_vm.value._id}`
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [!_vm.hidesNickname ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_vm.value.writer.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.value.writer.thumb.url
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v(_vm._s(((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$writer = _vm$value.writer) === null || _vm$value$writer === void 0 ? void 0 : _vm$value$writer.nickname) || ""))])], 1)]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v(_vm._s(_vm.value.viewCount))])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v(_vm._s(_vm.value.likeCount))])], 1)])], 1)], 1)], 1)], 1), _vm.value.storyThumbSrc ? _c('v-card', {
    attrs: {
      "img": _vm.value.storyThumbSrc,
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_vm.showsCheckbox ? [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$data.__selected,
      expression: "$data.__selected"
    }],
    staticClass: "ma-8px ma-lg-12px w-lg-20px h-lg-20px",
    attrs: {
      "multiple": "",
      "type": "checkbox"
    },
    domProps: {
      "value": _vm.value,
      "checked": Array.isArray(_vm.$data.__selected) ? _vm._i(_vm.$data.__selected, _vm.value) > -1 : _vm.$data.__selected
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      },
      "change": [function ($event) {
        var $$a = _vm.$data.__selected,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = _vm.value,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.$data, "__selected", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.$data, "__selected", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.$data, "__selected", $$c);
        }
      }, _vm.emit]
    }
  })] : _vm._e()], 2)], 1) : _c('v-card', {
    attrs: {
      "img": "/images/sub/story/storyDefault.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_vm.showsCheckbox ? [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$data.__selected,
      expression: "$data.__selected"
    }],
    staticClass: "ma-8px ma-lg-12px w-lg-20px h-lg-20px",
    attrs: {
      "multiple": "",
      "type": "checkbox"
    },
    domProps: {
      "value": _vm.value,
      "checked": Array.isArray(_vm.$data.__selected) ? _vm._i(_vm.$data.__selected, _vm.value) > -1 : _vm.$data.__selected
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      },
      "change": [function ($event) {
        var $$a = _vm.$data.__selected,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = _vm.value,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.$data, "__selected", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.$data, "__selected", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.$data, "__selected", $$c);
        }
      }, _vm.emit]
    }
  })] : _vm._e()], 2)], 1), _c('div', {
    staticClass: "txt txt--dark font-weight-medium text-truncate-2 mt-8px mt-lg-12px"
  }, [_vm.value.isNotice ? _c('v-icon', {
    staticClass: "icon icon-pin ml-4px"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.value.subject) + " ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }