var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', [_c('div', {
    staticClass: "btn-wrap d-flex justify-space-between"
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('service-date', {
    on: {
      "next": _vm.next,
      "input": _vm.emit
    }
  })], 1), _c('v-tab-item', [_c('service-input', _vm._b({
    on: {
      "prev": _vm.prev,
      "input": _vm.save
    }
  }, 'service-input', {
    value: _vm.form
  }, false))], 1)], 1)], 1), _c('nice-payments', _vm._g({
    ref: "nicePay"
  }, {
    complete: _vm.complete
  }))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }