var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    staticClass: "rider-story-slide swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('riders-story-card')], 1), _c('swiper-slide', [_c('riders-story-card')], 1), _c('swiper-slide', [_c('riders-story-card')], 1), _c('swiper-slide', [_c('riders-story-card')], 1), _c('swiper-slide', [_c('riders-story-card')], 1), _c('swiper-slide', [_c('riders-story-card')], 1), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }