<template>
    <div class="d-none d-lg-block">
        <div class="new-store-btn" @click.stop="newStoreDrawer = !newStoreDrawer">
            <v-img src="/images/sub/store/map/drawer-button.png" class="new-store-btn__bg"></v-img>
            <v-icon>mdi-chevron-left</v-icon>
        </div>

        <v-navigation-drawer v-model="newStoreDrawer" hide-overlay right class="new-store-drawer">
            <div class="new-store-btn new-store-btn--close" @click.stop="newStoreDrawer = !newStoreDrawer">
                <v-img src="/images/sub/store/map/drawer-button.png" class="new-store-btn__bg"></v-img>
                <v-icon>mdi-chevron-right</v-icon>
            </div>

            <v-sheet class="w-440px pa-32px h-100">
                <div class="tit-wrap">
                    <h3 class="tit tit--xs">이달의 NEW STORE</h3>
                </div>
                <ul class="store-map-list">
                    <li v-for="(store, index) in stores" :key="index" @click="toggleItem(store)">
                        <v-row class="row--xxs my-0">
                            <v-col cols="auto">
                                <v-avatar size="60">
                                    <v-img v-if="store.thumb" :src="store.thumb.src"></v-img>
                                    <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <!-- 업체명 -->
                                <div class="txt txt--lg txt--dark font-weight-bold">{{ store.nickname }}</div>
                                <!-- 주소 -->
                                <div class="txt txt--sm txt--dark">{{ store.address1 }} {{ store.address2 }}</div>
                                <!-- 전화번호 -->
                                <div class="txt txt--sm">{{ store?.tel }}</div>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </li>
                </ul>
            </v-sheet>
        </v-navigation-drawer>
    </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
export default {
    data: () => ({
        newStoreDrawer: true,
        stores: null,
    }),
    methods: {
        ...mapMutations("location", ["setCoordinates"]),
        init() {
            this.search();
        },
        async search() {
            try {
                let { stores } = await api.v1.shop.stores.gets({
                    headers: { skip: 0, limit: 6 },
                });

                this.stores = stores;
            } catch (error) {
                this.$handleError(error);
            }
        },
        toggleItem(store) {
            const { coordinates } = store?.geolocation;
            this.$router.push({ query: { ...this.$route.query, lat: coordinates[1], lng: coordinates[0], _location: store._id, isNew: true } });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.new-store-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    width: 16px;
    height: 88px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.new-store-drawer {
    width: auto !important;
    height: 100%;
    z-index: 999;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;
    .new-store-btn--close {
        left: 2px;
        transform: translate(-100%, -50%);
    }
    // ::v-deep() {
    //     .v-navigation-drawer__border {
    //         display: none;
    //     }
    // }
}
</style>