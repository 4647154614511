var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('calendar-head', _vm._g(_vm._b({}, 'calendar-head', {
    date: _vm.date
  }, false), {
    prev: _vm.prev,
    next: _vm.next
  })), _c('v-calendar', _vm._b({
    ref: "calendar",
    attrs: {
      "show-month-on-first": false,
      "color": "secondary"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "day-label",
      fn: function (props) {
        return [_c('calendar-item', _vm._b({
          on: {
            "input": _vm.emit,
            "holidayToggle": function ($event) {
              return _vm.$emit('holidayToggle');
            }
          },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        }, 'calendar-item', {
          props,
          loading: _vm.loading,
          holidays: _vm.holidays,
          eventsArray: _vm.eventsArray,
          excludedDates: _vm.excludedDates
        }, false))];
      }
    }]),
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }, 'v-calendar', {
    weekdayFormat: _vm.weekdayFormat,
    events: _vm.events
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }