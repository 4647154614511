var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("라이딩")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tabs', {
    attrs: {
      "hide-slider": ""
    }
  }, [_c('tab-tertiary', [_vm._v("최신순")]), _c('tab-tertiary', [_vm._v("인기순")])], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v("작성자")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)])], 1)], 1)], 1)], 1), _c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "txt txt--dark font-weight-medium text-truncate-2 mt-8px mt-lg-12px"
  }, [_vm._v("게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.")])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-40px mt-lg-60px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "w-100px",
    attrs: {
      "dense": ""
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "w-300px",
    attrs: {
      "dense": "",
      "placeholder": "검색어를 입력해주세요."
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("검색")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("글쓰기")])], 1)], 1)], 1), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }