<template>
    <story-page>
        <v-row>
            <v-col cols="12">
                <v-divider style="border-width: 2px 0 0" dark />
                <div class="py-20px">
                    <v-row class="row--xxs" justify="space-between" align="center">
                        <v-col cols="12" lg="auto">
                            <!-- 게시물 제목 -->
                            <h4 class="tit tit--xs">게시글 제목이 옵니다.</h4>
                        </v-col>
                        <!-- S: PC 게시물 정보 -->
                        <v-col cols="auto" class="d-none d-lg-block">
                            <div class="divider-group tit--xxs">
                                <ul>
                                    <!-- 날짜 -->
                                    <li>2024.02.02</li>
                                    <!-- 조회수 -->
                                    <li>조회수 13회</li>
                                    <!-- 좋아요 수 -->
                                    <li class="d-flex align-center"><v-icon small class="material-icons-outlined mr-4px">thumb_up</v-icon>75</li>
                                </ul>
                            </div>
                        </v-col>
                        <!-- E: PC 게시물 정보 -->

                        <!-- S: Mobile 작성자 닉네임 -->
                        <v-col cols="auto" class="d-lg-none">
                            <div class="d-flex align-center">
                                <v-avatar size="30">
                                    <v-img src="/images/test-img.jpg" />
                                </v-avatar>
                                <!-- 작성자 닉네임 -->
                                <div class="txt txt--sm ml-6px">작성자</div>
                            </div>
                        </v-col>
                        <!-- E: Mobile 작성자 닉네임 -->
                    </v-row>
                    <v-row class="row--xxs" align="center">
                        <!-- S: PC 작성자 닉네임 -->
                        <v-col cols="12" class="d-none d-lg-block">
                            <div class="d-flex align-center">
                                <v-avatar size="30">
                                    <v-img src="/images/test-img.jpg" />
                                </v-avatar>
                                <!-- 작성자 닉네임 -->
                                <div class="txt txt--sm ml-6px">작성자</div>
                            </div>
                        </v-col>
                        <!-- E: PC 작성자 닉네임 -->

                        <!-- S: Mobile 게시물 정보 -->
                        <v-col cols="auto" class="d-lg-none">
                            <div class="divider-group tit--xxs">
                                <ul>
                                    <!-- 날짜 -->
                                    <li>2024.02.02</li>
                                    <!-- 조회수 -->
                                    <li>조회수 13회</li>
                                    <!-- 좋아요 수 -->
                                    <li class="d-flex align-center"><v-icon small class="material-icons-outlined mr-4px">thumb_up</v-icon>75</li>
                                </ul>
                            </div>
                        </v-col>
                        <!-- E: Mobile 게시물 정보 -->
                    </v-row>
                </div>
                <v-divider />
                <div class="py-20px">
                    <v-row class="row--xxs">
                        <v-col cols="12">
                            <!-- 태그 -->
                            <v-chip-group>
                                <v-chip v-bind="{ ...chip_secondary }">#태그</v-chip>
                                <v-chip v-bind="{ ...chip_secondary }">#가</v-chip>
                                <v-chip v-bind="{ ...chip_secondary }">#옵니다</v-chip>
                            </v-chip-group>
                        </v-col>
                        <v-col cols="12">
                            <!-- 이미지 -->
                            <board-image-pin></board-image-pin>
                        </v-col>
                        <v-col cols="12">
                            <!-- 본문 -->
                            <div>글 내용이 옵니다.</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex justify-center align-center">
                                <!-- 좋아요 버튼 -->
                                <v-btn v-bind="{ ...btn_icon }">
                                    <v-icon class="material-icons-outlined">thumb_up</v-icon>
                                    <!-- <v-icon class="material-icons-outlined accent2--text">thumb_up</v-icon> -->
                                </v-btn>
                                <!-- 좋아요 수 -->
                                <span class="font-weight-medium txt txt--sm">75</span>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <!-- S: 수정, 삭제, 목록 버튼 -->
                <div class="btn-wrap">
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">삭제</v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }">목록</v-btn>
                        </v-col>
                    </v-row>
                </div>
                <!-- E: 수정, 삭제, 목록 버튼 -->
            </v-col>

            <!-- S: 이전글, 다음글 -->
            <v-col cols="12">
                <v-divider style="border-width: 2px 0 0" dark />
                <v-row class="row--sm ma-0">
                    <v-col cols="auto" class="font-weight-light">
                        <v-icon small>keyboard_arrow_up</v-icon>
                        이전글
                    </v-col>
                    <v-col cols="" class="text-truncate">
                        <router-link to="/">이전글 제목이 옵니다.</router-link>
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="row--sm ma-0">
                    <v-col cols="auto" class="font-weight-light">
                        <v-icon small>keyboard_arrow_down</v-icon>
                        다음글
                    </v-col>
                    <v-col class="text-truncate">
                        <router-link to="/">다음글 제목이 옵니다.</router-link>
                    </v-col>
                </v-row>
                <v-divider style="border-width: 2px 0 0" dark />
            </v-col>
            <!-- E: 이전글, 다음글 -->

            <!-- S: 추천상품 -->
            <v-col cols="12" class="d-none d-lg-block">
                <story-recommendations></story-recommendations>
            </v-col>
            <!-- E: 추천상품 -->

            <!-- S: 댓글 입력 -->
            <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <h3 class="tit tit--xxs">
                        댓글

                        <!-- 댓글 수 -->
                        <span>(5)</span>
                    </h3>
                </div>
                <v-row class="row--xxs" justify="end">
                    <v-col cols="12">
                        <v-textarea auto-grow filled outlined hide-details="auto" placeholder="댓글을 입력해 주세요." />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary }" class="v-btn--width-fixed">확인</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <!-- E: 댓글 입력 -->

            <!-- S: 댓글 리스트 -->
            <v-col cols="12">
                <!-- S: Mobile 댓글 -->
                <v-sheet class="d-lg-none pb-16px border-b">
                    <v-row class="row--xxs" justify="space-between" align="center">
                        <v-col>
                            <v-row class="row--xxs" align="center">
                                <!-- BEST 댓글 -->
                                <v-col cols="auto">
                                    <v-card tile color="secondary" height="26" class="d-flex align-center white--text px-10px font-weight-medium">BEST</v-card>
                                </v-col>
                                <!-- 댓글 닉네임 -->
                                <v-col cols="auto" class="font-weight-medium txt txt--dark">닉네임이 옵니다.</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <!-- 더보기 버튼 -->
                            <v-btn v-bind="{ ...btn_icon }" class="v-size--xx-small border">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="divider-group">
                        <ul>
                            <!-- 댓글 작성일 -->
                            <li class="txt txt--xs">2024.03.12</li>
                            <li>
                                <!-- 좋아요 버튼 -->
                                <v-btn v-bind="{ ...btn_txt }" color="grey">
                                    <v-icon class="material-icons-outlined">thumb_up</v-icon>
                                    <!-- <v-icon class="material-icons-outlined accent2--text">thumb_up</v-icon> -->

                                    <!-- 좋아요 수 -->
                                    <span class="font-weight-medium txt txt--sm ml-6px">75</span>
                                </v-btn>
                            </li>
                        </ul>
                    </div>
                    <v-row class="row--xxs">
                        <!-- 댓글 내용 -->
                        <v-col cols="12" class="txt"> 댓글 내용이 옵니다. </v-col>
                    </v-row>
                </v-sheet>
                <!-- E: Mobile 댓글 -->

                <!-- S: PC 댓글 -->
                <v-sheet class="d-none d-lg-block pb-20px border-b">
                    <v-row class="row--xxs" align="center">
                        <!-- BEST 댓글 -->
                        <v-col cols="auto">
                            <v-card tile color="secondary" height="26" class="d-flex align-center white--text px-10px font-weight-medium">BEST</v-card>
                        </v-col>
                        <!-- 댓글 닉네임 -->
                        <v-col cols="auto" class="font-weight-medium txt txt--dark">닉네임이 옵니다.</v-col>
                        <!-- 댓글 작성일 -->
                        <v-col cols="auto" class="txt txt--xs">2024.03.12</v-col>
                        <v-spacer />
                        <!-- 더보기 버튼 -->
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_txt }" class="d-none d-lg-block">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="row--xxs mb-0" justify="space-between" align="center">
                        <v-col class="txt">댓글 내용이 옵니다. 댓글 내용이 옵니다.</v-col>
                        <v-col cols="auto">
                            <!-- 좋아요 버튼 -->
                            <v-btn v-bind="{ ...btn_txt }" color="grey">
                                <v-icon class="material-icons-outlined">thumb_up</v-icon>
                                <!-- <v-icon class="material-icons-outlined accent2--text">thumb_up</v-icon> -->

                                <!-- 좋아요 수 -->
                                <span class="font-weight-medium txt txt--sm ml-6px">75</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
                <!-- E: PC 댓글 -->

                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-40px mt-lg-60px" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </v-col>
            <!-- E: 댓글 리스트 -->
        </v-row>
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";

import BoardImagePin from "@/components/client/board/board-image-pin.vue";
import StoryRecommendations from "@/components/client/sub/story/story-recommendations.vue";

import { attrs_input, btn_icon, btn_txt, btn_primary, btn_secondary, chip_secondary } from "@/assets/variables";

export default {
    components: {
        StoryPage,

        BoardImagePin,
        StoryRecommendations,
    },
    data: () => ({
        attrs_input,
        btn_icon,
        btn_txt,
        btn_primary,
        btn_secondary,
        chip_secondary,
    }),
};
</script>