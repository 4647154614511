<template>
    <story-page>
        <story-form v-model="board" v-bind="{ code, _board }" @updated="reroute" />
    </story-page>
</template>

<script>
import { initStoryBoard } from "@/store/story";

import StoryPage from "@/components/client/templates/story-page.vue";
import StoryForm from "@/components/client/story/form/story-form.vue";

export default {
    components: {
        StoryPage,
        StoryForm,
    },
    props: {
        code: { type: String },
        _board: { type: String },
    },
    data: () => ({
        board: initStoryBoard(),
    }),
    methods: {
        reroute() {
            this.$router.go(-1);
            this.$router.replace(`/story/${this.code}/${this.board._id}`);
        },
    },
};
</script>