var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('v-sheet', {
    staticClass: "pb-40px pb-lg-60px border-b border-dark"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("타보고서")]), _c('v-icon', {
    staticClass: "ml-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('slides-list')], 1), _c('v-sheet', {
    staticClass: "py-40px py-lg-60px border-b border-dark"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("가보고서")]), _c('v-icon', {
    staticClass: "ml-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('slides-list')], 1), _c('v-sheet', {
    staticClass: "pt-40px pt-lg-60px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("News")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/",
      "x-small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v(" 더보기 "), _c('v-icon', {
    staticClass: "ml-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_vm._l(4, function (n) {
    return [_c('v-col', {
      key: n,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('board-item')], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }