var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "to": _vm.to
    }
  }, [_c('v-img', {
    staticClass: "v-card__bg",
    attrs: {
      "src": _vm.bg
    }
  }), _c('v-card-title', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.tit))]), _c('v-card-actions', [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "white--text border-b"
  }, [_vm._v("바로가기")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }