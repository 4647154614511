var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "py-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("게시글 제목이 옵니다.")])]), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group tit--xxs"
  }, [_c('ul', [_c('li', [_vm._v("2024.02.02")]), _c('li', [_vm._v("조회수 13회")]), _c('li', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _vm._v("75")], 1)])])]), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "30"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--sm ml-6px"
  }, [_vm._v("작성자")])], 1)])], 1), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "30"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--sm ml-6px"
  }, [_vm._v("작성자")])], 1)]), _c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group tit--xxs"
  }, [_c('ul', [_c('li', [_vm._v("2024.02.02")]), _c('li', [_vm._v("조회수 13회")]), _c('li', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _vm._v("75")], 1)])])])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-chip-group', [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#태그")]), _c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#가")]), _c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#옵니다")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('board-image-pin')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("글 내용이 옵니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-btn', _vm._b({}, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', {
    staticClass: "material-icons-outlined"
  }, [_vm._v("thumb_up")])], 1), _c('span', {
    staticClass: "font-weight-medium txt txt--sm"
  }, [_vm._v("75")])], 1)])], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("목록")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('v-row', {
    staticClass: "row--sm ma-0"
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("keyboard_arrow_up")]), _vm._v(" 이전글 ")], 1), _c('v-col', {
    staticClass: "text-truncate",
    attrs: {
      "cols": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("이전글 제목이 옵니다.")])], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "row--sm ma-0"
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("keyboard_arrow_down")]), _vm._v(" 다음글 ")], 1), _c('v-col', {
    staticClass: "text-truncate"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("다음글 제목이 옵니다.")])], 1)], 1), _c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('story-recommendations')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(" 댓글 "), _c('span', [_vm._v("(5)")])])]), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "filled": "",
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "댓글을 입력해 주세요."
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed"
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "d-lg-none pb-16px border-b"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center white--text px-10px font-weight-medium",
    attrs: {
      "tile": "",
      "color": "secondary",
      "height": "26"
    }
  }, [_vm._v("BEST")])], 1), _c('v-col', {
    staticClass: "font-weight-medium txt txt--dark",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("닉네임이 옵니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small border"
  }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("more_horiz")])], 1)], 1)], 1), _c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', {
    staticClass: "txt txt--xs"
  }, [_vm._v("2024.03.12")]), _c('li', [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('v-icon', {
    staticClass: "material-icons-outlined"
  }, [_vm._v("thumb_up")]), _c('span', {
    staticClass: "font-weight-medium txt txt--sm ml-6px"
  }, [_vm._v("75")])], 1)], 1)])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    staticClass: "txt",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 댓글 내용이 옵니다. ")])], 1)], 1), _c('v-sheet', {
    staticClass: "d-none d-lg-block pb-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center white--text px-10px font-weight-medium",
    attrs: {
      "tile": "",
      "color": "secondary",
      "height": "26"
    }
  }, [_vm._v("BEST")])], 1), _c('v-col', {
    staticClass: "font-weight-medium txt txt--dark",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("닉네임이 옵니다.")]), _c('v-col', {
    staticClass: "txt txt--xs",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2024.03.12")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "d-none d-lg-block"
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('v-icon', [_vm._v("more_vert")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs mb-0",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "txt"
  }, [_vm._v("댓글 내용이 옵니다. 댓글 내용이 옵니다.")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('v-icon', {
    staticClass: "material-icons-outlined"
  }, [_vm._v("thumb_up")]), _c('span', {
    staticClass: "font-weight-medium txt txt--sm ml-6px"
  }, [_vm._v("75")])], 1)], 1)], 1)], 1), _c('v-pagination', {
    staticClass: "mt-40px mt-lg-60px",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }