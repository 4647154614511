var render = function render(){
  var _vm$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mini-daily-card",
    attrs: {
      "tile": "",
      "to": `${_vm.$route.path}/story/daily/${_vm.board._id}`
    }
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.storyThumbSrc,
      "aspect-ratio": 1 / 1
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "large": ""
          }
        }, [_vm._v(" mdi-image-broken-variant ")])], 1)];
      },
      proxy: true
    }])
  }), _c('v-card-title', [_vm._v(_vm._s(_vm.board.subject))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }