<template>
    <v-container>
        <div class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--xxs">신청서 작성</h2>
        </div>

        <v-form>
            <div class="txt txt--sm text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>

            <vertical-form-table v-model="form" v-bind="{ items }">
                <template #고객ID>
                    <v-text-field v-model="username" v-bind="{ ...attrs_input, loading }" disabled></v-text-field>
                </template>
                <template #예약시간>
                    <v-text-field v-model="reservationTime" v-bind="{ ...attrs_input, loading }" disabled></v-text-field>
                </template>
                <template #서비스>
                    <v-text-field v-model="service.name" v-bind="{ ...attrs_input, loading }" disabled></v-text-field>
                </template>
                <template #금액>
                    <v-text-field v-model="service.price" v-bind="{ ...attrs_input, loading }" disabled></v-text-field>
                </template>
            </vertical-form-table>
        </v-form>

        <div class="btn-wrap d-flex justify-space-between">
            <v-btn v-bind="{ ...btn_secondary, loading }" class="v-btn--width-fixed" @click="$emit('prev')">이전</v-btn>
            <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="$emit('input', {form, service})">다음</v-btn>
        </div>
    </v-container>
</template>

<script>
import api from "@/api";
import FormInput from "@/components/client/form/form-input.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";
import { btn_primary, btn_secondary, initReservation, attrs_input } from "@/assets/variables";
import { mapActions, mapState } from 'vuex';
const items = [
    {
        key: "information.date",
        term: "날짜",
        type: "text",
        disabled: true,
        ...attrs_input,
    },
    {
        term: "예약시간",
    },
    {
        term: "고객ID",
    },
    {
        key: "model",
        term: "모델",
        type: "text",
        required: true,
        ...attrs_input,
    },
    {
        term: "서비스",
    },
    {
        term: "금액",
    },
    {
        key: "content",
        term: "내용",
        type: "textarea",
        required: true,
        ...attrs_input,
    },
];
export default {
    components: {
        FormInput,
        VerticalFormTable,
        MiniShopPage,
    },
    props: {
        value: { type: Object, default: initReservation } //form
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        items,
        attrs_input,
        loading: false,

        service: {
            name: "",
            price: 0,
        },
        reservationTime: "",
        username: null,
        form: initReservation(),
    }),
    computed: {
        ...mapState(["accessToken"]),
        _service(){
            return this.$route.params._service;
        },
        _user(){
            return this.$store.state.payload._user;
        },
        _seller(){
            return this.$route.params._user;
        }
    },
    methods: {
        ...mapActions(["getUser"]),
        async init(){
            //유저 ID 추가
            await this.getUser();
            this.username= this.$store.state.user.username;

            //서비스 검색 및 포맷
            await this.search();

            //예약시간 포맷
            this.sync();
        },
        async search(){
            if(this.loading) return;
            else this.loading = true;
            try{
                let { service } = await api.v1.shop.services.get({ _id: this._service });
                this.service = service;
                this.service.price= this.formatCurrency(this.service.price);

            } catch(error){
                this.$handleError(error);
                this.$router.push("/");
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = initReservation({...this.value, _service: this._service, _seller: this._seller });
            this.reservationTime = this.formatReservationTime();
        },
        formatCurrency(amount){
            return amount.toLocaleString('ko-KR') + '원';
        },
        formatReservationTime(){
            return `${this.value?.information?.startsAt} ~ ${this.value?.information?.endsAt}`;
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        value(){
            this.sync();
        }
    }

};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    flex-wrap: wrap;
    .v-btn {
        width: 100%;
        border-radius: 0 !important;
        border-bottom-width: 0 !important;
        border-left-width: 1px !important;

        background-color: #fff !important;
        color: var(--v-primary-base);
        &:last-child {
            border-bottom-width: 1px !important;
        }
        &--active {
            background-color: var(--v-secondary-base) !important;
        }
    }

    .v-btn--disabled.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: var(--v-grey-lighten5) !important;
        color: var(--v-grey-base) !important;
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--border-color) !important;
}
</style>