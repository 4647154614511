var render = function render(){
  var _vm$value, _vm$value$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "to": `${_vm.path}/${_vm.value._id}`
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "xl": "3"
    }
  }, [_vm.value.storyThumbSrc ? _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "img": _vm.value.storyThumbSrc
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1) : _c('v-card', {
    attrs: {
      "img": "/images/sub/story/storyDefault.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_vm.value.writer.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.value.writer.thumb.url
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v(_vm._s(((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$writer = _vm$value.writer) === null || _vm$value$writer === void 0 ? void 0 : _vm$value$writer.nickname) || ""))])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit font-size-16 font-weight-medium text-truncate"
  }, [_vm.value.isNotice ? _c('v-icon', {
    staticClass: "icon icon-pin ml-4px"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.value.subject) + " ")], 1), _c('p', {
    staticClass: "txt txt--xs text-truncate"
  }, [_vm._v(_vm._s(_vm.value.contentText))])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v(_vm._s(_vm.value.viewCount))])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v(_vm._s(_vm.value.likeCount))])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }