<template>
    <v-sheet>
        <template v-if="isPc">
            <v-row class="row--xxs">
                <v-col cols="auto" v-if="!hideSearchKeys">
                    <v-select v-model="query['searchKey']" :items="searchKeys" v-bind="{ ...attrs_input, loading }" dense class="mw-100px"></v-select>
                </v-col>
                <v-col cols="auto">
                    <v-text-field v-model="query['searchValue']" v-bind="{ ...attrs_input, loading }" dense placeholder="검색어를 입력해주세요." class="w-md-300px" @keydown.enter="push" />
                </v-col>
                <v-col cols="auto">
                    <v-btn block small v-bind="{ ...btn_primary, loading }" @click="push">검색</v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-if="isMobile">
            <v-row class="ma-n1">
                <v-col class="pa-1" cols="auto">
                    <v-select v-model="query['searchKey']" :items="searchKeys" v-bind="{ ...attrs_input, loading }" dense class="mw-100px"></v-select>
                </v-col>
                <v-col class="pa-1">
                    <v-text-field v-model="query['searchValue']" v-bind="{ ...attrs_input, loading }" dense placeholder="검색어를 입력해주세요." class="w-md-300px" @keydown.enter="push" />
                </v-col>
                <v-col class="pa-1" cols="12">
                    <v-btn block small v-bind="{ ...btn_primary, loading }" @click="push">검색</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-sheet>
</template>

<script>
import detect from "@/plugins/detect";
import { attrs_input, btn_primary } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,
    ["searchKey"]: query["searchKey"] || null,
    ["searchValue"]: query["searchValue"] || null,
});

const searchKeys = [
    { text: "전체", value: null },
    { text: "제목", value: "subject" },
    { text: "내용", value: "content" },
];

export default {
    props: {
        value: { type: Boolean, default: false }, // showsSearch
        loading: { type: Boolean, default: false },
        hideSearchKeys: { type: Boolean, default: false },
    },
    setup: () => ({
        detect,
        searchKeys,

        attrs_input,
        btn_primary,
    }),
    data: () => ({
        query: initQuery(),
    }),
    computed: {
        isPc() {
            return this.detect == "pc";
        },
        isMobile() {
            return this.detect == "mobile";
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        push() {
            let { ...query } = this.query;

            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            delete query.page;

            this.$emit("input", false);
            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>