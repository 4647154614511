import { render, staticRenderFns } from "./slides-item.vue?vue&type=template&id=45c7424c&scoped=true&"
import script from "./slides-item.vue?vue&type=script&lang=js&"
export * from "./slides-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c7424c",
  null
  
)

export default component.exports