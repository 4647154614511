var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "pa-20px px-md-50px py-md-36px txt txt--dark",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-showcase.svg"
    }
  }), _c('div', [_vm._v("상품 태그 작성 가이드")])], 1)]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "dot"
  }, [_vm._v("●")])]), _c('v-col', [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs font-weight-bold"
  }, [_vm._v("상품태그")]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("사진에 붙은 [ "), _c('v-img', {
    staticClass: "d-inline-block vertical-align-middle",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-showcase.svg"
    }
  }), _vm._v(" ] 모양 버튼을 뜻합니다.")], 1), _c('p', [_vm._v("해당 태그를 통해 사진속 제품정보와 구매처를 확인할 수 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("1. 이미지 추가를 누른 뒤, 사진 내 태그를 등록할 위치를 클릭해 주세요.")]), _c('p', [_vm._v("2. 해당 상품의 브랜드와 상품명을 검색하고, 사진에 보이는 것과 동일한 상품을 찾아 [선택]을 눌러 주세요.")]), _c('p', [_vm._v("3. 태그가 정상적으로 등록되었다면 [본문에 삽입] 버튼을 눌러주세요.")])])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs mt-24px"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "dot"
  }, [_vm._v("●")])]), _c('v-col', [_c('p', [_vm._v("해당 태그를 통해 사진속 제품정보와 구매처를 확인할 수 있습니다.")])])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "dot"
  }, [_vm._v("●")])]), _c('v-col', [_c('p', [_vm._v("작성해주신 게시글은 내용에 따라 메인페이지에 노출 될 수 있습니다.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }