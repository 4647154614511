<template>
    <dialog-basic :value="showsDialog" @input="(shows) => (this.showsDialog = shows)">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">프로필 설정</v-card-title>
        </div>

        <v-divider dark style="border-width: 0 0 2px" />

        <div class="my-18px my-lg-24px">
            <v-row justify="center">
                <v-col cols="auto">
                    <div class="profile-avatar">
                        <v-avatar size="140">
                            <v-img v-if="src" :src="src"></v-img>
                            <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                        </v-avatar>
                        <v-btn elevation="4" icon class="v-size--xx-small white" @click="click">
                            <v-icon>edit</v-icon>
                        </v-btn>
                    </div>
                    <v-file-input v-model="form.thumb" ref="fileInput" class="d-none" @change="fileHandler" accept="image/*"></v-file-input>
                    <div class="tit tit--xxs text-center mt-14px mt-lg-16px">{{ value.nickname }}</div>
                </v-col>
            </v-row>
        </div>

        <v-form v-model="isValid">
            <v-simple-table class="v-data-table--no-hover">
                <tbody>
                    <tr>
                        <th>닉네임</th>
                        <td>
                            <v-text-field v-model="form.nickname" :rules="rules.nickname" v-bind="{ ...attrs_input }" dense placeholder="2~15자 이내여야 합니다."></v-text-field>
                        </td>
                    </tr>

                    <tr>
                        <th>태그</th>
                        <td>
                            <!-- <v-text-field v-bind="{ ...attrs_input }" dense placeholder="#태그 #붙여서 #입력해주세요"></v-text-field> -->
                            <v-combobox v-model="form.mini.tag" v-bind="attrs_input" multiple :dense="false" hideDetails append-icon="" placeholder="엔터를 눌러서 태그를 입력해주세요">
                                <template #selection="{ attrs, item, parent }">
                                    <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
                                </template>
                            </v-combobox>
                        </td>
                    </tr>

                    <tr>
                        <th>인사말</th>
                        <td>
                            <v-textarea v-model="form.mini.greeting" v-bind="{ ...attrs_input }"> </v-textarea>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>

            <v-card-actions class="btn-wrap px-0 pb-0">
                <v-row class="row--xxs" justify="end">
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="() => (showsDialog = false)">취소</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="!this.isValid" v-bind="{ ...btn_primary }" class="v-btn--width-fixed" @click="save">확인</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-form>
    </dialog-basic>
</template>

<script>
import api from "@/api";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';
import { attrs_input, btn_primary, btn_secondary, rules } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

export const initMini= (mini = {})=>({
    tag: mini?.tag || "",
    greeting: mini?.greeting || "",
    title: mini?.title || "",
    followCount: mini?.followCount,
    followPrivate: mini?.followPrivate || false,
})

const initProfile = (profile = {}) => ({
    _thumb: profile?._thumb || "",

    nickname: profile?.nickname || "",
    thumb: profile?.thumb || "",
    mini: initMini(profile?.mini),
});
export default {
    components: {
        DialogBasic,
    },
    props: {
        value: { type: Object, default: () => {} },
    },
    data: () => ({
        rules,
        showsDialog: false,
        isValid: true,

        attrs_input,
        btn_primary,
        btn_secondary,

        form: initProfile(),
    }),
    computed: {
        src() {
            return this.form.thumb instanceof File ? URL.createObjectURL(this.form.thumb) : this.form.thumb?.src;
        },
    },
    methods: {
        sync() {
            this.form = initProfile(this.value);
        },
        async save() {
            try {
                let { _thumb, thumb, ...form } = this.form;

                if(!this.validates(form)) return;
                let { user } = await api.v1.me.put(form);

                if (thumb instanceof File) {
                    thumb = (await api.v1.files.post({ path: "thumb" }, thumb))?.file;
                    user = (await api.v1.me.put({ _thumb: thumb._id }))?.user;
                }

                if (_thumb && _thumb !== thumb._id) await api.v1.files.delete({ _id: _thumb });

                this.$emit("input", user);

                this.showsDialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
        click() {
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
        fileHandler(file) {
            if (file instanceof File) this.form.thumb = file;
        },
        validates(form){
            const bannedKeywords = this.$store.state.setting.bannedKeywords;

            checkForBannedKeywords(form.mini.greeting, bannedKeywords);

            form.mini.tag.forEach(tag => {
                checkForBannedKeywords(tag, bannedKeywords);
            });

            return true;
        }
    },
    mounted() {
        this.sync();
    },
    watch: {
        showsDialog() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            text-align: center !important;
            border-right: 1px solid var(--border-color);
        }
    }
}

.profile-avatar {
    position: relative;
    .v-btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>