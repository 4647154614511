var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('div', [_c('vertical-form-table', _vm._b({
    staticClass: "vertical-form-table--border",
    attrs: {
      "topBorderMdNone": ""
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "py-12px py-md-20px"
  }, [_c('naver-smarteditor', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  })], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "py-12px d-md-none"
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium pb-4px"
  }, [_vm._v("첨부파일 추가")]), _c('div', {
    staticClass: "overflow-x-auto overflow-y-hidden mr-n12px pt-4px"
  }, [_c('div', {
    staticClass: "d-flex flex-nowrap pr-8px"
  }, [_c('div', {
    staticClass: "pr-4px"
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "width": "72",
      "height": "72px !important",
      "color": "grey lighten-5"
    }
  }, [_c('i', {
    staticClass: "icon icon-image"
  })])], 1), _c('div', {
    staticClass: "pr-4px"
  }, [_c('v-card', {
    staticClass: "attachment-img",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pr-4px"
  }, [_c('v-card', {
    staticClass: "attachment-img",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pr-4px"
  }, [_c('v-card', {
    staticClass: "attachment-img",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pr-12px"
  }, [_c('v-card', {
    staticClass: "attachment-img",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)])])]), _c('div', {
    staticClass: "btn-wrap mx-n12px mx-md-0px"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 w-md-140px"
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("이미지 추가")]), _c('div', {
    staticClass: "pt-16px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "attachment-img thumb",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "attachment-img",
    attrs: {
      "tile": ""
    }
  }, [_c('v-btn', {
    staticClass: "attachment-img__close",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "width": "72"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("저장")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("취소")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 d-md-none",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3, {
    loading: _vm.loading
  }), false), [_vm._v("취소")])], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }