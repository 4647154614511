<template>
    <v-tabs v-model="sort" model-value="" height="auto" hide-slider>
        <template v-for="item in items">
            <tab-tertiary :key="item.text" :value= "sort">
                {{ item.text }}
            </tab-tertiary>
        </template>
    </v-tabs>
</template>

<script>
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";

export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        sort: null,
    }),
    computed: {
        items() {
            return [
                { text: "등록순", value: { sort: "lastestDesc" } },
                { text: "추천순", value: { sort: "sequenceDesc" } },
            ]
        }
    },
    methods: {
        setSort() {
            let { ...query } = this.$route.query;
            delete query.page;

            this.$router.push({ query: { ...query, ...this.items[this.sort].value } });
        },
    },
    watch: {
        sort() {
            this.setSort();
        },
        "$route.query"(){
            this.$route.query.sort === "lastestDesc" ? this.sort = 0 : this.sort = 1;
        }
    },
};
</script>