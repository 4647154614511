var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._b({
    attrs: {
      "search-input": _vm.text
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.text = $event;
      },
      "update:search-input": function ($event) {
        _vm.text = $event;
      },
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        var _vm$text, _vm$text$trim;
        return [_c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !!((_vm$text = _vm.text) !== null && _vm$text !== void 0 && (_vm$text$trim = _vm$text.trim) !== null && _vm$text$trim !== void 0 && _vm$text$trim.call(_vm$text)),
            expression: "!!text?.trim?.()"
          }],
          attrs: {
            "text": "",
            "small": "",
            "color": "primary"
          },
          on: {
            "click": _vm.push
          }
        }, [_vm._v("추가")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.meta.tags,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "tags", $$v);
      },
      expression: "form.meta.tags"
    }
  }, 'v-combobox', _vm.attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }