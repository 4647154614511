<template>
    <v-sheet>
        <v-row>
            <template v-for="category in categories">
                <v-col cols="12" lg="6" :key="category._id">
                    <summary-boards v-bind="{ category }" />
                </v-col>
            </template>
        </v-row>

        <v-divider dark class="my-40px my-md-70px my-lg-90px" />
        <common-board v-bind="{ category: commonCategory }" />

        <v-divider dark class="my-40px my-md-70px my-lg-90px" />
        <summary-riders v-bind="{ category: ridersCategory }" />
    </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { btn_txt } from "@/assets/variables";
import { initStoryCategory } from "@/store/story";

import CommonBoard from "./common/common-board.vue";
import SummaryBoards from "./summary/summary-boards.vue";
import SummaryRiders from "./summary/summary-riders.vue";

export default {
    components: {
        CommonBoard,
        SummaryBoards,
        SummaryRiders,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
    },
    data: () => ({
        btn_txt,
    }),
    computed: {
        ...mapGetters("story", ["getCategory"]),
        categories() {
            return this.category?.children || [];
        },
        commonCategory() {
            return this.getCategory("article");
        },
        ridersCategory() {
            return this.getCategory("rider");
        },
    },
};
</script>

<style>
</style>