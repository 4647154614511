var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s(_vm.category.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": _vm.path
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _vm.boards.length < 1 && !_vm.loading ? [_c('span', [_vm._v(" 게시글이 없습니다 ")])] : _vm._e(), 0 < _vm.boards.length ? _c('v-row', {
    staticClass: "row--xxs"
  }, [_vm._l(_vm.boards, function (value) {
    return [_c('v-col', {
      key: value._id,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('board-item', _vm._b({}, 'board-item', {
      path: _vm.path,
      value
    }, false))], 1)];
  })], 2) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }