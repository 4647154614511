<template>
    <v-date-picker v-bind="{ dayFormat, allowedDates }" :events="events" :weekday-format="getWeekDay" v-model="picker" full-width no-title color="secondary" @click:date="emit">
        <slot/>
    </v-date-picker>
</template>

<script>
import dayjs from "dayjs";
export default {
    props:{
        value: { type: Object, default: ()=> ({}) },
        eventsArray: { type: Array, default: ()=> [] }
    },
    data: () => ({
        picker: null,
    }),
    computed: {
        excludedDates(){
            return this.value.excludedDates;
        },
        holidays(){
            return this.value.holidays;
        },
        events(){
            return Array.from(new Set(this.eventsArray.map(({ date }) => date)));
        },
    },
    methods: {
        emit(date){
            this.$emit('input', date)
        },
        dayFormat(value) {
            return +value.split('-')[2];
        },
        getWeekDay(date) {
            const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            let week = new Date(date).getDay(date);
            return daysOfWeek[week];
        },
        allowedDates(date) {
            // 금일 ~ 한 달간 지정 휴무일과 특정 휴무일 제외
            const isPast = this.$dayjs(date).isBefore(this.$dayjs().startOf("day"));
            const isWithinAMonth = this.$dayjs(date).isBefore(this.$dayjs().add(1, "month"));
            const isExcludedDate = this.excludedDates.includes(date);
            const isHoliday = this.holidays.includes(String(this.$dayjs(date).day()));
            return isWithinAMonth && !isPast && !isExcludedDate && !isHoliday;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep() {
    .v-date-picker {
        &-header {
            justify-content: center;
            &__value {
                flex: initial;
                font-size: var(--tit-font-size-xxs);
            }
            .v-btn {
                background-color: transparent !important;
            }
        }
    }
    .v-date-picker-table {
        height: auto !important;
        td {
            padding: 20px 0;
        }
    }
}
</style>