var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('common-title', _vm._b({}, 'common-title', {
    title: _vm.title
  }, false)), _c('default-category', _vm._b({}, 'default-category', {
    value: _vm.category.code,
    items: _vm.categoryItems
  }, false)), _c('default-list', _vm._b({}, 'default-list', {
    items: _vm.items
  }, false)), _c('common-actions', _vm._b({}, 'common-actions', {
    page: _vm.page,
    pageCount: _vm.pageCount,
    loading: _vm.loading,
    writable: _vm.writable
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }