<template>
    <div>
        <div class="tit-wrap tit-wrap--lg text-center">
            <h2 class="tit">나만의 협력사를 찾아보세요!</h2>
        </div>
        <v-sheet max-width="1040" class="mx-auto">
            <v-row class="row--sm">
                <v-col>
                    <store-category-card tit="전기자전거" to="/sub/store/bicycle" bg="/images/sub/store/bicycle/store-bicycle.png" />
                </v-col>
                <v-col>
                    <store-category-card tit="전동스쿠터" to="/sub/store/scooter" bg="/images/sub/store/scooter/store-scooter.png" />
                </v-col>
                <v-col>
                    <store-category-card tit="전동킥보드" to="/sub/store/kickboard" bg="/images/sub/store/kickboard/store-kickboard.png" />
                </v-col>
            </v-row>
        </v-sheet>
        <div class="btn-wrap btn-wrap--lg d-flex justify-center">
            <v-btn v-bind="{ ...btn_secondary }" x-large class="v-btn--width-fixed" to="/sub/store/map">
                <v-img src="/images/icon/icon-marker-pin.png" max-width="16" class="mr-4px" />
                내 근처 AS샵 보기
            </v-btn>
        </div>
    </div>
</template>

<script>
import { btn_secondary } from "@/assets/variables";
import StoreCategoryCard from "@/components/client/sub/store/store-category-card.vue";

export default {
    components: {
        StoreCategoryCard,
    },
    data: () => ({
        btn_secondary,
    }),
};
</script>
