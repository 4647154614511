var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "request-card",
    attrs: {
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.go();
      }
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-card-title', {
    staticClass: "tit--xs justify-center tit"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.name))]), _c('v-card-subtitle', {
    staticClass: "txt txt--dark text-center"
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.description))]), _c('v-card-text', {
    staticClass: "tit text-center"
  }, [_vm._v(_vm._s(_vm.value.price ? `${_vm.value.price.format()} 원` : "무료"))])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }