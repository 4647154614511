var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('story-view-body', _vm._b({
    attrs: {
      "isForm": ""
    }
  }, 'story-view-body', {
    board: _vm.board
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }