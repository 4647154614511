<template>
    <ul class="store-map-list">
        <li v-for="(location, index) in locations" :key="index" @click="toggleItem(location)">
            <v-row class="row--xxs my-0">
                <v-col cols="auto">
                    <v-avatar size="60">
                        <v-img v-if="location.thumb" :src="location.thumb.src"></v-img>
                        <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                    </v-avatar>
                </v-col>
                <v-col>
                    <!-- 업체명 -->
                    <div class="txt txt--lg txt--dark font-weight-bold">{{ location?.nickname }}</div>
                    <!-- 주소 -->
                    <div class="txt txt--sm txt--dark">{{ location?.address1 }} {{ location?.address2 }}</div>
                    <!-- 전화번호 -->
                    <div class="txt txt--sm">{{ location?.tel }}</div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </li>
    </ul>
</template>

<script>
import api from "@/api";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
    data: ()=> ({
        locations: [],
    }),
    computed: {
        ...mapState("location", ["bounds"]),
        ...mapGetters("location", ["geolocation"]),
        lat() {
            return this.$route.query.lat;
        },
        lng() {
            return this.$route.query.lng;
        },
        category() {
            return this.$route.query.category;
        },
        isNew() {
            return this.$route.query.isNew;
        },
        coordinates() {
            const { lat, lng } = this.$route.query;
            return `${lat}${lng}`;
        },
        // params(){
        //     let { ...query } = this.$route.query;
        //     delete query.isNew;

        //     return query;
        // }
    },
    methods: {
        ...mapMutations("location", ["setCoordinates"]),
        async search() {
            try{
                let { geolocation: near, bounds, category }= this;
                const headers = { };
                const params = { };
                if(!near || !bounds.w || !bounds.s || !bounds.e || !bounds.n){
                    return;
                }
                params.near = near;
                params.bounds = bounds;
                params.category = category;

                let { summary, stores } = await api.v1.shop.stores.gets({ headers, params });
                this.locations = stores;
                this.totalCount = summary.totalCount;
            } catch(error){
                this.$handleError(error);
            }
        },
        toggleItem(location) {
            const { coordinates } = location?.geolocation;

            let { ...query } = this.$route.query;
            delete query.isNew;
            query._location = location._id;

            this.$router.push({ query: { ...query, lat: coordinates[1], lng: coordinates[0] }});
        },
    },
    mounted() {
        this.$nextTick(()=>{this.search()});
    },
    watch:{
        coordinates(){
            if(this.isNew || !this.$route.query._location) this.$nextTick(()=>{this.search()});
        },
        category(){
            this.search();
        }
    }
}
</script>


<style lang="scss" scoped>
.store-map-list {
    min-height: 535px;
    max-height: 535px;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>