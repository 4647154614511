var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    attrs: {
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0"
  }, [_vm._v("카테고리 관리")])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-subtitle', {
    staticClass: "pa-0 txt txt--sm grey--text"
  }, [_vm._v("카테고리를 추가하고 순서를 변경할 수 있습니다.")])], 1), _c('v-btn', {
    staticClass: "add-button primary--text justify-start pa-4px mb-14px mb-lg-18px",
    attrs: {
      "block": "",
      "tile": "",
      "large": ""
    },
    on: {
      "click": _vm.push
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "tile": "",
      "width": "42"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-icon', {
    staticClass: "font-size-18 h-100"
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('div', [_vm._v("카테고리 추가")])], 1), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-list', {
    staticClass: "categories pa-0"
  }, [_c('draggable', {
    attrs: {
      "draggable": ".category-item",
      "handle": ".category-item-handle"
    },
    model: {
      value: _vm.storyCodes,
      callback: function ($$v) {
        _vm.storyCodes = $$v;
      },
      expression: "storyCodes"
    }
  }, [_vm._l(_vm.storyCodes, function (item, index) {
    return [_c('v-list-item', {
      key: item.code,
      staticClass: "category-item px-4px"
    }, [_c('v-row', {
      staticClass: "ma-n4px",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', _vm._b({
      staticClass: "category-item-handle",
      style: {
        cursor: 'grab'
      },
      attrs: {
        "small": "",
        "tile": ""
      }
    }, 'v-btn', Object.assign({}, _vm.btn_icon), false), [_c('v-icon', [_vm._v("sort")])], 1)], 1), _c('v-col', {
      staticClass: "px-1 py-3"
    }, [_c('v-text-field', _vm._b({
      attrs: {
        "label": "이름",
        "dense": "",
        "placeholder": "카테고리 추가 시 갤러리형으로 생성됩니다.",
        "persistent-placeholder": ""
      },
      model: {
        value: _vm.storyCodes[index].name,
        callback: function ($$v) {
          _vm.$set(_vm.storyCodes[index], "name", $$v);
        },
        expression: "storyCodes[index].name"
      }
    }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('mini-delete-category', {
      on: {
        "remove": function ($event) {
          return _vm.pull(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var attrs = _ref2.attrs,
            on = _ref2.on;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "small": ""
            }
          }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
            attrs
          }), false), on), [_vm._v("삭제")])];
        }
      }], null, true)
    })], 1)], 1)], 1)];
  }), _c('v-list-item', {
    staticClass: "grey lighten-5"
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v("일상 이야기")])]), _c('v-list-item', {
    staticClass: "grey lighten-5"
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v("라이더 이야기")])]), ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.type) === _vm.USER_TYPES.COMPANY.value ? _c('v-list-item', {
    staticClass: "grey lighten-5"
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v("사장님 이야기")])]) : _vm._e()], 2)], 1)], 1), _c('v-card-actions', {
    staticClass: "btn-wrap px-0 pb-0"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    disabled: !_vm.isValid
  }), false), [_vm._v("확인")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }