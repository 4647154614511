var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('story-view', _vm._b({
    model: {
      value: _vm.board,
      callback: function ($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  }, 'story-view', {
    code: _vm.code ? _vm.code : _vm.board.code || null,
    _board: _vm._board
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }