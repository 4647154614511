var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": `/story/${_vm.category.code}`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s(_vm.category.name))]), _c('v-icon', {
    staticClass: "ml-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), 0 < _vm.boards.length ? [_c('slides-list', _vm._b({}, 'slides-list', {
    boards: _vm.boards
  }, false))] : !_vm.loading ? [_c('span', [_vm._v("게시글이 없습니다")])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }