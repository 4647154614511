<template>
    <v-sheet>
        <common-title v-bind="{ title }">
            <template #left>
                <template v-if="1 < categoryItems.length">
                    <gallery-category :value="category.code" :items="categoryItems" />
                </template>
            </template>
            <template #right>
                <gallery-sort />
            </template>
        </common-title>

        <v-row class="row--sm">
            <template v-if="items.length < 1">
                <v-col>
                    <v-card-text class="text-center"> 등록된 게시글이 없습니다 </v-card-text>
                </v-col>
            </template>
            <template v-for="item in items">
                <v-col cols="6" lg="3" :key="item._id">
                    <gallery-item :value="item" />
                </v-col>
            </template>
        </v-row>

        <common-actions
            v-bind="{
                page,
                pageCount,

                loading,
                writable,
            }"
            style="margin: 80px -12px -80px"
        />
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapGetters, mapState } from "vuex";
import { initStoryCategory } from "@/store/story";
import { btn_primary } from "@/assets/variables";

import CommonFilter from "./common/common-filter.vue";
import CommonTitle from "./common/common-title.vue";

import GalleryCategory from "./gallery/gallery-category.vue";
import GalleryItem from "./gallery/gallery-item.vue";
import GallerySort from "./gallery/gallery-sort.vue";
import CommonActions from "./common/common-actions.vue";

export default {
    components: {
        CommonFilter,
        CommonTitle,

        GalleryCategory,
        GalleryItem,
        GallerySort,
        CommonActions,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
    },
    data: () => ({
        boards: [],

        limit: 12,
        summary: { totalCount: 0 },

        btn_primary,

        loading: false,
    }),
    computed: {
        ...mapState(["scope", "accessToken"]),
        ...mapGetters("story", ["getCategoryWithId"]),

        writable() {
            if (!this.accessToken) return false;
            if ((this.category?.scope__form?.length || 0) == 0) return true;
            else return this.category?.scope__form?.some?.((item) => (this.scope || []).includes(item));
        },
        items() {
            return [...this.boards];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            let { sort } = this.$route.query;

            switch (sort) {
                case "like": {
                    sort = { isNotice: -1, likeCount: -1, createdAt: -1, _id: -1 };
                    break;
                }
                case "view": {
                    sort = { isNotice: -1, viewCount: -1, createdAt: -1, _id: -1 };
                    break;
                }
                default: {
                    sort = { isNotice: -1, createdAt: -1, _id: -1 };
                    break;
                }
            }

            return JSON.stringify(sort);
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            delete query.sort;

            query.code = JSON.stringify({ $in: this.category.codes });

            return { ...query };
        },
        parentCategory() {
            return this.getCategoryWithId(this.category._parent);
        },
        categoryItems() {
            /** @type {object[]} */
            let items = [this.category];

            if (0 < this.category?.children?.length) {
                items = [...items, ...(this.category?.children || [])];
            } else {
                if (this.parentCategory) items = [this.parentCategory, ...this.parentCategory.children];
            }

            return items.reduce((items, item, index) => {
                if (item) {
                    items.push({
                        text: index == 0 ? "전체보기" : item.name,
                        value: item.code,
                    });
                }
                return items;
            }, []);
        },
        title() {
            switch (this.category?.children?.length < 1) {
                case true: {
                    if (this.parentCategory) {
                        return this.parentCategory.name;
                    }
                }
                default: {
                    return this.category.name;
                }
            }
        },
    },
    methods: {
        async init() {
            this.boards = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { sort, skip, limit, params } = this;
                var { summary, boards } = await api.v1.boards.gets({
                    headers: { sort, skip, limit },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        params() {
            this.search();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>