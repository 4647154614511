var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showGuide,
      expression: "showGuide"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('showcase-guide')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('showcase-head', _vm._g(_vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "guide-button",
      fn: function () {
        return [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 w-md-140px",
          on: {
            "click": _vm.toggleGuide
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("작성 가이드")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showcases,
      callback: function ($$v) {
        _vm.showcases = $$v;
      },
      expression: "showcases"
    }
  }, 'showcase-head', {
    tab: _vm.tab,
    board: _vm.board
  }, false), {
    setTab: _vm.setTab,
    pushOnContent: _vm.pushOnContent,
    toggleGuide: _vm.toggleGuide
  }))], 1)], 1), _c('v-tabs-items', {
    staticClass: "mt-6",
    attrs: {
      "value": _vm.tab
    }
  }, _vm._l(_vm.showcases, function (item, index) {
    return _c('v-tab-item', {
      key: item.tempId,
      attrs: {
        "value": item.tempId
      }
    }, [_c('showcase', _vm._g({
      ref: item.tempId,
      refInFor: true,
      attrs: {
        "editable": "",
        "editable_image": ""
      },
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.showcases[index],
        callback: function ($$v) {
          _vm.$set(_vm.showcases, index, $$v);
        },
        expression: "showcases[index]"
      }
    }, {
      pull: _vm.pull
    }))], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }