var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-list-card', _vm._b({
    staticClass: "story-list-card px-12px px-lg-36px py-20px fill-height",
    attrs: {
      "outlined": "",
      "tile": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var items = _ref.items,
          loading = _ref.loading;
        return [_c('v-card-text', {
          staticClass: "fill-height d-flex flex-column justify-center pa-0"
        }, [_c('v-data-table', _vm._b({
          staticClass: "v-data-table--no-hover",
          attrs: {
            "dense": "",
            "hide-default-footer": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          on: {
            "click:row": _vm.goStory
          },
          scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
            return header.hasOwnProperty('formatter');
          }), function (header, index) {
            return {
              key: `item.${header.value}`,
              fn: function (_ref2) {
                var value = _ref2.value,
                  item = _ref2.item;
                return [_c('span', {
                  key: index,
                  domProps: {
                    "innerHTML": _vm._s(header.formatter.bind(item)(value))
                  }
                })];
              }
            };
          }), {
            key: `header.createdAt`,
            fn: function () {
              return [_c('router-link', {
                attrs: {
                  "to": `/minishop/${_vm.query._writer}/story`
                }
              }, [_vm._v(" 더보기 ")])];
            },
            proxy: true
          }], null, true)
        }, 'v-data-table', {
          items,
          headers: _vm.headers,
          loading
        }, false))], 1)];
      }
    }])
  }, 'story-list-card', {
    query: _vm.query,
    limit: 5
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }