<template>
    <story-page>
        <div>
            <vertical-form-table v-bind="{ items }" topBorderMdNone class="vertical-form-table--border"></vertical-form-table>

            <div class="py-12px py-md-20px">
                <naver-smarteditor v-model="board" @input="$emit('input', board)" />
            </div>

            <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />

            <!-- S: 첨부파일 추가(모바일) -->
            <div class="py-12px d-md-none">
                <div class="txt txt--dark font-weight-medium pb-4px">첨부파일 추가</div>
                <div class="overflow-x-auto overflow-y-hidden mr-n12px pt-4px">
                    <div class="d-flex flex-nowrap pr-8px">
                        <div class="pr-4px">
                            <v-btn tile width="72" height="72px !important" color="grey lighten-5">
                                <i class="icon icon-image" />
                            </v-btn>
                        </div>
                        <!-- 반복 -->
                        <div class="pr-4px">
                            <v-card tile class="attachment-img">
                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                <v-img :aspect-ratio="1 / 1" width="72">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </div>
                        <!-- // 반복 -->
                        <div class="pr-4px">
                            <v-card tile class="attachment-img">
                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                <v-img :aspect-ratio="1 / 1" width="72">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </div>
                        <div class="pr-4px">
                            <v-card tile class="attachment-img">
                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                <v-img :aspect-ratio="1 / 1" width="72">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </div>
                        <div class="pr-12px">
                            <v-card tile class="attachment-img">
                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                <v-img :aspect-ratio="1 / 1" width="72">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </div>
                    </div>
                </div>
            </div>
            <!-- E: 첨부파일 추가(모바일) -->

            <div class="btn-wrap mx-n12px mx-md-0px">
                <v-row justify="space-between">
                    <!-- S: 이미지 추가(PC) -->
                    <v-col cols="6" md="" class="d-none d-md-block">
                        <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large w-100 w-md-140px">이미지 추가</v-btn>
                        <div class="pt-16px">
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-row align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <!-- 대표이미지 class="thumb" -->
                                            <v-card tile class="attachment-img thumb">
                                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                                <v-img :aspect-ratio="1 / 1" width="72">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-card tile class="attachment-img">
                                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">mdi-close</v-icon></v-btn>
                                                <v-img :aspect-ratio="1 / 1" width="72">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <!-- E: 첨부파일 추가(PC) -->
                    <v-col cols="12" md="auto">
                        <v-row class="row--xxs">
                            <v-col cols="6" md="auto">
                                <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="save">저장</v-btn>
                            </v-col>
                            <v-col cols="6" md="auto">
                                <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$router.go(-1)">취소</v-btn>
                                <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$router.go(-1)">취소</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
    </story-page>
</template>

<script>
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import StoryPage from "@/components/client/templates/story-page.vue";
import { btn_primary, btn_primary3, btn_secondary, attrs_input } from "@/assets/variables";

const items = [
    {
        term: "분류",
        type: "select",
        class: "w-md-358px",
    },
    {
        term: "작성자",
        type: "text",
        class: "w-md-358px",
    },
    {
        term: "제목",
        type: "text",
    },
    {
        term: "해시태그",
        type: "text",
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components: {
        VerticalFormTable,
        NaverSmarteditor,

        StoryPage,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            board: this.$props.value,
            items,
            loading: false,

            btn_primary,
            btn_primary3,
            btn_secondary,
            attrs_input,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
};
</script>