var render = function render(){
  var _vm$board, _vm$board$writer, _vm$board2, _vm$board2$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rider-story-card py-18px border-b border-dark",
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--sm my-0",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs cursor-pointer",
    attrs: {
      "align": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.go('minishop');
      }
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "62"
    }
  }, [(_vm$board = _vm.board) !== null && _vm$board !== void 0 && (_vm$board$writer = _vm$board.writer) !== null && _vm$board$writer !== void 0 && _vm$board$writer.thumb ? _c('img', {
    attrs: {
      "src": _vm.board.writer.thumb.url,
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": "/images/minishop/profile-default.png",
      "alt": ""
    }
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v(_vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$writer = _vm$board2.writer) === null || _vm$board2$writer === void 0 ? void 0 : _vm$board2$writer.nickname))])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "1",
      "order-lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--xs"
  }, [_vm._v(_vm._s(_vm.board.createdAt.toAgo()))])]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12"
    }
  }, [_c('div', [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('span', {
    staticClass: "txt txt--xs font-weight-medium primary--text"
  }, [_vm._v(_vm._s(_vm.board.likeCount))])], 1)])], 1)], 1), _c('v-col', {
    staticClass: "border-lg-l--dark border-lg-r--dark cursor-pointer",
    attrs: {
      "cols": "12",
      "lg": "8",
      "order-lg": "2"
    },
    on: {
      "click": function ($event) {
        return _vm.go('story');
      }
    }
  }, [_c('h4', {
    staticClass: "txt txt--dark font-weight-bold mb-4px mb-lg-6px text-truncate"
  }, [_vm._v(_vm._s(_vm.board.subject))]), _c('p', {
    staticClass: "txt txt--dark text-truncate"
  }, [_vm._v(_vm._s(_vm.board.contentText))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }