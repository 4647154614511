var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _vm$form, _vm$form$open, _vm$form2, _vm$form2$open;
        var user = _ref.user,
          isMe = _ref.isMe;
        return [_c('v-container', [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('h2', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("Schedule")])]), _c('v-row', {
          attrs: {
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "7"
          }
        }, [_c('schedule-calendar', _vm._g(_vm._b({
          on: {
            "input": _vm.change
          }
        }, 'schedule-calendar', {
          loading: _vm.loading,
          holidays: _vm.form.holidays,
          eventsArray: _vm.reservationEvents,
          excludedDates: _vm.form.excludedDates
        }, false), {
          holidayToggle: _vm.holidayToggle
        }))], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "auto"
          }
        }, [_c('v-divider', {
          staticClass: "d-lg-none"
        }), _c('v-divider', {
          staticClass: "d-none d-lg-block",
          attrs: {
            "vertical": ""
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "lg": ""
          }
        }, [_c('div', {
          staticClass: "mb-18px mb-lg-24px"
        }, [_vm._v(_vm._s(_vm.dayjs(_vm.date || _vm.dayjs()).format("MM월 DD일(ddd)")))]), _c('v-sheet', {
          staticClass: "select-time"
        }, [_vm._l(_vm.schedulesWithReservation, function (item, index) {
          return _c('v-btn', _vm._b({
            key: `${index}-schedule`,
            class: !!item.reservation ? 'reserved' : '',
            on: {
              "click": function ($event) {
                return _vm.showDialog(item);
              }
            }
          }, 'v-btn', {
            loading: _vm.loading
          }, false), [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.startsAt) + " ~ " + _vm._s(item === null || item === void 0 ? void 0 : item.endsAt) + " "), _vm.isReservationAwaiting(item) ? _c('span', {
            staticClass: "accent2--text"
          }, [_vm._v(" (예약대기)")]) : _vm._e()]);
        }), _c('mini-schedule-info', _vm._b({
          on: {
            "input": _vm.close
          }
        }, 'mini-schedule-info', {
          value: _vm.reservationDialog,
          seller: user,
          shows: _vm.shows
        }, false))], 2), _vm.form.holidays.length ? _c('div', {
          staticClass: "txt accent2--text mt-14px mt-lg-16px"
        }, [_vm._v("매주 " + _vm._s(_vm.holidays) + " 휴무 ")]) : _vm._e(), _c('div', {
          staticClass: "txt txt--sm line-height-2"
        }, [_c('div', [_vm._v("영업시간 : " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$open = _vm$form.open) === null || _vm$form$open === void 0 ? void 0 : _vm$form$open.startsAt) + " ~ " + _vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$open = _vm$form2.open) === null || _vm$form2$open === void 0 ? void 0 : _vm$form2$open.endsAt))]), _vm.form.breaks.length ? _c('div', [_vm._v("휴게시간 : " + _vm._s(_vm.breaks))]) : _vm._e()]), _c('div', {
          staticClass: "btn-wrap"
        }, [(user === null || user === void 0 ? void 0 : user.type) === _vm.USER_TYPES.COMPANY.value && isMe ? _c('mini-schedule-settings', _vm._b({
          on: {
            "input": _vm.searchSchedule
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', Object.assign({}, _vm.btn_secondary, {
                attrs
              }), false), on), [_vm._v("운영시간 설정")])];
            }
          }], null, true)
        }, 'mini-schedule-settings', {
          user,
          value: _vm.form
        }, false)) : _vm._e()], 1)], 1)], 1)], 1)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }