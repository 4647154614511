<template>
    <dialog-basic v-model="shows" persistent width="auto">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">게시글 이동</v-card-title>
        </div>
        <v-card-text class="txt txt-sm pa-0">게시글을 이동할 곳을 선택해 주세요.</v-card-text>

        <v-select v-model="code" v-bind="{ ...attrs_input, items: storyCodes }" item-text="name" item-value="code" placeholder="일상 이야기" clearable dense class="mt-12px mt-lg-16px" />

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row justify="end" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, disabled }" class="v-btn--width-fixed" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import { attrs_input, btn_icon, btn_primary, btn_secondary } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";
import api from "@/api";

export default {
    components: {
        DialogBasic,
    },
    props: {
        selected: { type: Array, default: () => [] },
        storyCodes: { type: Array, default: () => [] },
    },
    data: () => ({
        code: null,

        shows: false,
        loading: false,

        attrs_input,
        btn_icon,
        btn_primary,
        btn_secondary,
    }),
    computed: {
        disabled() {
            return this.storyCodes.length < 1 || this.selected.length < 1;
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            else this.loading = true;

            const { code } = this;

            for (const board of this.selected) {
                try {
                    await api.v1.boards.put({
                        _id: board._id,
                        meta: {
                            ...(board?.meta || {}),
                            mini: {
                                ...(board?.meta?.mini || {}),

                                code,
                            },
                        },
                    });
                } catch {}
            }

            alert("이동되었습니다");
            this.$emit("updated");

            this.loading = false;
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>