var render = function render(){
  var _vm$board, _vm$board2, _vm$board2$createdAt, _vm$board2$createdAt$, _vm$board3, _vm$board3$viewCount, _vm$board3$viewCount$, _vm$likeCount, _vm$likeCount$format, _vm$board$writer, _vm$board5, _vm$board5$writer, _vm$prevBoard, _vm$nextBoard;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "max-width": "1152"
    }
  }, [_vm.viewable ? [_c('div', {
    staticClass: "py-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.subject))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group tit--xxs"
  }, [_c('ul', [_c('li', [_vm._v(_vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$createdAt = _vm$board2.createdAt) === null || _vm$board2$createdAt === void 0 ? void 0 : (_vm$board2$createdAt$ = _vm$board2$createdAt.toDate) === null || _vm$board2$createdAt$ === void 0 ? void 0 : _vm$board2$createdAt$.call(_vm$board2$createdAt)))]), _c('li', [_vm._v("조회수 " + _vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : (_vm$board3$viewCount = _vm$board3.viewCount) === null || _vm$board3$viewCount === void 0 ? void 0 : (_vm$board3$viewCount$ = _vm$board3$viewCount.format) === null || _vm$board3$viewCount$ === void 0 ? void 0 : _vm$board3$viewCount$.call(_vm$board3$viewCount)) + "회")]), _c('li', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _vm._v(_vm._s((_vm$likeCount = _vm.likeCount) === null || _vm$likeCount === void 0 ? void 0 : (_vm$likeCount$format = _vm$likeCount.format) === null || _vm$likeCount$format === void 0 ? void 0 : _vm$likeCount$format.call(_vm$likeCount)))], 1)])])])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-inline-flex align-center cursor-pointer",
    on: {
      "click": function ($event) {
        var _vm$board4, _vm$board4$writer;
        return _vm.$router.push(`/minishop/${(_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : (_vm$board4$writer = _vm$board4.writer) === null || _vm$board4$writer === void 0 ? void 0 : _vm$board4$writer._id}`);
      }
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "30"
    }
  }, [(_vm$board$writer = _vm.board.writer) !== null && _vm$board$writer !== void 0 && _vm$board$writer.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.board.writer.thumb.url
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--sm ml-6px"
  }, [_vm._v(" " + _vm._s((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : (_vm$board5$writer = _vm$board5.writer) === null || _vm$board5$writer === void 0 ? void 0 : _vm$board5$writer.nickname) + " ")])], 1)])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-20px"
  }, [_c('story-view-body', _vm._b({
    scopedSlots: _vm._u([{
      key: "foot",
      fn: function () {
        var _vm$board6, _vm$likeCount2, _vm$likeCount2$format;
        return [_c('like-btn', {
          attrs: {
            "params": {
              _board: (_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6._id
            }
          },
          on: {
            "input": _vm.setLikeCoount
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var active = _ref.active,
                toggle = _ref.toggle;
              return [_c('v-btn', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": "",
                  "rounded": "",
                  "outlined": "",
                  "color": "transparent"
                },
                on: {
                  "!click": function ($event) {
                    $event.stopPropagation();
                    return toggle.apply(null, arguments);
                  }
                }
              }, [active ? _c('span', {
                staticClass: "material-icons-outlined accent2--text"
              }, [_vm._v("thumb_up")]) : _c('span', {
                staticClass: "material-icons-outlined primary--text"
              }, [_vm._v("thumb_up")])])];
            }
          }], null, false, 2463752190)
        }), _c('span', {
          staticClass: "font-weight-medium txt txt--sm"
        }, [_vm._v(_vm._s((_vm$likeCount2 = _vm.likeCount) === null || _vm$likeCount2 === void 0 ? void 0 : (_vm$likeCount2$format = _vm$likeCount2.format) === null || _vm$likeCount2$format === void 0 ? void 0 : _vm$likeCount2$format.call(_vm$likeCount2)))])];
      },
      proxy: true
    }], null, false, 4122859482)
  }, 'story-view-body', {
    board: _vm.board
  }, false))], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_vm.isUserWriter ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function ($event) {
        return _vm.$router.push(`${_vm.$route.path}/modify`);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("수정")])], 1) : _vm._e(), _vm.isUserWriter ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.remove
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("삭제")])], 1) : _vm._e(), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "exact": ""
    },
    on: {
      "click": _vm.goList
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("목록")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('v-row', {
    staticClass: "row--sm ma-0"
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("keyboard_arrow_up")]), _vm._v(" 이전글 ")], 1), _vm.prevBoard ? _c('v-col', {
    staticClass: "text-truncate"
  }, [_c('router-link', {
    attrs: {
      "to": _vm.showBoard(_vm.prevBoard)
    }
  }, [_vm._v(_vm._s((_vm$prevBoard = _vm.prevBoard) === null || _vm$prevBoard === void 0 ? void 0 : _vm$prevBoard.subject))])], 1) : _c('v-col', {
    staticClass: "text-truncate"
  }, [_vm._v(" 이전 게시물이 없습니다. ")])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "row--sm ma-0"
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("keyboard_arrow_down")]), _vm._v(" 다음글 ")], 1), _vm.nextBoard ? _c('v-col', {
    staticClass: "text-truncate"
  }, [_c('router-link', {
    attrs: {
      "to": _vm.showBoard(_vm.nextBoard)
    }
  }, [_vm._v(_vm._s((_vm$nextBoard = _vm.nextBoard) === null || _vm$nextBoard === void 0 ? void 0 : _vm$nextBoard.subject))])], 1) : _c('v-col', {
    staticClass: "text-truncate"
  }, [_vm._v(" 다음 게시물이 없습니다. ")])], 1), _c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMinishop && _vm.products.length,
      expression: "!isMinishop && products.length"
    }]
  }, [_c('story-recommendations', {
    on: {
      "load": _vm.syncProducts
    }
  })], 1)], 1), _c('comments', _vm._b({}, 'comments', {
    _board: _vm._board
  }, false))] : [_vm._v(" 권한이 없습니다. ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }