var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-file-btn', _vm._b({
    ref: "image-input",
    staticClass: "v-size--xx-large w-100 w-md-140px",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "input": _vm.push
    }
  }, 'v-file-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 이미지 추가 ")])], 1), _vm.$slots['guide-button'] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("guide-button")], 2) : _vm._e(), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('showcase-preview', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large w-100 w-md-140px"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v("미리보기")])];
      }
    }])
  }, 'showcase-preview', {
    board: _vm.board
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 w-md-140px",
    on: {
      "click": function ($event) {
        return _vm.$emit('pushOnContent');
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("본문에 삽입")])], 1), 0 < _vm.showcases.length ? [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('draggable', {
    staticClass: "row row--xxs",
    attrs: {
      "draggable": ".showcase-item"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.showcases,
      callback: function ($$v) {
        _vm.showcases = $$v;
      },
      expression: "showcases"
    }
  }, [_vm._l(_vm.showcases, function (showcase, i) {
    return [_c('v-col', {
      key: showcase.tempId,
      staticClass: "showcase-item",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "attachment-img",
      class: {
        thumb: i == 0,
        onTab: _vm.tab == showcase.tempId
      },
      attrs: {
        "tile": "",
        "ripple": false
      },
      on: {
        "click": function ($event) {
          return _vm.setTab(showcase);
        }
      }
    }, [_c('v-btn', {
      staticClass: "attachment-img__close",
      attrs: {
        "fab": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.pull(showcase);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": "",
        "color": "white"
      }
    }, [_vm._v(" mdi-close ")])], 1), _c('v-img', {
      staticClass: "attachment-img__image",
      attrs: {
        "aspect-ratio": 1 / 1,
        "width": "72",
        "src": _vm.getSrc(showcase.image)
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)];
  })], 2)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }