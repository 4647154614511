<template>
    <v-card outlined tile class="hot-best-card fill-height d-flex align-center px-12px px-lg-36px py-20px">
        <div class="w-100">
            <div class="tit-wrap tit-wrap--xs">
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                        <h3 class="txt txt--dark font-weight-bold">BEST</h3>
                    </v-col>
                </v-row>
            </div>

            <!-- <shop-product-items>
            <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="6" :rank="index + 1" />
        </shop-product-items> -->
            <best-list-card v-if="query?._seller" v-bind="{ 
                query: { _seller: query?._seller }, 
                limit: 2,
                }" v-slot="{ products }">
                <template>
                    <shop-product-items>
                        <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="6" :rank="index + 1" />
                    </shop-product-items>
                </template>
            </best-list-card>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import StoryListCard from "@/components/client/story/list/story-list-card.vue";
import BestListCard from "@/components/client/miniShop/list/best-list-card.vue";

export default {
    components: {
        StoryListCard,
        ShopProductItems,
        ShopProductItem,
        BestListCard,
    },
    props: {
        query: { type: Object, default: () => ({}) },
    },
    data: () => ({}),
    methods: {},
    mounted() {},
};
</script>


<style lang="scss" scoped>
.hot-best-card {
    border-top-width: 0;
}
@media (min-width: 1024px) {
    .hot-best-card {
        border-top-width: 1px;
        border-left-width: 0;
    }
}
</style>