var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _vm$category, _vm$category2;
        var user = _ref.user;
        return [_c('v-sheet', {
          staticClass: "mb-16px mb-20px"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "justify": "space-between",
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('category-sort')], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('category-search')], 1)], 1)], 1), _c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('h2', {
          staticClass: "tit tit--xxs"
        }, [_vm._v(_vm._s((_vm$category = _vm.category) === null || _vm$category === void 0 ? void 0 : _vm$category.name))])]), _c('div', {
          staticClass: "product-lists"
        }, [_c('div', {
          staticClass: "product-lists-body"
        }, [user !== null && user !== void 0 && user._id && _vm.category ? _c('category-list-card', _vm._b({
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var products = _ref2.products,
                summary = _ref2.summary;
              return [[_c('shop-product-items', _vm._l(products, function (product, index) {
                return _c('shop-product-item', {
                  key: index,
                  attrs: {
                    "value": product,
                    "sm": "6",
                    "md": "6",
                    "lg": "3"
                  }
                });
              }), 1)], _c('div', {
                staticClass: "product-lists__pagination"
              }, [_c('v-pagination', {
                attrs: {
                  "length": Math.ceil(summary.totalCount / _vm.limit) || 1,
                  "total-visible": 11
                },
                on: {
                  "input": function (page) {
                    _vm.$router.push({
                      query: Object.assign({}, _vm.$route.query, {
                        page
                      })
                    });
                  }
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v;
                  },
                  expression: "page"
                }
              })], 1)];
            }
          }], null, true)
        }, 'category-list-card', {
          _seller: user === null || user === void 0 ? void 0 : user._id,
          _category: (_vm$category2 = _vm.category) === null || _vm$category2 === void 0 ? void 0 : _vm$category2._id,
          skip: _vm.skip,
          limit: _vm.limit
        }, false)) : _vm._e()], 1)])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }