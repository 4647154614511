var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("사장님 이야기")])]), _c('story-board-tab'), _c('seller-list-table')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }