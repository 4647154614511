<template>
    <mini-shop-page>
        <div class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--xxs">Wishlist</h2>
        </div>

        <shop-product-items>
            <shop-product-item v-for="(product, index) in products" wishlist :key="index" :value="product" sm="6" md="6" lg="3" />
        </shop-product-items>
    </mini-shop-page>
</template>

<script>
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

export default {
    components: {
        MiniShopPage,

        ShopProductItems,
        ShopProductItem,
    },
    data: () => ({
        products: [],
    }),
};
</script>