var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "notice-slide-card",
    attrs: {
      "to": `/story/${_vm.board.code}/${_vm.board._id}`
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5",
      "order-lg": "2"
    }
  }, [_vm.board.storyThumbSrc ? _c('v-card', {
    attrs: {
      "img": _vm.board.storyThumbSrc,
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1) : _c('v-card', {
    attrs: {
      "img": "/images/sub/story/storyDefault.jpg",
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h4', {
    staticClass: "tit font-weight-regular text-truncate-2"
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('p', {
    staticClass: "txt text-truncate-3"
  }, [_vm._v(_vm._s(_vm.board.contentText))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }