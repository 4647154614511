<template>
    <dialog-basic>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <story-view-body isForm v-bind="{ board }" />
    </dialog-basic>
</template>

<script>
import { initStoryBoard } from "@/store/story";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

import StoryViewBody from "@/components/client/story/view/story-view-body.vue";

export default {
    components: {
        DialogBasic,

        StoryViewBody,
    },
    props: {
        board: { type: Object, default: initStoryBoard },
    },
};
</script>

<style>
</style>