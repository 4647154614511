<template>
    <div>
        <!-- S: 비공개 -->
        <v-sheet v-if="isPrivate" class="py-60px py-lg-120px d-flex justify-center align-center">
            <div>
                <div class="tit-wrap tit-wrap--sm">
                    <v-img max-width="40" src="/images/icon/icon-lock.svg" class="mx-auto" />
                </div>
                <div class="tit tit--lg font-weight-light">비공개 계정입니다.</div>
            </div>
        </v-sheet>
        <!-- E: 비공개 -->
        <!-- S: List -->
        <v-sheet v-else max-width="900" class="mx-auto">
            <v-row class="row--sm">
                <!-- v-for -->
                <template>
                    <v-col v-for="(item, index) in items" :key="index" cols="12" md="6">
                        <v-card :to="`/minishop/${item._id}`">
                            <v-row align="center" class="row--xxs">
                                <v-col cols="auto">
                                    <v-avatar>
                                        <v-img v-if="item?.thumb" :src="item?.thumb?.url"></v-img>
                                        <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col class="txt txt--dark font-weight-medium">
                                    {{ item?.nickname }}
                                </v-col>
                                <v-col cols="auto">
                                    <follow-btn v-if="item?._id !== _user" :params="{ _follower: item?._id, followings }" @input="emit">
                                        <template #activator="{ active, toggle, loading }">
                                            <v-btn v-if="!active" v-bind="{ ...btn_quaternary, loading }" small class="product-item__favorite v-btn--width-fixed" @click.stop.prevent="toggle">팔로우</v-btn>
                                            <v-btn v-else v-bind="{ ...btn_tertiary, loading }" small class="v-btn--width-fixed" @click.stop.prevent="toggle"> 팔로우 취소 </v-btn>
                                        </template>
                                    </follow-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </v-sheet>
        <!-- E: List -->
        <v-divider class="mt-30px" />
        <div class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-40px mt-lg-60px" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_tertiary, btn_quaternary } from "@/assets/variables";
import FollowBtn from "@/components/client/follow/follow-btn.vue";
export default {
    components: {
        FollowBtn,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        isMe: { type: Boolean, default: false },
    },
    data: () => ({
        btn_tertiary,
        btn_quaternary,

        loading: false,
        summary: {
            totalCount: 0,
        },
        limit: 12,

        follows: [],
        followings: [],
    }),
    computed: {
        items() {
            if (this.$route.query.type === "follower") {
                return [...this.follows.map((follow) => follow.follower)];
            }

            if (this.$route.query.type === "following") {
                return [...this.follows.map((follow) => follow.following)];
            }
            return [];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;
            return { ...query };
        },
        isPrivate() {
            return this.value?.mini?.followPrivate && !this.isMe;
        },
        _user() {
            return this.$store.state.payload._user;
        },
    },
    methods: {
        init() {
            if (!this.isPrivate && this.value?._id) {
                this.getFollowings();
                this.search();
            }
        },
        async search() {
            try {
                let { skip, limit, params } = this;
                params[this.params.type === "follower" ? "_following" : "_user"] = this.value?._id;

                //프로필 주인의 팔로우, 팔로잉 목록
                let { summary, follows } = await api.v1.me.follows.gets({
                    headers: { skip, limit },
                    params: this.params,
                });

                this.follows = follows;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async getFollowings() {
            try {
                //프로필 방문자의 팔로잉 목록
                if (this._user) {
                    let { follows: followings } = await api.v1.me.follows.gets({ params: { _user: this._user } });
                    this.followings = followings;
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
        emit() {
            this.init();
            this.$emit("input");
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query"() {
            this.search();
        },
        value() {
            this.init();
        },
    },
};
</script>

<style>
</style>