<template>
    <!-- <v-card class="mx-auto" :to="to" :class="{ 'active': isActive(to) }"> -->
    <v-card class="mx-auto" :to="to">
        <v-img :src="bg" class="v-card__bg" />
        <v-card-title class="tit">{{ tit }}</v-card-title>
        <v-card-actions>
            <v-btn v-bind="{ ...btn_txt }" small>
                <span class="white--text border-b">바로가기</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { btn_txt } from "@/assets/variables";

export default {
    props: {
        tit: { type: String, default: "" },
        bg: { type: String, default: "" },
        mobileBg: { type: String, default: "" },
        to: { String, default: "" },
    },
    data: () => ({
        btn_txt,
    }),
};
</script>

<style lang="scss" scoped>
.v-card {
    position: relative;
    margin-bottom: 56px;
    border-radius: 50% !important;
    &::after {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 50%;
    }
    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
    &__title {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        word-break: keep-all;
    }
    &__actions {
        display: none;
        .v-btn {
            .border-b {
                border-color: #fff !important;
            }
        }
    }
    &.router-link-active {
        .v-card__bg {
            border: 2px solid var(--v-primary-base);
        }
        .v-card__title {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 70%;
                background: var(--v-primary-base);
                z-index: -1;
                border-radius: 999px;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-card.router-link-active {
        .v-card__title {
            &::before {
                height: 80%;
            }
        }
    }
}
@media (min-width: 1200px) {
    .v-card {
        width: 100%;
        max-width: 320px;
        margin-bottom: 0;
        &__bg::after,
        &__title,
        &__actions {
            transition: all ease-out 0.2s;
        }
        &__bg {
            border-radius: 50%;
        }
        &__title {
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            opacity: 0;
        }
        &__actions {
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;
            opacity: 0;
        }
        &.router-link-active,
        &:hover {
            .v-card__bg {
                border: none;
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
            .v-card__title {
                opacity: 1;
                &::before {
                    display: none;
                }
            }
        }
        &:hover {
            .v-card__actions {
                opacity: 1;
            }
        }
    }
}
</style>
