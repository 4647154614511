var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "newDivLayer",
    attrs: {
      "id": "newDivLayer"
    }
  }, [_c('iframe', {
    ref: "newPayIframe",
    attrs: {
      "id": "newPayIframe",
      "name": "newPayIframe",
      "marginwidth": "0",
      "marginheight": "0",
      "frameborder": "no",
      "scrolling": "no",
      "allowtransparency": "true"
    }
  }), _c('form', {
    ref: "newPayForm",
    attrs: {
      "name": "newPayForm"
    }
  }, [_c('input', {
    attrs: {
      "name": "NPjsVersion",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.NPjsVersion
    }
  }), _c('input', {
    attrs: {
      "name": "NPappType",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.NPappType
    }
  }), _c('input', {
    attrs: {
      "name": "NPbrowser",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.NPbrowser
    }
  }), _c('input', {
    attrs: {
      "name": "NPos",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.NPos
    }
  }), _c('input', {
    attrs: {
      "name": "NPsvcType",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.NPsvcType
    }
  }), _c('input', {
    attrs: {
      "name": "clientId",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.clientId
    }
  }), _c('input', {
    attrs: {
      "name": "orderId",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.orderId
    }
  }), _c('input', {
    attrs: {
      "name": "goodsName",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.goodsName
    }
  }), _c('input', {
    attrs: {
      "name": "amount",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.amount
    }
  }), _c('input', {
    attrs: {
      "name": "method",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.method
    }
  }), _c('input', {
    attrs: {
      "name": "vbankHolder",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.vbankHolder
    }
  }), _c('input', {
    attrs: {
      "name": "returnUrl",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }