<template>
    <v-sheet style="position: relative">
        <v-btn-toggle v-model="date" borderless rounded class="day-btn ma-3" @change="emit">
            <v-btn
                v-bind="{
                    value: props.date,

                    loading,
                    disabled: disabled && !isSeller,
                    class: { disabled },

                    color: 'transparent',
                    'active-class': 'secondary',
                }"
            >
                {{ props.day }}
            </v-btn>
        </v-btn-toggle>

        <v-fade-transition>
            <v-card outlined tile class="holiday-btn">
                <v-btn v-show="holidayToggleShows" small block tile color="white" v-bind="{ loading }" @click="$emit('holidayToggle')">
                    <span> {{ holidayToggleTitle }} </span>
                </v-btn>
            </v-card>
        </v-fade-transition>
    </v-sheet>
</template>

<script>
import dayjs from "@/plugins/vue-dayjs";
export default {
    props: {
        value: { type: String, default: Date.now().toDate() },

        props: {
            type: Object,
            default: () => ({
                date: Date.now().toDate(),
                day: dayjs().format("D"),
            }),
        },

        loading: { type: Boolean, default: false },

        holidays: { type: Array, default: () => [] },
        eventsArray: { type: Array, default: () => [] },
        excludedDates: { type: Array, default: () => [] },
    },
    data: () => ({
        date: Date.now().toDate(),
    }),
    computed: {
        isSeller() {
            return this.$route.params._user == this.$store.state?.payload?._user;
        },
        disabled() {
            // 금일 ~ 한 달간 지정 휴무일과 특정 휴무일 제외
            const isPast = this.$dayjs(this.props.date).isBefore(this.$dayjs().startOf("day"));
            const isWithinAMonth = this.$dayjs(this.props.date).isBefore(this.$dayjs().add(1, "month"));
            const isExcludedDate = this.excludedDates.includes(this.props.date);
            const isHoliday = this.holidays.includes(String(this.$dayjs(this.props.date).day()));
            const isNotThisMonth = !this.$dayjs(this.props.date).isSame(this.date, "month");

            return !isWithinAMonth || isPast || isExcludedDate || isHoliday || isNotThisMonth;
        },
        holidayToggleShows() {
            return this.value == this.props.date && this.isSeller;
        },
        holidayToggleTitle() {
            return `휴무 ${this.excludedDates.includes(this.props.date) ? "취소" : "등록"}`;
        },
    },
    methods: {
        sync() {
            this.date = this.value;
        },
        emit() {
            this.$emit("input", this.date);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
.day-btn {
    &,
    > .v-btn {
        width: 44px !important;
        min-width: 44px !important;
        height: 44px !important;
        padding: 0;

        &.disabled {
            color: var(--v-grey-lighten3) !important;
        }
    }

    > .v-btn--disabled.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: var(--v-grey-lighten3) !important;
        background-color: transparent !important;
    }
}
.holiday-btn {
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: translateY(50%);

    width: max-content !important;
    min-width: max-content !important;
    z-index: 2;
}

@media (max-width: 560px) {
    .day-btn {
        &,
        > .v-btn {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
        }
    }
}

@media (max-width: 400px) {
    .day-btn {
        &,
        > .v-btn {
            width: 28px !important;
            min-width: 28px !important;
            height: 28px !important;
        }
    }
}

@media (max-width: 320px) {
    .day-btn {
        &,
        > .v-btn {
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
        }
    }
}
</style>