var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    attrs: {
      "value": _vm.showsDialog
    },
    on: {
      "input": function (shows) {
        return _this.showsDialog = shows;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0"
  }, [_vm._v("스킨 설정")])], 1), _c('v-simple-table', [_c('tbody', [_c('tr', [_c('th', [_vm._v("배경 이미지")]), _c('td', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.src ? _c('v-img', {
    attrs: {
      "width": "100",
      "src": _vm.src
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1) : _c('v-card', {
    attrs: {
      "width": "100",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('v-responsive', {
    staticClass: "text-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('span', {
    staticClass: "txt txt--xs line-height-18"
  }, [_vm._v(" 이미지"), _c('br'), _vm._v(" 업로드 ")])])], 1)], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--xxs h-100",
    attrs: {
      "align-content": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": ""
    },
    on: {
      "click": _vm.click
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("파일선택")]), _c('v-file-input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "change": _vm.fileHandler
    },
    model: {
      value: _vm.form.banner,
      callback: function ($$v) {
        _vm.$set(_vm.form, "banner", $$v);
      },
      expression: "form.banner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('div', {
    staticClass: "mb-4px"
  }, [_vm._v("JPG, PNG")]), _c('div', [_vm._v("권장사이즈 : 가로1920px 세로400px 또는 가로2280px 세로475px")])])])], 1)], 1)], 1)], 1)]), _c('tr', [_c('th', [_vm._v("타이틀")]), _c('td', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.mini.title,
      callback: function ($$v) {
        _vm.$set(_vm.form.mini, "title", $$v);
      },
      expression: "form.mini.title"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)])])]), _c('v-card-actions', {
    staticClass: "btn-wrap px-0 pb-0"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function () {
        return _vm.showsDialog = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }