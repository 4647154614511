<template>
    <v-sheet>
        <div class="tit-wrap tit-wrap--lg">
            <v-btn v-bind="{ ...btn_txt }" :to="`/story/${category.code}`">
                <h2 class="tit tit--xxs">{{ category.name }}</h2>
                <v-icon class="ml-4px">mdi-chevron-right</v-icon>
            </v-btn>
        </div>
        <template v-if="0 < boards.length">
            <slides-list v-bind="{ boards }" />
        </template>
        <template v-else-if="!loading">
            <span>게시글이 없습니다</span>
        </template>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { btn_txt } from "@/assets/variables";
import { initStoryCategory } from "@/store/story";

import SlidesList from "./slides/slides-list.vue";

export default {
    components: {
        SlidesList,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
        limit: { type: [String, Number], default: 4 },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        btn_txt,

        loading: false,
    }),
    computed: {
        sort() {
            return JSON.stringify({ isNotice: -1, createdAt: -1, _id: -1 });
        }
    },
    methods: {
        async search() {
            if (!this.category.code) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                const { boards, summary } = await api.v1.boards.gets({
                    headers: { limit: this.limit, sort: this.sort },
                    params: { code: JSON.stringify({ $in : [...this.category.codes] }) },
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        category() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>