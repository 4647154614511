var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("신청서 작성")])]), _c('v-form', [_c('div', {
    staticClass: "txt txt--sm text-right pb-16px pb-md-20px"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    scopedSlots: _vm._u([{
      key: "고객ID",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "disabled": ""
          },
          model: {
            value: _vm.username,
            callback: function ($$v) {
              _vm.username = $$v;
            },
            expression: "username"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }, {
      key: "예약시간",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "disabled": ""
          },
          model: {
            value: _vm.reservationTime,
            callback: function ($$v) {
              _vm.reservationTime = $$v;
            },
            expression: "reservationTime"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }, {
      key: "서비스",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "disabled": ""
          },
          model: {
            value: _vm.service.name,
            callback: function ($$v) {
              _vm.$set(_vm.service, "name", $$v);
            },
            expression: "service.name"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }, {
      key: "금액",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "disabled": ""
          },
          model: {
            value: _vm.service.price,
            callback: function ($$v) {
              _vm.$set(_vm.service, "price", $$v);
            },
            expression: "service.price"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1), _c('div', {
    staticClass: "btn-wrap d-flex justify-space-between"
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function ($event) {
        return _vm.$emit('prev');
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("이전")]), _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function ($event) {
        return _vm.$emit('input', {
          form: _vm.form,
          service: _vm.service
        });
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("다음")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }