<template>
    <v-sheet>
        <template v-if="isPc">
            <v-sheet class="mt-40px mt-lg-60px">
                <v-row justify="space-between" class="row--xxs">
                    <v-col cols="auto">
                        <common-filter />
                    </v-col>
                    <v-col cols="auto" v-if="writable">
                        <v-btn v-bind="{ ...btn_primary, loading }" small class="v-btn--width-fixed" @click="$router.push(`${$route.path}/write`)">글쓰기</v-btn>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </template>
        <template v-if="isMobile">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mb-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-row no-gutters>
                <v-col>
                    <v-bottom-sheet v-model="showsSearch" fullscreen>
                        <template #activator="{ attrs, on }">
                            <v-btn large tile block color="black" v-bind="attrs" v-on="on">
                                <span class="white--text font-weight-light font-size-14"> 검색 </span>
                            </v-btn>
                        </template>
                        <v-card rounded="0">
                            <v-card-title>
                                <v-icon @click="showsSearch = false"> mdi-chevron-left </v-icon>
                                <span> 검색하기 </span>
                            </v-card-title>
                            <v-card-text>
                                <common-filter v-model="showsSearch" />
                            </v-card-text>
                        </v-card>
                    </v-bottom-sheet>
                </v-col>
                <v-col v-if="writable" cols="auto"> <v-divider vertical /> </v-col>
                <v-col v-if="writable">
                    <v-btn large tile block color="black" @click="$router.push(`${$route.path}/write`)">
                        <span class="white--text font-weight-light font-size-14"> 글쓰기 </span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-sheet>
</template>

<script>
import detect from "@/plugins/detect";
import { btn_primary } from "@/assets/variables";

import CommonFilter from "./common-filter.vue";

export default {
    components: {
        CommonFilter,
    },
    props: {
        page: { type: Number, default: 0 },
        pageCount: { type: Number, default: 0 },

        loading: { type: Boolean, default: false },
        writable: { type: Boolean, default: false },
    },
    setup: () => ({
        detect,
        btn_primary,
    }),
    data: () => ({
        showsSearch: false,
    }),
    computed: {
        isPc() {
            return this.detect == "pc";
        },
        isMobile() {
            return this.detect == "mobile";
        },
    },
    methods: {
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
        itemClass({ isNotice }) {
            return isNotice ? "accent3" : "";
        },
    },
};
</script>
