var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var isMe = _ref.isMe;
        return [_vm.user ? _c('story-list-card', _vm._b({
          attrs: {
            "componentName": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var items = _ref2.items,
                summary = _ref2.summary,
                loading = _ref2.loading;
              return [_c('v-sheet', {
                staticClass: "mb-16px mb-20px"
              }, [_c('v-row', {
                attrs: {
                  "no-gutters": "",
                  "justify": "space-between",
                  "align": "center"
                }
              }, [_c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('story-sort')], 1), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('common-filter', {
                attrs: {
                  "hideSearchKeys": ""
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "tit-wrap tit-wrap--sm"
              }, [_c('v-row', {
                staticClass: "row--xxs",
                attrs: {
                  "align": "center"
                }
              }, [_c('v-col', {
                staticClass: "tit tit--xxs",
                attrs: {
                  "cols": "12",
                  "md": "auto"
                }
              }, [_vm._v(_vm._s(_vm.title))]), _c('v-spacer'), _c('v-col', {
                staticClass: "txt txt--sm txt--dark",
                attrs: {
                  "cols": "auto"
                }
              }, [_vm._v("카테고리")]), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('gallery-category', {
                attrs: {
                  "value": _vm.category.code,
                  "items": _vm.categoryItems,
                  "route": function (code) {
                    return {
                      query: {
                        code
                      }
                    };
                  }
                }
              })], 1)], 1)], 1), _c('default-list', _vm._b({}, 'default-list', {
                items
              }, false)), isMe ? _c('v-sheet', {
                staticClass: "mt-4"
              }, [_c('v-row', {
                attrs: {
                  "no-gutters": "",
                  "justify": "space-between"
                }
              }, [_c('v-spacer'), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('v-btn', _vm._b({
                staticClass: "v-btn--width-fixed",
                on: {
                  "click": function ($event) {
                    return _vm.$router.push(`${_vm.$route.path}/write`);
                  }
                }
              }, 'v-btn', Object.assign({}, _vm.btn_primary, {
                loading
              }), false), [_vm._v("글쓰기")])], 1)], 1)], 1) : _vm._e(), _c('v-pagination', {
                staticClass: "mt-4",
                attrs: {
                  "value": _vm.page,
                  "length": Math.ceil((summary === null || summary === void 0 ? void 0 : summary.totalCount) / _vm.limit) || 1,
                  "total-visible": 11,
                  "color": "primary"
                },
                on: {
                  "input": function (page) {
                    return _vm.$router.push({
                      query: Object.assign({}, _vm.$route.query, {
                        page
                      })
                    });
                  }
                }
              })];
            }
          }], null, true)
        }, 'story-list-card', {
          query: _vm.query,
          sort: _vm.sort,
          skip: _vm.skip,
          limit: _vm.limit
        }, false)) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }