var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "fill-height",
    class: _vm.className,
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "width": "100%"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_vm.imageSrc ? _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-img', _vm._b({
          key: _vm.value.image.name,
          ref: "showcase-area",
          staticStyle: {
            "position": "relative",
            "cursor": "pointer"
          },
          attrs: {
            "src": _vm.imageSrc
          },
          on: {
            "click": _vm.pushTag,
            "resize": _vm.setArea,
            "load": _vm.setArea
          }
        }, 'v-img', _vm.$attrs, false), [_vm.editable_image ? [_c('v-overlay', {
          staticClass: "d-block",
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "absolute": "",
            "color": "transparent",
            "dark": false
          }
        }, [_c('v-row', {
          staticClass: "ma-3 row--xxs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: hover,
                  expression: "hover"
                }],
                staticStyle: {
                  "pointer-events": "auto"
                },
                attrs: {
                  "fab": "",
                  "x-small": "",
                  "color": "white",
                  "elevation": "4"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.getImage.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("변경")])])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: hover,
                  expression: "hover"
                }],
                staticStyle: {
                  "pointer-events": "auto"
                },
                attrs: {
                  "fab": "",
                  "x-small": "",
                  "color": "white",
                  "elevation": "4"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.$emit('pull', _vm.value);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("삭제")])])], 1)], 1)], 1)] : _vm._e(), _vm.isImageLoaded ? [_vm._l(_vm.showcase.tags, function (item, z) {
          return [_c('v-fade-transition', {
            key: item._id
          }, [_c('showcase-tag', _vm._g(_vm._b({
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showsTags,
              expression: "showsTags"
            }],
            ref: item._id,
            refInFor: true,
            model: {
              value: _vm.showcase.tags[z],
              callback: function ($$v) {
                _vm.$set(_vm.showcase.tags, z, $$v);
              },
              expression: "showcase.tags[z]"
            }
          }, 'showcase-tag', {
            activeTag: _vm.activeTag,
            area: _vm.area,
            editable: _vm.editable,
            z
          }, false), {
            pullTag: _vm.pullTag,
            input: _vm.emit
          }))], 1)];
        })] : _vm._e()], 2)];
      }
    }], null, false, 3091565416)
  }) : _vm._e()], 1), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "image-input",
    on: {
      "change": _vm.setImage
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }