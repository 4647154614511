<template>
    <mini-shop-page v-model="user">
        <story-form v-model="board" v-bind="{ code, _board }" @updated="reroute" />
    </mini-shop-page>
</template>

<script>
import { initStoryBoard } from "@/store/story";

import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";
import StoryForm from "@/components/client/story/form/story-form.vue";

export default {
    components: {
        MiniShopPage,
        StoryForm,
    },
    props: {
        code: { type: String },
        _board: { type: String },
    },
    data: () => ({
        user: undefined,
        board: initStoryBoard(),
    }),
    methods: {
        reroute() {
            this.$router.go(-1);
            this.$router.replace(`/minishop/${this.user?._id}/story/${this.code}/${this.board?._id}`);
        },
    },
};
</script>