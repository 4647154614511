<template>
    <div>
        <div class="tit-wrap tit-wrap--lg text-center">
            <h2 class="tit">이달의 <span class="red--text">Hot</span> 협력사</h2>
        </div>
        <v-row no-gutters>
            <v-col cols="12" lg="6" xl="4">
                <store-profile hot :value="shop">
                    <template #avatar>
                        <v-img v-if="shop.thumb" :src="shop.thumb.src" />
                        <v-img v-else src="/images/test-img.jpg" />
                    </template>
                </store-profile>
            </v-col>
            <v-col cols="12" lg="6" xl="4">
                <hot-story :query="{ _writer: shop?._id }" v-if="shop?._id" />
            </v-col>
            <v-col cols="12" lg="6" xl="4">
                <hot-best :query="{ _seller: shop?._id }" v-if="shop?._id" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";
import StoreProfile from "@/components/client/sub/store/store-profile.vue";
import HotStory from "./hot/hot-story.vue";
import HotBest from "./hot/hot-best.vue";
export default {
    components: {
        StoreProfile,
        HotStory,
        HotBest,
    },
    data: () => ({
        shop: {},
    }),
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                let { stores } = await api.v1.shop.stores.gets({ params: { meta: { isHot: true } } });
                this.shop = stores?.[0];
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>