var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    attrs: {
      "value": _vm.shows,
      "width": "auto"
    },
    on: {
      "input": function (shows) {
        return _this.shows = shows;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0 justify-center"
  }, [_vm._v("게시글 삭제")])], 1), _c('v-card-text', {
    staticClass: "txt txt-sm pa-0 text-center"
  }, [_vm._v("선택한 게시글을 삭제하시겠습니까?")]), _c('v-card-actions', {
    staticClass: "btn-wrap px-0 pb-0"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function () {
        return _vm.shows = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    disabled: _vm.disabled
  }), false), [_vm._v("확인")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }