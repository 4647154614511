var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    attrs: {
      "value": _vm.showsDialog
    },
    on: {
      "input": function (shows) {
        return _this.showsDialog = shows;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0"
  }, [_vm._v("프로필 설정")])], 1), _c('v-divider', {
    staticStyle: {
      "border-width": "0 0 2px"
    },
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "my-18px my-lg-24px"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "profile-avatar"
  }, [_c('v-avatar', {
    attrs: {
      "size": "140"
    }
  }, [_vm.src ? _c('v-img', {
    attrs: {
      "src": _vm.src
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1), _c('v-btn', {
    staticClass: "v-size--xx-small white",
    attrs: {
      "elevation": "4",
      "icon": ""
    },
    on: {
      "click": _vm.click
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-file-input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "change": _vm.fileHandler
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  }), _c('div', {
    staticClass: "tit tit--xxs text-center mt-14px mt-lg-16px"
  }, [_vm._v(_vm._s(_vm.value.nickname))])], 1)], 1)], 1), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-simple-table', {
    staticClass: "v-data-table--no-hover"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("닉네임")]), _c('td', [_c('v-text-field', _vm._b({
    attrs: {
      "rules": _vm.rules.nickname,
      "dense": "",
      "placeholder": "2~15자 이내여야 합니다."
    },
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('tr', [_c('th', [_vm._v("태그")]), _c('td', [_c('v-combobox', _vm._b({
    attrs: {
      "multiple": "",
      "dense": false,
      "hideDetails": "",
      "append-icon": "",
      "placeholder": "엔터를 눌러서 태그를 입력해주세요"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          item = _ref2.item,
          parent = _ref2.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.form.mini.tag,
      callback: function ($$v) {
        _vm.$set(_vm.form.mini, "tag", $$v);
      },
      expression: "form.mini.tag"
    }
  }, 'v-combobox', _vm.attrs_input, false))], 1)]), _c('tr', [_c('th', [_vm._v("인사말")]), _c('td', [_c('v-textarea', _vm._b({
    model: {
      value: _vm.form.mini.greeting,
      callback: function ($$v) {
        _vm.$set(_vm.form.mini, "greeting", $$v);
      },
      expression: "form.mini.greeting"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)])])]), _c('v-card-actions', {
    staticClass: "btn-wrap px-0 pb-0"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": function () {
        return _vm.showsDialog = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "disabled": !this.isValid
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }