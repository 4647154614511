var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', [_vm._l(_vm.categories, function (category) {
    return [_c('v-col', {
      key: category._id,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('summary-boards', _vm._b({}, 'summary-boards', {
      category
    }, false))], 1)];
  })], 2), _c('v-divider', {
    staticClass: "my-40px my-md-70px my-lg-90px",
    attrs: {
      "dark": ""
    }
  }), _c('common-board', _vm._b({}, 'common-board', {
    category: _vm.commonCategory
  }, false)), _c('v-divider', {
    staticClass: "my-40px my-md-70px my-lg-90px",
    attrs: {
      "dark": ""
    }
  }), _c('summary-riders', _vm._b({}, 'summary-riders', {
    category: _vm.ridersCategory
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }