<template>
    <v-card flat tile v-bind="{ loading }">
        <template v-if="coupons.length">
            <!-- <v-row justify="end" align="center">
                <v-col cols="auto">
                    <span class="font-size-14">
                        총 <span class="secondary--text">{{ summary.totalCount }}</span>
                        개
                    </span>
                </v-col>
            </v-row> -->

            <v-row class="ma-n20px ma-lg-n40px">
                <template v-for="coupon in coupons">
                    <v-col cols="12" lg="6" :key="coupon._id" class="pa-20px pa-lg-40px">
                        <shop-coupon-item v-bind="{ coupon }" />
                    </v-col>
                </template>
            </v-row>
            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
            </div>
        </template>
        <template v-else>
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-ticket-percent</v-icon>
                </div>
                <div class="tit tit--sm">다운로드 가능한 쿠폰이없습니다.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </v-card>
</template>

<script>
import api from "@/api";
import { initShopCoupon } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";

import ShopCouponItem from "@/components/client/shop/coupons/shop-coupon-item.vue";

export default {
    components: {
        ShopCouponItem,
    },
    props: {
        _seller: { type: String, default: null },
    },
    data: () => ({
        btn_primary,

        coupons: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        query: {},

        loading: false,
    }),
    computed: {
        items() {
            return [...this.coupons].map(initShopCoupon);
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.query;

            query._seller = this._seller;
            if (!query._seller) delete query._seller;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
        "$route.path"(path) {
            if (path == "/mypage/coupons/download") this.search();
        },
    },
    methods: {
        async init() {
            this.coupons = [];
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, coupons } = await api.v1.shop.coupons.gets({
                    headers: { skip, limit },
                    params,
                });
                this.summary = summary;
                this.coupons = coupons;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
