<template>
    <mini-shop-page v-model="user">
        <v-row justify="center">
            <v-col cols="auto">
                <story-view v-model="board" v-bind="{ code : code ? code : (board.code || null)  , _board }" />
            </v-col>
        </v-row>
    </mini-shop-page>
</template>
 
<script>
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import StoryView from "@/components/client/story/view/story-view.vue";

export default {
    components: {
        MiniShopPage,

        StoryView,
    },
    props: {
        code: { type: String, default: null },
        path: { type: String, default: null },
        _board: { type: String },
    },
    data: () => ({
        user: undefined,
        board: {},
    }),
    methods: {
        reroute() {
            if (!!this.path && !!this.user && !!this.board?.meta?.mini?.code) {
                const code = (this.user?.mini?.storyCodes || []).find(({ code }) => code == this.board?.meta?.mini?.code) || { path: this.path };

                const path = `/minishop/${this.user?._id}/story/${code?.path}/${this.board?._id}`;
                if (this.$route.path != path) {
                    this.$router.replace(path);
                }
            }
        },
    },
    watch: {
        user() {
            this.reroute();
        },
        board() {
            this.reroute();
        },
    },
    mounted() {
        this.reroute();
    },
};
</script>