<template>
    <v-combobox v-model="form.meta.tags" :search-input.sync="text" v-bind="attrs" @input="emit">
        <template #append>
            <v-fade-transition>
                <v-btn v-show="!!text?.trim?.()" text small color="primary" @click="push">추가</v-btn>
            </v-fade-transition>
        </template>
    </v-combobox>
</template>

<script>
import { initStoryBoard } from "@/store/story";
export default {
    props: {
        value: { type: Object, default: initStoryBoard },
    },
    data: () => ({
        form: initStoryBoard(),
        text: null,
    }),
    computed: {
        attrs() {
            return {
                multiple: true,

                dense: true,
                outlined: true,

                appendIcon: "",
                hideDetails: true,

                chips: true,
                smallChips: true,
                deletableChips: true,
            };
        },
    },
    methods: {
        sync() {
            this.form = initStoryBoard(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        push() {
            let text = this.text?.trim?.();
            if (!text) return;
            this.form.meta.tags = [...new Set([...(this.form?.meta?.tags || []), text])];
            this.text = null;
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__append-inner {
    margin: 0 -16px 0 0 !important;
}
</style>
