<template>
    <dialog-basic v-model="shows" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">카테고리 관리</v-card-title>
        </div>
        <div class="tit-wrap tit-wrap--sm">
            <v-card-subtitle class="pa-0 txt txt--sm grey--text">카테고리를 추가하고 순서를 변경할 수 있습니다.</v-card-subtitle>
        </div>

        <v-btn block tile large class="add-button primary--text justify-start pa-4px mb-14px mb-lg-18px" @click="push">
            <v-card color="transparent" tile width="42">
                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center">
                    <v-icon class="font-size-18 h-100">mdi-plus</v-icon>
                </v-responsive>
            </v-card>
            <div>카테고리 추가</div>
        </v-btn>

        <v-form v-model="isValid">
            <v-list class="categories pa-0">
                <draggable v-model="storyCodes" draggable=".category-item" handle=".category-item-handle">
                    <template v-for="(item, index) in storyCodes">
                        <v-list-item class="category-item px-4px" :key="item.code">
                            <v-row class="ma-n4px" align="center">
                                <v-col cols="auto" class="pa-4px">
                                    <v-btn v-bind="{ ...btn_icon }" small tile class="category-item-handle" :style="{ cursor: 'grab' }">
                                        <v-icon>sort</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col class="px-1 py-3">
                                    <v-text-field v-model="storyCodes[index].name" v-bind="{ ...attrs_input }" label="이름" dense placeholder="카테고리 추가 시 갤러리형으로 생성됩니다." persistent-placeholder />
                                </v-col>
                                <!-- <v-col class="px-1 py-3">
                                    <v-text-field v-model="storyCodes[index].path" v-bind="{ ...attrs_input }" label="경로" dense persistent-placeholder :rules="pathRules">
                                        <template #prepend-inner> ../story/ </template>
                                    </v-text-field>
                                </v-col> -->
                                <v-col cols="auto" class="pa-4px">
                                    <mini-delete-category @remove="pull(index)">
                                        <template #activator="{ attrs, on }">
                                            <v-btn v-bind="{ ...btn_secondary, attrs }" v-on="on" small>삭제</v-btn>
                                        </template>
                                    </mini-delete-category>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </template>

                    <!-- S: 고정 카테고리 -->
                    <v-list-item class="grey lighten-5">
                        <span class="txt txt--sm">일상 이야기</span>
                    </v-list-item>
                    <v-list-item class="grey lighten-5">
                        <span class="txt txt--sm">라이더 이야기</span>
                    </v-list-item>
                    <v-list-item v-if="value?.type===USER_TYPES.COMPANY.value" class="grey lighten-5">
                        <span class="txt txt--sm">사장님 이야기</span>
                    </v-list-item>
                    <!-- E: 고정 카테고리 -->
                </draggable>
            </v-list>
        </v-form>

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row justify="end" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, disabled: !isValid }" class="v-btn--width-fixed" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import api from "@/api";
import { attrs_input, btn_icon, btn_primary, btn_secondary, USER_TYPES } from "@/assets/variables";

import Draggable from "vuedraggable";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";
import MiniDeleteCategory from "@/components/client/miniShop/dialog/mini-delete-category.vue";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';

const initStoryCodeItem = (item = {}) => ({
    ...item,

    code: item?.code || Math.random().toString().slice(2, 8).padEnd(8, "0"),
    name: item?.name || null,
    path: item?.path || null,
});

export default {
    components: {
        Draggable,

        DialogBasic,
        MiniDeleteCategory,
    },
    props: {
        value: { type: Object, default: () => {} },
    },
    data: () => ({
        USER_TYPES,
        storyCodes: [],

        shows: false,
        isValid: false,
        loading: false,

        attrs_input,
        btn_icon,
        btn_primary,
        btn_secondary,
    }),
    computed: {
        pathRules() {
            const rules = [
                //
                (value) => !/[^a-z\d-_]/.test(value) || "영문 소문자 및 숫자로만 작성해주세요",
                (value) => (this.storyCodes || []).filter(({ path }) => path == value)?.length < 2 || "이미 있는 경로명입니다.",
                (value) => !["daily", "owner", "rider"].includes(value) || "사용할 수 없는 경로명입니다.",
            ];

            return rules;
        },
    },
    methods: {
        init() {
            this.storyCodes = [...(this.value?.mini?.storyCodes || [])];
        },
        open() {
            this.shows = true;
        },
        push() {
            this.storyCodes.push(initStoryCodeItem());
        },
        pull(index) {
            this.storyCodes.splice(index, 1);
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let [...storyCodes] = this.storyCodes;
                
                if(!this.validates()) return;

                storyCodes.forEach((storyCode)=> storyCode.path = storyCode.code);
                await api.v1.me.put({ mini: { ...this.value.mini, storyCodes } });

                alert("변경되었습니다");

                this.$emit("input");
                this.shows = false;
            } catch(error){
                this.$handleError(error);
            
            } finally {
                this.loading = false;
            }
        },
        validates(){
            const bannedKeywords = this.$store.state.setting.bannedKeywords;
            this.storyCodes.forEach((storyCode)=> {
                checkForBannedKeywords(storyCode.name, bannedKeywords);
            });

            return true;
        }
    },
    watch: {
        shows() {
            this.init();
        },
        value() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.add-button {
    background-color: var(--v-grey-lighten5) !important;
    border: 1px dashed var(--v-grey-base);
}
.v-list.categories {
    border: 1px solid var(--border-color);
    .v-list-item {
        border-bottom: 1px solid;
        border-color: var(--border-color) !important;
        &:last-child {
            border-bottom: 0;
        }
    }
}
</style>