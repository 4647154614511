var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    staticClass: "rider-story-slide swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.boards, function (board) {
    return [_c('swiper-slide', {
      key: board._id
    }, [_c('riders-item', _vm._b({}, 'riders-item', {
      board
    }, false))], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }