<template>
    <v-sheet>
        <v-row class="row--xxs">
            <template v-if="0 < (board?.meta?.tags?.length || 0)">
                <v-col cols="12">
                    <hashtag-search v-bind="{ tags: board?.meta?.tags || [] }" />
                    <!-- <v-chip-group>
                                <template v-for="tag in board?.meta?.tags || []">
                                    <v-chip v-bind="{ ...chip_secondary }" :key="tag">#{{ tag }}</v-chip>
                                </template>
                            </v-chip-group> -->
                </v-col>
            </template>
            <v-col cols="12">
                <template v-for="(item, i) in items">
                    <template v-if="item.type == 'html'">
                        <component :is="item.is" v-html="item.html" :key="i" class="board-content" />
                    </template>
                    <template v-if="item.type == 'showcase' && (item.showcase._id || item.showcase.tempId)">
                        <story-view-showcase :value="item.showcase" :key="i" />
                    </template>
                </template>
            </v-col>
            <v-col cols="12" v-if="$scopedSlots['foot']">
                <div class="d-flex justify-center align-center">
                    <slot name="foot" />
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { initStoryBoard, STORY_TAG_REGEX } from "@/store/story";

import HashtagSearch from "@/components/dumb/hashtag-search.vue";
import StoryViewShowcase from "@/components/client/story/view/story-view-showcase.vue";

export default {
    components: {
        HashtagSearch,
        StoryViewShowcase,
    },
    props: {
        board: { type: Object, default: initStoryBoard },
        isForm: { type: Boolean, default: false },
    },
    computed: {
        items() {
            /** @type {string} */
            const content = this.board?.content || "";
            const iterator = content.matchAll(STORY_TAG_REGEX);
            const items = [];

            let lastIndex = 0;
            do {
                const target = iterator.next();
                if (target.done) {
                    const html = content.slice(lastIndex, content.length);
                    if (html.length) {
                        items.push({
                            type: "html",
                            html,
                            is: target?.value?.[1] || "div",
                        });
                    }
                    break;
                }
                const homeIndex = target.value.index;
                const html = content.slice(lastIndex, homeIndex);
                if (html.length) {
                    items.push({
                        type: "html",
                        // is: target.value[1],
                        is: "div",
                        html,
                    });
                }
                lastIndex = homeIndex + target.value[0].length;
                items.push({
                    type: "showcase",
                    showcase: this.board.showcases.find(({ _id, tempId }) => (this.isForm ? tempId : _id) == target.value[2]) || {},
                });
            } while (true);

            return items;
        },
    },
};
</script>

<style>
</style>