<template>
    <v-card width="333" rounded="sm">
        <v-card-text>
            <v-list>
                <v-list-item class="pa-0 cursor-pointer" style="border: none" @click="go">
                    <v-list-item-avatar size="60" rounded="sm" class="border">
                        <v-img :src="value?.thumb?.url">
                            <template #placeholder>
                                <v-icon>mdi-image-broken</v-icon>
                            </template>
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle class="txt txt--xs">
                            {{ value?.seller?.nickname }}
                        </v-list-item-subtitle>
                        <v-list-item-title class="txt txt--dark">
                            {{ value?.name }}
                        </v-list-item-title>
                        <v-list-item-title class="txt accent1--text font-weight-bold">{{ value?.salePrice.format() }}원</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="isActable" class="flex-row">
                        <v-row class="ma-n2px">
                            <v-col cols="auto" class="pa-2px">
                                <v-btn outlined x-small min-width="58" @click="edit">변경</v-btn>
                            </v-col>
                            <v-col cols="auto" class="pa-2px">
                                <v-btn outlined x-small min-width="58" @click.capture.stop="pull">삭제</v-btn>
                            </v-col>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";
import { initCommonShowcaseTag } from "@/store/story";

export default {
    props: {
        value: { type: Object, default: initCommonShowcaseTag },
    },
    data: () => ({
        _tag: null,
    }),
    computed: {
        isActable() {
            return this.$route.path.includes("write") || this.$route.path.includes("modify");
        },
    },
    methods: {
        edit() {
            this.$emit("input", null);
        },
        pull() {
            this.$emit("pullTag");
        },
        async search() {
            try {
                let { tags } = await api.v1.shop.tagEvents.gets({ params: { _product: this.value?._id } });
                this._tag = tags?.[0]?._id;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async go() {
            // 클릭 데이터 생성
            let params = { _board: this.$route.params._board, _product: this.value?._id };
            if(this._tag) params = { ...params, _tag: this._tag };
            await api.v1.shop.tagLogs.post(params);

            // 상품 상세 페이지로 이동
            let router = `/shop/products/${this.value?._id}`;
            if (this._tag) router = { path: router, query: { _tag: this._tag, _board: this.$route.params._board } };

            this.$router.push(router);
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>