var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var isMe = _ref.isMe;
        return [_vm.user ? _c('story-list-card', _vm._b({
          attrs: {
            "componentName": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var items = _ref2.items,
                summary = _ref2.summary,
                loading = _ref2.loading,
                search = _ref2.search;
              return [_c('v-sheet', {
                staticClass: "mb-16px mb-20px"
              }, [_c('v-row', {
                staticClass: "row--xxs",
                attrs: {
                  "align": "center"
                }
              }, [isMe ? _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('story-edit', _vm._b({
                on: {
                  "updated": search
                }
              }, 'story-edit', {
                selected: _vm.selected,
                user: _vm.user
              }, false))], 1) : _vm._e(), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('story-sort')], 1), _c('v-spacer'), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_c('common-filter', {
                attrs: {
                  "hideSearchKeys": ""
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "tit-wrap tit-wrap--sm"
              }, [_c('h3', {
                staticClass: "tit tit--xxs"
              }, [_vm._v(_vm._s(_vm.title))])]), _c('v-divider', {
                staticClass: "mb-20px mb-lg-40px",
                staticStyle: {
                  "border-width": "0 0 2px"
                },
                attrs: {
                  "dark": ""
                }
              }), _c('v-row', [_vm._l(items, function (item) {
                return [_c('v-col', {
                  key: item._id,
                  attrs: {
                    "cols": "6",
                    "md": "3"
                  }
                }, [_c('gallery-item', _vm._b({
                  attrs: {
                    "value": item,
                    "hidesNickname": "",
                    "showsCheckbox": isMe
                  },
                  on: {
                    "update:selected": function (__selected) {
                      return _vm.selected = __selected;
                    }
                  }
                }, 'gallery-item', {
                  selected: _vm.selected
                }, false))], 1)];
              }), items.length < 1 && !loading ? [_c('v-col', [_c('v-card-text', {
                staticClass: "text-center"
              }, [_vm._v(" 등록된 게시글이 없습니다 ")])], 1)] : _vm._e()], 2), _c('div', {
                staticClass: "pagination-wrap"
              }, [_c('v-pagination', {
                staticClass: "mt-4",
                attrs: {
                  "value": _vm.page,
                  "length": Math.ceil((summary === null || summary === void 0 ? void 0 : summary.totalCount) / _vm.limit) || 1,
                  "total-visible": 11,
                  "color": "primary"
                },
                on: {
                  "input": function (page) {
                    return _vm.$router.push({
                      query: Object.assign({}, _vm.$route.query, {
                        page
                      })
                    });
                  }
                }
              })], 1)];
            }
          }], null, true)
        }, 'story-list-card', {
          query: _vm.query,
          sort: _vm.sort,
          skip: _vm.skip,
          limit: _vm.limit
        }, false)) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }