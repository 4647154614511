<template>
    <v-menu :offset-x="true">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="{ ...btn_icon, attrs }" v-on="on" class="v-size--xx-small">
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item class="pa-0">
                <mini-post-move v-bind="{ selected, storyCodes }" @updated="$emit('updated')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="{ ...attrs, disabled }" v-on="on" color="transparent">게시물 이동</v-btn>
                    </template>
                </mini-post-move>
            </v-list-item>
            <v-list-item class="pa-0">
                <mini-post-delete v-bind="{ selected }" @updated="$emit('updated')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="{ ...attrs, disabled }" v-on="on" color="transparent">게시물 삭제</v-btn>
                    </template>
                </mini-post-delete>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { btn_icon, initUser } from "@/assets/variables";

import MiniPostDelete from "@/components/client/miniShop/dialog/mini-post-delete.vue";
import MiniPostMove from "@/components/client/miniShop/dialog/mini-post-move.vue";

export default {
    components: {
        MiniPostDelete,
        MiniPostMove,
    },
    props: {
        user: { type: Object, default: initUser },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        btn_icon,
    }),
    computed: {
        disabled() {
            return this.selected.length < 1;
        },
        storyCodes() {
            return this.user?.mini?.storyCodes;
        },
    },
};
</script>

<style>
</style>