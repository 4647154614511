<template>
    <v-sheet>
        <template v-if="writable">
            <vertical-form-table v-model="board" v-bind="{ items }" topBorderMdNone class="vertical-form-table--border" @input="emit">
                <template #해시태그>
                    <story-form-hashtags v-model="board" @input="emit" />
                </template>
            </vertical-form-table>
            <div class="py-12px py-md-20px">
                <naver-smarteditor v-model="board.content" bSkipXssFilter @input="emit" :key="editorKey" />
            </div>

            <template v-if="category?.showcase">
                <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />

                <story-form-showcase v-model="board.showcases" class="my-6" v-bind="{ board }" v-on="{ pushOnContent }" @input="emit" />
            </template>

            <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />

            <div class="btn-wrap mx-n12px mx-md-0px">
                <v-row justify="center">
                    <v-col cols="12" md="auto">
                        <v-row class="row--xxs">
                            <v-col cols="6" md="auto">
                                <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="save">저장</v-btn>
                            </v-col>
                            <v-col cols="6" md="auto">
                                <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$router.go(-1)">취소</v-btn>
                                <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$router.go(-1)">취소</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </template>
        <template v-else>권한이 없습니다.</template>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters, mapState } from "vuex";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';
import { initStoryBoard } from "@/store/story";
import { btn_primary, btn_primary3, btn_secondary, attrs_input } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import StoryPage from "@/components/client/templates/story-page.vue";
import StoryFormShowcase from "@/components/client/story/form/story-form-showcase.vue";
import StoryFormHashtags from "./story-form-hashtags.vue";

export default {
    components: {
        VerticalFormTable,
        NaverSmarteditor,

        StoryPage,
        StoryFormShowcase,
        StoryFormHashtags,
    },
    props: {
        code: { type: String },
        _board: { type: String, default: null },
    },
    data: () => ({
        board: initStoryBoard(),

        // ++를 통해서 에디터 업데이트
        editorKey: 0,

        loading: false,

        btn_primary,
        btn_primary3,
        btn_secondary,
        attrs_input,
    }),
    computed: {
        ...mapState(["scope", "user"]),
        ...mapGetters("story", ["getCategory"]),

        category() {
            return this.getCategory(this.code);
        },
        writable() {
            if ((this.category?.scope__form?.length || 0) == 0) return true;
            else return this.category?.scope__form?.some?.((item) => (this.scope || []).includes(item));
        },
        isCreate() {
            return !this._board;
        },
        items() {
            const items = [];

            if (0 < this.category?.children?.length) {
                items.push({
                    key: "code",
                    term: "분류",
                    type: "select",
                    class: "w-md-358px",
                    items: this.category.children.map(({ code, name }) => ({ text: name, value: code })),
                });
            }

            items.push(
                {
                    key: "writer.nickname",
                    term: "작성자",
                    type: "text",
                    filled: true,
                    disabled: true,
                    class: "w-md-358px",
                },
                {
                    key: "subject",
                    term: "제목",
                    type: "text",
                },
                {
                    key: "meta.tags",
                    term: "해시태그",
                }
            );

            return items.map((item) => ({ ...item, dense: true, outlined: true }));
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (!this.writable) return;

            if (this.isCreate) {
                if (!this.user) await this.getUser();

                this.board = initStoryBoard({
                    writer: {
                        _id: this.user._id,
                        nickname: this.user.nickname,
                    },
                });
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const _user = this.$store.state.payload._user;
                const { board } = await api.v1.boards.get({ _id: this._board });

                //URL로 접속 시, 권한 확인
                if(!_user || board.writer._id !== _user){
                    alert("권한이 없습니다.");
                    this.$router.go(-1);
                    return;
                }

                this.board = initStoryBoard(board);
                this.$emit("input", this.board);
                this.editorKey++;
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (!this.writable) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                const { ...form } = this.board;
                if(!this.validates(form)) return;
                delete form.writer;

                if (!form.code) form.code = this.code;

                ////////////////////////////////////////////////////////////////
                // common-showcases 처리
                ////////////////////////////////////////////////////////////////
                for (let [index, { _image, image, tempId, ...showcase }] of form.showcases.entries()) {
                    // 데이터 처리
                    for (const tag of showcase.tags) {
                        tag._product = tag.product?._id;
                    }
                    const isCreate = !showcase._id;
                    const { post, put } = api.v1.showcases;
                    showcase = (await (isCreate ? post : put)(showcase))?.showcase;

                    // 리소스 처리
                    if (image instanceof File) {
                        image = (
                            await api.v1.files.post(
                                {
                                    path: "showcases",
                                    _index: showcase._id,
                                    index,
                                },
                                image
                            )
                        )?.file;
                    }
                    if (!!_image && image?._id != _image) {
                        await api.v1.files.delete({ _id: _image });
                    }
                    _image = image?._id;
                    if (showcase._image != _image) {
                        showcase = (await put({ ...showcase, _image }))?.showcase;
                    }

                    form.showcases[index] = showcase;
                    //blob 이미지 -> 이미지 src 변경
                    form.content = form.content.replaceAll(new RegExp(`<img([^>]+){{_showcase:${tempId}}}[^>]+>`, "g"), `<img src='${image?.src}' data='{{_showcase:${showcase?._id}}}' />`);
                    
                    // form.thumb = /<img [^\>]*src=["']([^\>\s]+)["'][^\>]*\/*>/.exec(form.content)?.[1] || null;

                    // form.content = form.content.replaceAll(`_showcase:${tempId}`, `_showcase:${showcase._id}`);
                }
                form._showcases = form.showcases.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // boards 처리
                ////////////////////////////////////////////////////////////////
                const { post, put } = api.v1.boards;
                let { board } = await (this.isCreate ? post : put)(form);

                this.board = initStoryBoard(board);
                this.$emit("input", this.board);

                alert("저장되었습니다");
                this.$emit("updated");
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.board = initStoryBoard(this.board);
        },
        pushOnContent(tempId) {
            const src = this.getImageSrc(tempId);
            this.board.content += `<img src="${src}" data="{{_showcase:${tempId}}}" />`;
            this.editorKey++;
        },
        getImageSrc(tempId) {
            const showcase = this.board.showcases.find((showcase) => showcase.tempId === tempId);
            if (showcase?.image instanceof File) {
                return URL.createObjectURL(showcase.image);
            } else {
                return showcase?.image?.url;
            }
        },
        validates(form){
            const bannedKeywords = this.$store.state.setting.bannedKeywords;
            checkForBannedKeywords(form.subject, bannedKeywords);

            form.meta.tags.forEach(tag => {
                checkForBannedKeywords(tag, bannedKeywords);
            });

            checkForBannedKeywords(form.content, bannedKeywords);

            return true;
        }
    },
    watch: {
        value() {
            this.board = this.value;
            this.editorKey++;
        },
    },
    mounted() {
        this.init();
    },
};
</script>