<template>
    <v-card tile class="pb-30px pb-xl-0" :to="`${path}/${value._id}`">
        <v-card v-if="value.storyThumbSrc" :img="value.storyThumbSrc" tile>
            <v-responsive :aspect-ratio="1 / 1" />
        </v-card>
        <v-card v-else img="/images/sub/story/storyDefault.jpg" tile flat>
            <v-responsive :aspect-ratio="1 / 1" />
        </v-card>
        <h4 class="tit tit--xxs mt-12px mb-6px line-height-15 text-truncate">
            <v-icon v-if="value.isNotice" class="icon icon-pin ml-4px"></v-icon>
            {{ value.subject }}
        </h4>
        <p class="txt txt--dark text-truncate-2">{{ value.contentText }}</p>
    </v-card>
</template>

<script>

export default {
    props: {
        path: { type: String, default: "/story/:code" },
        value: { type: Object, default: () => ({}) }, // boards
    },
};
</script>

<style>
</style>