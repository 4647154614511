<template>
    <dialog-basic :value="showsDialog" @input="(shows) => (this.showsDialog = shows)">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">스킨 설정</v-card-title>
        </div>

        <v-simple-table>
            <tbody>
                <tr>
                    <th>배경 이미지</th>
                    <td>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <v-img v-if="src" width="100" :src="src">
                                    <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                                </v-img>
                                <v-card v-else width="100" tile color="grey lighten-5">
                                    <v-responsive :aspect-ratio="1 / 1" class="text-center align-center">
                                        <span class="txt txt--xs line-height-18">
                                            이미지<br />
                                            업로드
                                        </span>
                                    </v-responsive>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-row align-content="space-between" class="row--xxs h-100">
                                    <v-col cols="12">
                                        <v-btn v-bind="{ ...btn_secondary }" x-small @click="click">파일선택</v-btn>
                                        <v-file-input v-model="form.banner" ref="fileInput" class="d-none" @change="fileHandler" accept="image/*"></v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="txt txt--xs">
                                            <div class="mb-4px">JPG, PNG</div>
                                            <div>권장사이즈 : 가로1920px 세로400px 또는 가로2280px 세로475px</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </td>
                </tr>

                <tr>
                    <th>타이틀</th>
                    <td>
                        <v-text-field v-model="form.mini.title" v-bind="{ ...attrs_input }" dense></v-text-field>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row class="row--xxs" justify="end">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="() => (showsDialog = false)">취소</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary }" class="v-btn--width-fixed" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import api from "@/api";
import { initMini } from "./mini-profile-edit.vue";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';
import { attrs_input, btn_primary, btn_secondary } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

const initSkin = (skin = {}) => ({
    _banner: skin?._banner || "",

    banner: skin?.banner || "",
    mini: initMini(skin?.mini),
});
export default {
    components: {
        DialogBasic,
    },
    props: {
        value: { type: Object, default: () => {} },
    },
    data: () => ({
        showsDialog: false,

        attrs_input,
        btn_primary,
        btn_secondary,

        form: initSkin(),
    }),
    computed: {
        src() {
            return this.form.banner instanceof File ? URL.createObjectURL(this.form.banner) : this.form.banner?.src;
        },
    },
    methods: {
        sync() {
            this.form = initSkin(this.value);
        },
        async save() {
            try {
                let { _banner, banner, ...form } = this.form;

                if(!this.validates(form)) return false;

                let { user } = await api.v1.me.put(form);

                if (banner instanceof File) {
                    banner = (await api.v1.files.post({ path: "banner" }, banner))?.file;
                    user = (await api.v1.me.put({ _banner: banner._id }))?.user;
                }

                if (_banner && _banner !== banner._id) await api.v1.files.delete({ _id: _banner });

                this.$emit("input");

                this.showsDialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
        click() {
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
        fileHandler(file) {
            if (file instanceof File) this.form.thumb = file;
        },
        validates(form){
            checkForBannedKeywords(form.mini.title, this.$store.state.setting.bannedKeywords);
            return true;
        }
    },
    mounted() {
        this.sync();
    },
    watch: {
        showsDialog() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            text-align: center !important;
            border-right: 1px solid var(--border-color);
        }
    }
}
</style>