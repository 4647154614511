var render = function render(){
  var _vm$value, _vm$value$thumb, _vm$value2, _vm$value2$seller, _vm$value3, _vm$value4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "width": "333",
      "rounded": "sm"
    }
  }, [_c('v-card-text', [_c('v-list', [_c('v-list-item', {
    staticClass: "pa-0 cursor-pointer",
    staticStyle: {
      "border": "none"
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-list-item-avatar', {
    staticClass: "border",
    attrs: {
      "size": "60",
      "rounded": "sm"
    }
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$thumb = _vm$value.thumb) === null || _vm$value$thumb === void 0 ? void 0 : _vm$value$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-icon', [_vm._v("mdi-image-broken")])];
      },
      proxy: true
    }])
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$seller = _vm$value2.seller) === null || _vm$value2$seller === void 0 ? void 0 : _vm$value2$seller.nickname) + " ")]), _c('v-list-item-title', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.name) + " ")]), _c('v-list-item-title', {
    staticClass: "txt accent1--text font-weight-bold"
  }, [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.salePrice.format()) + "원")])], 1), _vm.isActable ? _c('v-list-item-action', {
    staticClass: "flex-row"
  }, [_c('v-row', {
    staticClass: "ma-n2px"
  }, [_c('v-col', {
    staticClass: "pa-2px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "x-small": "",
      "min-width": "58"
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("변경")])], 1), _c('v-col', {
    staticClass: "pa-2px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "x-small": "",
      "min-width": "58"
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.pull.apply(null, arguments);
      }
    }
  }, [_vm._v("삭제")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }