<template>
    <v-row class="row--xxs">
        <v-col cols="auto">
            <v-file-btn v-bind="{ ...btn_secondary, loading }" accept="image/*" class="v-size--xx-large w-100 w-md-140px" ref="image-input" @input="push"> 이미지 추가 </v-file-btn>
        </v-col>
        <v-col v-if="$slots['guide-button']" cols="auto">
            <slot name="guide-button" />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <showcase-preview v-bind="{ board }">
                <template #activator="{ attrs, on }">
                    <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on" class="v-size--xx-large w-100 w-md-140px">미리보기</v-btn>
                </template>
            </showcase-preview>
        </v-col>
        <v-col cols="auto">
            <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large w-100 w-md-140px" @click="$emit('pushOnContent')">본문에 삽입</v-btn>
        </v-col>
        <template v-if="0 < showcases.length">
            <v-col cols="12" class="pt-0">
                <draggable v-model="showcases" class="row row--xxs" draggable=".showcase-item" @change="emit">
                    <template v-for="(showcase, i) in showcases">
                        <v-col cols="auto" class="showcase-item" :key="showcase.tempId">
                            <v-card tile class="attachment-img" :class="{ thumb: i == 0, onTab: tab == showcase.tempId }" :ripple="false" @click="setTab(showcase)">
                                <v-btn fab color="primary" class="attachment-img__close" @click.stop="pull(showcase)">
                                    <v-icon x-small color="white"> mdi-close </v-icon>
                                </v-btn>
                                <v-img class="attachment-img__image" :aspect-ratio="1 / 1" width="72" :src="getSrc(showcase.image)">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-col>
                    </template>
                </draggable>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { btn_secondary } from "@/assets/variables";
import { initCommonShowcase, initStoryBoard } from "@/store/story";

import Draggable from "vuedraggable";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import ShowcasePreview from "./showcase-preview.vue";

export default {
    components: {
        Draggable,
        VFileBtn,
        ShowcasePreview,
    },
    props: {
        value: { type: Array, default: () => [] },
        board: { type: Object, default: initStoryBoard },

        tab: { type: [String, Number], default: null },
        loading: { type: Boolean, default: false },
        isMobile: { type: Boolean, default: false },
    },
    data: () => ({
        showcases: [],

        btn_secondary,
    }),
    methods: {
        sync() {
            this.showcases = this.value.map(initCommonShowcase);
        },
        emit() {
            this.$emit("input", this.showcases);
        },
        push(image) {
            this.showcases.push(initCommonShowcase({ image }));
            this.$refs["image-input"]?.$on?.click?.clear?.();
            this.emit();
        },
        pull(showcase) {
            this.showcases = this.showcases.filter((item) => item.tempId != showcase.tempId);
            this.emit();
        },
        getSrc(image) {
            if (image instanceof File) return URL.createObjectURL(image);
            else return image?.url || null;
        },
        setTab(showcase) {
            this.$emit("setTab", showcase);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
.attachment-img {
    &::before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        border-width: 2px;
        border-style: solid;
        border-color: transparent;

        width: 100%;
        height: 100%;
        background-color: transparent;

        opacity: 1 !important;
        z-index: 1;
    }
    &.thumb::before {
        border-color: var(--v-secondary-base);
    }
    &.onTab::before {
        border-color: green;
    }
    &__close {
        z-index: 2;
    }
    &__image {
        z-index: 0;
    }
}
</style>