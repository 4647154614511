<template>
    <v-select v-model="code" v-bind="{ items }" outlined dense hide-details @input="push" />
</template>

<script>
export default {
    props: {
        value: { type: String, default: "bicycle" },
        items: { type: Array, default: () => [] },

        route: { type: Function, default: (code) => ({ path: `/story/${code}` }) },
    },
    data: () => ({
        code: undefined,
    }),
    methods: {
        sync() {
            this.code = this.value;
        },
        push() {
            this.$router.push(this.route(this.code));
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>