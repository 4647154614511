var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1040"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('store-category-card', {
    attrs: {
      "tit": "전기자전거",
      "to": "/sub/store/bicycle",
      "bg": "/images/sub/store/bicycle/store-bicycle.png"
    }
  })], 1), _c('v-col', [_c('store-category-card', {
    attrs: {
      "tit": "전동스쿠터",
      "to": "/sub/store/scooter",
      "bg": "/images/sub/store/scooter/store-scooter.png"
    }
  })], 1), _c('v-col', [_c('store-category-card', {
    attrs: {
      "tit": "전동킥보드",
      "to": "/sub/store/kickboard",
      "bg": "/images/sub/store/kickboard/store-kickboard.png"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg d-flex justify-center"
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-large": "",
      "to": "/sub/store/map"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_c('v-img', {
    staticClass: "mr-4px",
    attrs: {
      "src": "/images/icon/icon-marker-pin.png",
      "max-width": "16"
    }
  }), _vm._v(" 내 근처 AS샵 보기 ")], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("나만의 협력사를 찾아보세요!")])]);

}]

export { render, staticRenderFns }