var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', _vm._b({
    on: {
      "input": _vm.emit
    }
  }, 'mini-shop-page', {
    value: _vm.user,
    isMe: _vm.isMe
  }, false), [_c('v-sheet', {
    staticClass: "mb-16px mb-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_vm.isMe ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('follow-private-toggle', {
    attrs: {
      "value": _vm.user
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "w-md-300px",
    attrs: {
      "dense": "",
      "placeholder": "검색어를 입력하세요",
      "clearable": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function () {
          _vm.$router.push({
            query: Object.assign({}, _vm.$route.query, _vm.filter, {
              page: 1
            })
          });
        }.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    },
    on: {
      "click": function () {
        _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, _vm.filter, {
            page: 1
          })
        });
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("검색")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('v-divider', {
    staticClass: "mb-30px",
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('follow-list', _vm._b({
    on: {
      "input": _vm.emit
    }
  }, 'follow-list', {
    value: _vm.user,
    isMe: _vm.isMe
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }