<template>
  <v-container v-if="schedule">
        <v-row justify="space-between">
            <v-col cols="12" lg="7">
                <div class="tit-wrap tit-wrap--sm">
                    <h2 class="tit tit--xxs">날짜를 선택해주세요.</h2>
                </div>
                <mini-date-picker v-bind="{ value: schedule }" @input="change"/>
            </v-col>
            <v-col cols="12" lg="auto">
                <v-divider class="d-lg-none"></v-divider>
                <v-divider class="d-none d-lg-block" vertical></v-divider>
            </v-col>
            <v-col cols="12" lg="">
                <div class="tit-wrap tit-wrap--sm">
                    <h2 class="tit tit--xxs">시간 선택</h2>
                </div>
                <div class="mb-18px mb-lg-24px">{{ dayjs(date||dayjs()).format("MM월 DD일(ddd)") }}</div>
                <v-btn-toggle v-model="timeSelect">
                    <v-btn v-bind="{loading}" 
                    v-for="(item, index) in schedulesWithReservation" :key="index" :value="item"
                    :disabled="!!item.reservation"
                    >
                        {{ item?.startsAt }} ~ {{ item?.endsAt }}
                        <span v-if="isReservationAwaiting(item)"
                         class="accent2--text">&nbsp;(예약대기)</span>
                    </v-btn>
                </v-btn-toggle>
                <div v-if="schedule.holidays.length" class="txt accent2--text mt-14px mt-lg-16px">
                    매주 {{ holidays }} 휴무
                </div>
                <div class="txt txt--sm line-height-2">
                    <div>영업시간 : {{ schedule?.open?.startsAt }} ~ {{ schedule?.open?.endsAt }}</div>
                    <div v-if="schedule.breaks.length">휴게시간 : {{ breaks }}</div>
                </div>
            </v-col>
        </v-row>

        <div class="btn-wrap d-flex justify-space-between">
            <v-btn v-bind="{ ...btn_secondary }" class="v-btn--width-fixed" @click="$router.go(-1)">이전</v-btn>
            <v-btn v-bind="{ ...btn_primary }" class="v-btn--width-fixed" :disabled="!isValid" @click="$emit('next')">다음</v-btn>
        </div>
    </v-container>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import MiniDatePicker from "@/components/client/miniShop/mini-date-picker.vue";
import { btn_primary, btn_secondary, initSchedule, initReservation, RESERVATION_STATUS } from "@/assets/variables";
dayjs.locale("ko");

export default {
    components: {
        MiniShopPage,

        TabPrimary,
        MiniDatePicker,
    },
    data: () => ({
        dayjs,
        btn_primary,
        btn_secondary,
        loading: false,

        form: initReservation(),
        timeSelect: null,
        date: null,
        schedule: null,
        reservations: [],
        day: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
    }),
    computed: {
        _user() {
            return this.$route.params._user;
        },
        holidays() {
            let sunday = this.schedule.holidays.indexOf("0");
            if(sunday !== -1) {
                this.schedule.holidays.splice(sunday, 1);
                this.schedule.holidays.sort((a, b) => parseInt(a) - parseInt(b));
                this.schedule.holidays.push("0");
                return this.schedule.holidays.map(day => this.day[day]).join(", ");
            } else{
                return this.schedule.holidays.sort((a, b) => parseInt(a) - parseInt(b)).map(day => this.day[day]).join(", ");
            }
        },
        breaks() {
            return this.schedule.breaks.map(time => `${time.startsAt} ~ ${time.endsAt}`).join("/ ")
        },
        isValid(){
            return this.form.information.date && this.timeSelect;
        },
        // _service(){
        //     return this.$route.params._service;
        // },
        schedulesWithReservation(){
            return this.schedule.reservationTime.map((item) => {
                let reservation = this.reservations.find(({ information, reservationStatus }) => {
                    return information.startsAt === item.startsAt 
                    && information.endsAt === item.endsAt
                    && reservationStatus !== RESERVATION_STATUS.RESERVATION_CANCELED.value;;
                });
                return { ...item, reservation };
            });
        },
    },
    methods: {
        init(){
            this.date = this.$dayjs().format("YYYY-MM-DD");
            this.emit();
            this.searchSchedule();
            this.searchReservations();
        },
        async searchReservations() {
            try {
                if(this.loading) return;
                else this.loading = true;

                let { reservations } = await api.v1.shop.reservations.gets({ 
                    params: { date: this.date, _seller: this._user }
                });
                
                this.reservations = reservations;
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            } 
        },
        async searchSchedule(){
            try {
                if(this.loading) return;
                else this.loading = true;

                let schedule = (await api.v1.shop.schedules.gets({ params: { _user: this._user }}))?.schedules?.[0] || {};

                this.schedule = initSchedule(schedule);
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        isReservationAwaiting(item){
            if(item?.reservation?.reservationStatus === RESERVATION_STATUS.RESERVATION_AWAITING.value) return true;
            else return false;
        },
        change(date){
            this.date = date;

            this.form = initReservation({ 
                ...this.form, 
                information: {...this.form.information, date} 
            });
        },
        emit(){
            this.$emit("input", this.form);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        timeSelect(){
            this.form = initReservation({ 
                ...this.form, 
                information: {...this.form.information, ...this.timeSelect} 
            });
        },
        form(){
            this.emit();
        },
        date(){
            this.searchReservations();
            this.timeSelect = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    flex-wrap: wrap;
    .v-btn {
        width: 100%;
        border-radius: 0 !important;
        border-bottom-width: 0 !important;
        border-left-width: 1px !important;

        background-color: #fff !important;
        color: var(--v-primary-base);
        &:last-child {
            border-bottom-width: 1px !important;
        }
        &--active {
            background-color: var(--v-secondary-base) !important;
        }
    }

    .v-btn--disabled.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: var(--v-grey-lighten5) !important;
        color: var(--v-grey-base) !important;
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--border-color) !important;
}
</style>