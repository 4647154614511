<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }" />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot />
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    props:{
        disableScrollTop: { type: Boolean, default: false },
    },
    components: {
        MainFooter,
        MainHeader,
    },
};
</script>

<style lang="scss" scoped>
</style>
