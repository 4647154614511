import { render, staticRenderFns } from "./mini-schedule-settings.vue?vue&type=template&id=dcd5adb0&scoped=true&v-if=value&"
import script from "./mini-schedule-settings.vue?vue&type=script&lang=js&"
export * from "./mini-schedule-settings.vue?vue&type=script&lang=js&"
import style0 from "./mini-schedule-settings.vue?vue&type=style&index=0&id=dcd5adb0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcd5adb0",
  null
  
)

export default component.exports