var render = function render(){
  var _vm$value, _vm$value$banner, _vm$value2, _vm$value2$mini;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('section', {
    staticClass: "mini-shop-visual"
  }, [_vm.value.banner ? _c('v-img', {
    staticClass: "mini-shop-visual__bg",
    attrs: {
      "src": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$banner = _vm$value.banner) === null || _vm$value$banner === void 0 ? void 0 : _vm$value$banner.url
    }
  }, [_vm.value.mini.title ? _c('div', {
    staticClass: "overlay"
  }) : _vm._e()]) : _c('v-img', {
    staticClass: "mini-shop-visual__bg",
    attrs: {
      "src": "/images/minishop/skin-default.jpg"
    }
  }, [_vm.value.mini.title ? _c('div', {
    staticClass: "overlay"
  }) : _vm._e()]), _c('v-container', {
    staticClass: "container--lg"
  }, [_c('h2', {
    staticClass: "tit white--text"
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$mini = _vm$value2.mini) === null || _vm$value2$mini === void 0 ? void 0 : _vm$value2$mini.title))]), _vm.isMe ? _c('mini-skin-edit', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small white",
          attrs: {
            "elevation": "4",
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("image")])], 1)];
      }
    }], null, false, 2360554017)
  }) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }