var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-tabs', {
    staticClass: "my-40px",
    attrs: {
      "show-arrows": false,
      "grow": ""
    }
  }, [_vm._l(_vm.items, function (item, index) {
    var _item$children;
    return [_c('tab-secondary', _vm._b({
      key: item.title,
      class: `nav-tab-${index}`
    }, 'tab-secondary', item, false), [_vm._v(" " + _vm._s(item.title) + " "), 0 < ((_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) || 0 ? _c('v-menu', {
      attrs: {
        "activator": `.nav-tab-${index}`,
        "open-on-hover": "",
        "bottom": "",
        "nudge-bottom": "62px",
        "close-on-click": false,
        "close-on-content-click": false
      }
    }, [_c('v-list', [_vm._l(item.children, function (child) {
      return [child.type == 'story-category' ? [_c('v-list-item', _vm._b({
        key: child.title,
        on: {
          "click": function ($event) {
            return _vm.$refs['manage-categories'].open();
          }
        }
      }, 'v-list-item', child, false), [_c('v-list-item-title', {
        staticClass: "text-center"
      }, [_c('v-icon', [_vm._v(" mdi-folder-plus-outline ")]), _vm._v(" " + _vm._s(child.title) + " ")], 1)], 1)] : [_c('v-list-item', _vm._b({
        key: child.title,
        class: {
          active: child.exact && child.active
        }
      }, 'v-list-item', child, false), [_c('v-list-item-title', {
        staticClass: "text-center"
      }, [_vm._v(" " + _vm._s(child.title) + " ")])], 1)]];
    })], 2)], 1) : _vm._e()], 1)];
  })], 2), _c('mini-manage-categories', _vm._b({
    ref: "manage-categories",
    on: {
      "input": function ($event) {
        return _vm.$emit('input');
      }
    }
  }, 'mini-manage-categories', {
    value: _vm.value
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }