<template>
    <story-page v-bind="{ isMobile }">
        <template v-if="readable">
            <template v-if="mainType == STORY_MAIN_TYPES.ARTICLE.value">
                <story-main-article v-bind="{ category }" />
            </template>
            <template v-if="mainType == STORY_MAIN_TYPES.DEFAULT.value">
                <story-main-default v-bind="{ category }" />
            </template>
            <template v-if="mainType == STORY_MAIN_TYPES.GALLERY.value">
                <story-main-gallery v-bind="{ category }" />
            </template>
            <template v-if="mainType == STORY_MAIN_TYPES.SUMMARY.value">
                <story-main-summary v-bind="{ category }" />
            </template>
        </template>
        <template v-else> 권한이 없습니다. </template>
    </story-page>
</template>

<script>
import detect from "@/plugins/detect";

import { mapGetters, mapState } from "vuex";
import { STORY_MAIN_TYPES } from "@/store/story";

import StoryPage from "@/components/client/templates/story-page.vue";
import StoryMainDefault from "@/components/client/story/main/story-main-default.vue";
import StoryMainGallery from "@/components/client/story/main/story-main-gallery.vue";
import StoryMainSummary from "@/components/client/story/main/story-main-summary.vue";
import StoryMainArticle from "@/components/client/story/main/story-main-article.vue";

export default {
    components: {
        StoryPage,
        StoryMainArticle,
        StoryMainDefault,
        StoryMainGallery,
        StoryMainSummary,
    },
    props: {
        code: { type: String },
    },
    setup: () => ({
        detect,
    }),
    data: () => ({
        STORY_MAIN_TYPES,
    }),
    computed: {
        ...mapState(["scope"]),
        ...mapGetters("story", ["getCategory"]),

        category() {
            return this.getCategory(this.code);
        },
        readable() {
            if ((this.category?.scope__main?.length || 0) == 0) return true;
            else return this.category?.scope__main?.some?.((item) => (this.scope || []).includes(item));
        },
        mainType() {
            return this.category?.mainType;
        },
        isMobile() {
            return this.detect == "mobile" && [
                STORY_MAIN_TYPES.DEFAULT.value
            ].includes(this.mainType);
        },
    },
};
</script>