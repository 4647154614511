var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('mini-shop-visual', _vm._b({
          on: {
            "input": _vm.init
          }
        }, 'mini-shop-visual', {
          value: _vm.user,
          isMe: _vm.isMe
        }, false))];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('mini-shop-profile', _vm._b({
    on: {
      "input": _vm.init
    }
  }, 'mini-shop-profile', {
    value: _vm.user,
    isMe: _vm.isMe
  }, false)), _c('mini-shop-nav', _vm._b({
    on: {
      "input": _vm.init
    }
  }, 'mini-shop-nav', {
    value: _vm.user,
    isMe: _vm.isMe
  }, false)), _vm._t("default", null, null, {
    user: _vm.user,
    isMe: _vm.isMe
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }