<template>
    <div class="map-sheet-actions-search">
        <v-slide-x-transition>
            <v-btn v-show="isMapRelocated" large color="secondary" elevation="6" :class="{ 'mb-40': isDesktop }" class="rounded-pill" @click="search">
                <v-icon class="font-size-22 mr-4px">mdi-cached</v-icon>
                <span class="font-weight-bold">현 위치에서 검색</span>
            </v-btn>
        </v-slide-x-transition>
    </div>
</template>

<script>
import detect from "@/plugins/detect";
export default {
    props: {
        lat: { type: Number, default: null },
        lng: { type: Number, default: null },
        lvl: { type: Number, default: null },
        queryLat: { type: Number, default: null },
        queryLng: { type: Number, default: null },
        queryLvl: { type: Number, default: null },
    },
    data: () => ({
        detect,
    }),
    computed: {
        isDesktop() {
            return detect == "pc";
        },
        isMapRelocated() {
            const latNotSame = this.queryLat != this.lat;
            const lngNotSame = this.queryLng != this.lng;
            const lvlNotSame = this.queryLvl != this.lvl;
            if (latNotSame || lngNotSame || lvlNotSame) return true;
            else return false;
        },
    },
    methods: {
        search() {
            this.$router.push({ query: { lat: this.lat, lng: this.lng, lvl: this.lvl } });
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sheet-actions-search {
    position: absolute;
    right: 50%;
    top: 78px;
    transform: translateX(50%) translateY(-150%);
    z-index: 2;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .map-sheet-actions-search {
        top: 100%;
    }
}
@media (min-width: 1200px) {
}
</style>
