<template>
    <mini-shop-page v-model="user" v-slot="{ isMe }">
        <story-list-card v-if="user" componentName="div" v-bind="{ query, sort, skip, limit }" v-slot="{ items, summary, loading }">
            <v-sheet class="mb-16px mb-20px">
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                        <story-sort />
                    </v-col>
                    <v-col cols="auto">
                        <common-filter hideSearchKeys />
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="tit-wrap tit-wrap--sm">
                <v-row class="row--xxs" align="center">
                    <v-col cols="12" md="auto" class="tit tit--xxs">{{ title }}</v-col>
                    <v-spacer />
                    <v-col cols="auto" class="txt txt--sm txt--dark">카테고리</v-col>
                    <v-col cols="auto">
                        <gallery-category :value="category.code" :items="categoryItems" :route="(code) => ({ query: { code } })" />
                    </v-col>
                </v-row>
            </div>

            <default-list v-bind="{ items }" />

            <v-sheet class="mt-4" v-if="isMe">
                <v-row no-gutters justify="space-between">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="$router.push(`${$route.path}/write`)">글쓰기</v-btn>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-pagination :value="page" :length="Math.ceil(summary?.totalCount / limit) || 1" :total-visible="11" color="primary" class="mt-4" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </story-list-card>
    </mini-shop-page>
</template>

<script>
import { mapGetters } from "vuex";
import { btn_primary } from "@/assets/variables";

import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";

import StoryListCard from "@/components/client/story/list/story-list-card.vue";
import StorySort from "@/components/client/miniShop/story/story-sort.vue";

import CommonFilter from "@/components/client/story/main/common/common-filter.vue";
import DefaultList from "@/components/client/story/main/default/default-list.vue";
import GalleryCategory from "@/components/client/story/main/gallery/gallery-category.vue";

export default {
    components: {
        MiniShopPage,

        StoryListCard,
        StorySort,

        CommonFilter,
        DefaultList,
        GalleryCategory,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        user: undefined,

        limit: 15,

        btn_primary,
    }),
    computed: {
        ...mapGetters("story", ["getCategory", "getCategoryWithId"]),

        categoryCode() {
            return this.$route.query.code || this.code;
        },
        category() {
            return this.getCategory(this.categoryCode);
        },
        parentCategory() {
            return this.getCategoryWithId(this.category._parent);
        },
        title() {
            switch (this.category?.children?.length < 1) {
                case true: {
                    if (this.parentCategory) {
                        return this.parentCategory.name;
                    }
                }
                default: {
                    return this.category.name;
                }
            }
        },
        categoryItems() {
            /** @type {object[]} */
            let items = [this.category];

            if (0 < this.category?.children?.length) {
                items = [...items, ...(this.category?.children || [])];
            } else {
                if (this.parentCategory) items = [this.parentCategory, ...this.parentCategory.children];
            }

            return items.reduce((items, item, index) => {
                if (item) {
                    items.push({
                        text: index == 0 ? "전체보기" : item.name,
                        value: item.code,
                    });
                }
                return items;
            }, []);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            // return this.$route.query.sort;
        },
        query() {
            let { ...query } = this.$route.query;

            delete query.page;

            query.code = JSON.stringify({ $in: this.category.codes});
            query._writer = this.user?._id;

            return { ...query };
        },
    },
};
</script>