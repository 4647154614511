<template>
  <mini-shop-page>
    <div class="btn-wrap d-flex justify-space-between">
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <service-date @next="next" @input="emit"/>
            </v-tab-item>
            <v-tab-item>
                <service-input v-bind="{ value: form }" @prev="prev" @input="save"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
    <nice-payments ref="nicePay" v-on="{ complete }" />
  </mini-shop-page>
</template>

<script>
import api from "@/api";

import { btn_primary, btn_secondary, initReservation, NICEPAY_PURCAHSE_TYPES } from "@/assets/variables";
import ServiceInput from "@/components/client/miniShop/request/service-input.vue";
import ServiceDate from "@/components/client/miniShop/request/service-date.vue";
import MiniShopPage from "@/components/client/templates/mini-shop-page.vue";
import NicePayments from "@/components/plugins/nice/nice-payments.vue";

export default {
    components: {
        MiniShopPage,
        ServiceInput,
        ServiceDate,
        NicePayments
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        tab: 0,
        form: initReservation(),
    }),
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    methods: {
        async save({form, service}){
            try{
                this.form = initReservation(form);
                if(!this.validates()) return;
                
                let { reservation } = await api.v1.shop.reservations.post({
                    ...this.form,
                    expireAt: this.$dayjs().add(30, "minute")
                });

                this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_SERVICES.value;
                this.$refs.nicePay.orderNo = reservation.orderNo;
                this.$refs.nicePay.orderName = service.name;
                this.$refs.nicePay.paymentMethod = "card";
                this.$refs.nicePay.paymentGateway = "";
                this.$refs.nicePay.paymentAmount = reservation.price;
                this.$refs.nicePay.buyerName = this.user.name;
                this.$refs.nicePay.buyerPhone = this.user.phone;
                this.$refs.nicePay.buyerEmail = this.user.email;
                this.$refs.nicePay.pay();
                // if(reservation) this.$router.push({ path: "/shop/order", query: { _reservation: reservation._id }});
            } catch(error){
                this.$handleError(error);
            }
        },
        emit (value){
            this.form = initReservation(value);
        },
        prev(){
            this.tab = 0
        },
        next(){
            this.tab = 1
        },
        validates() {
            if(!this.form.model) throw new Error("모델명을 입력해주세요.");
            if(!this.form.content) throw new Error("내용을 입력해주세요.");
            return true;
        },
        // 결제 연동용(삭제 예정)
        complete({ resCode, resMessage, _order}){
            alert(["0000", "3001"].includes(resCode)? "결제가 완료되었습니다." : resMessage);
            this.$router.push(`/minishop/${this.$route.params._user}/request/schedule`);
        },
    },
    mounted() {},
}
</script>

<style>

</style>