var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "width": "333",
      "rounded": "sm"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_c('v-text-field', _vm._b({
    staticClass: "rounded-sm",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "상품명, 브랜드를 검색하세요"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search(1);
      }
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-icon', {
          on: {
            "click": function ($event) {
              return _vm.$emit('pullTag');
            }
          }
        }, [_vm._v("mdi-close")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, 'v-text-field', {
    loading: _vm.loading
  }, false))], 1), _c('v-card-text', [_c('v-list', {
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "height": "286"
    }
  }, [_vm.products.length < 1 ? [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" 검색결과가 없습니다. ")])], 1)] : _vm._e(), _vm._l(_vm.products, function (product, index) {
    var _product$thumb, _product$seller;
    return [_c('v-list-item', {
      key: index,
      staticClass: "pl-0",
      staticStyle: {
        "border": "none"
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "border",
      attrs: {
        "size": "60",
        "rounded": "sm"
      }
    }, [_c('v-img', {
      attrs: {
        "src": (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.url
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-icon', [_vm._v("mdi-image-broken")])];
        },
        proxy: true
      }], null, true)
    })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : (_product$seller = product.seller) === null || _product$seller === void 0 ? void 0 : _product$seller.nickname) + " ")]), _c('v-list-item-title', [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : product.name) + " ")])], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "outlined": "",
        "x-small": "",
        "min-width": "58"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('input', product);
        }
      }
    }, [_vm._v("선택")])], 1)], 1)];
  }), _vm.page < _vm.pageCount ? [_c('v-sheet', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.searchOnScroll,
      expression: "searchOnScroll"
    }],
    staticClass: "pa-4 d-flex justify-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "50",
      "indeterminate": ""
    }
  })], 1)] : _vm._e()], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }