<template>
    <v-tabs hide-slider v-model="query['sort']" @change="emit">
        <tab-tertiary tab-value="date">최신순</tab-tertiary>
        <tab-tertiary tab-value="view">인기순</tab-tertiary>
    </v-tabs>
</template>

<script>
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";

const initQuery = (query = {}) => ({
    ...query,

    ["sort"]: query?.["sort"] || "date",
});

export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        query: initQuery(),
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;
            if (query["sort"] == "date") delete query["sort"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>