<template>
    <swiper class="rider-story-slide swiper" :options="swiperOptions">
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>
        <swiper-slide>
            <riders-story-card />
        </swiper-slide>

        <div class="swiper-control" slot="pagination">
            <div class="swiper-button-prev">
                <v-icon>mdi-chevron-up</v-icon>
            </div>
            <div class="swiper-button-next">
                <v-icon>mdi-chevron-down</v-icon>
            </div>
        </div>
    </swiper>
</template>

<script>
import RidersStoryCard from "@/components/client/sub/story/riders-story-card.vue";

export default {
    components: {
        RidersStoryCard,
    },
    data() {
        return {
            swiperOptions: {
                direction: "vertical",
                slidesPerView: "4",
                allowTouchMove: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.rider-story-slide {
    height: 660px;
    border-top: 2px solid var(--v-primary-base);
    padding-bottom: 48px;
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 48px;
        background-color: #fff;
        z-index: 2;
    }
    &::before {
        top: 0;
        border-bottom: 2px solid var(--v-primary-base);
        display: none;
    }
    &::after {
        bottom: 0;
        border-top: 2px solid var(--v-primary-base);
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 32px;
        height: 32px;
        background: var(--v-primary-base);
        border-radius: 50%;
        margin: 0;
        top: initial;
        bottom: 0;
        .v-icon {
            color: #fff;
        }
        &::after {
            display: none;
        }
    }
    .swiper-button-prev {
        left: initial;
        right: 48px;
    }
}
@media (min-width: 1024px) {
    .rider-story-slide {
        height: 620px;
        padding: 48px 0;
        border: none;
        &::before {
            display: block;
        }
        .swiper-button-prev,
        .swiper-button-next {
            left: 50%;
            transform: translateX(-50%);
        }
        .swiper-button-prev {
            top: 0;
            bottom: initial;
        }
        .swiper-button-next {
            top: initial;
            bottom: 0;
        }
    }
}
</style>
