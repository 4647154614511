var render = function render(){
  var _vm$shop, _vm$shop2, _vm$shop3, _vm$shop4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('store-profile', {
    attrs: {
      "hot": "",
      "value": _vm.shop
    },
    scopedSlots: _vm._u([{
      key: "avatar",
      fn: function () {
        return [_vm.shop.thumb ? _c('v-img', {
          attrs: {
            "src": _vm.shop.thumb.src
          }
        }) : _c('v-img', {
          attrs: {
            "src": "/images/test-img.jpg"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [(_vm$shop = _vm.shop) !== null && _vm$shop !== void 0 && _vm$shop._id ? _c('hot-story', {
    attrs: {
      "query": {
        _writer: (_vm$shop2 = _vm.shop) === null || _vm$shop2 === void 0 ? void 0 : _vm$shop2._id
      }
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [(_vm$shop3 = _vm.shop) !== null && _vm$shop3 !== void 0 && _vm$shop3._id ? _c('hot-best', {
    attrs: {
      "query": {
        _seller: (_vm$shop4 = _vm.shop) === null || _vm$shop4 === void 0 ? void 0 : _vm$shop4._id
      }
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("이달의 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("Hot")]), _vm._v(" 협력사")])]);

}]

export { render, staticRenderFns }