var render = function render(){
  var _vm$user, _vm$user2, _vm$user3, _vm$user4, _vm$user5, _vm$user6, _vm$user7, _vm$mini;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mini-shop-page', {
    on: {
      "input": _vm.save
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }, [((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.type) === _vm.USER_TYPES.COMPANY.value ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-bold"
  }, [_vm._v("BEST")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "to": `./${(_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2._id}/best`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), (_vm$user3 = _vm.user) !== null && _vm$user3 !== void 0 && _vm$user3._id ? _c('best-list-card', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [[_c('shop-product-items', _vm._l(products, function (product, index) {
          return _c('shop-product-item', {
            key: index,
            attrs: {
              "value": product,
              "sm": "6",
              "md": "6",
              "lg": "6",
              "rank": index + 1
            }
          });
        }), 1)]];
      }
    }], null, false, 1830621266)
  }, 'best-list-card', _vm.attrs__bestListCard, false)) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-bold"
  }, [_vm._v("Request")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "to": {
        path: `./${(_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4._id}/request/service`,
        query: {
          type: _vm.COMPANY_CATEGORY_TYPES.ELC_BICYCLE.value,
          page: 1
        }
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), (_vm$user5 = _vm.user) !== null && _vm$user5 !== void 0 && _vm$user5._id ? _c('request-list-card', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var services = _ref2.services;
        return [_c('v-row', {
          staticClass: "row--sm"
        }, _vm._l(services, function (service, index) {
          return _c('v-col', {
            key: index,
            attrs: {
              "cols": "6"
            }
          }, [[_c('mini-request-card', {
            attrs: {
              "to": `./${_vm.user._id}/request/service/${service._id}`,
              "value": service
            }
          })]], 2);
        }), 1)];
      }
    }], null, false, 4268421348)
  }, 'request-list-card', _vm.attrs__requestListCard, false)) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "my-40px my-lg-60px"
  })], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-bold"
  }, [_vm._v("일상")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "to": `${_vm.$route.path}/story/daily`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), (_vm$user6 = _vm.user) !== null && _vm$user6 !== void 0 && _vm$user6._id ? _c('story-list-card', _vm._b({
    staticClass: "row--sm",
    attrs: {
      "tag": "v-row"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var boards = _ref3.boards;
        return [_vm._l(boards, function (board) {
          return _c('v-col', {
            key: board._id,
            attrs: {
              "cols": "6",
              "lg": "3"
            }
          }, [_c('mini-daily-card', _vm._b({}, 'mini-daily-card', {
            board
          }, false))], 1);
        })];
      }
    }], null, false, 1878195305)
  }, 'story-list-card', _vm.attrs__storyListCard, false)) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "my-40px my-lg-60px"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-bold"
  }, [_vm._v("라이더 이야기")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "to": `${_vm.$route.path}/story/rider`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), (_vm$user7 = _vm.user) !== null && _vm$user7 !== void 0 && _vm$user7._id ? _c('story-list-card', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit rounded-0",
    attrs: {
      "tag": "v-simple-table"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var items = _ref4.items;
        return [_c('tbody', [items.length < 1 ? [_c('tr', [_c('td', [_vm._v("등록된 게시글이 없습니다.")])])] : _vm._l(items, function (item) {
          return _c('tr', {
            key: item._id,
            staticClass: "cursor-pointer",
            on: {
              "click": function ($event) {
                return _vm.$router.push(`${_vm.$route.path}/story/rider/${item._id}`);
              }
            }
          }, [_c('th', {
            staticClass: "w-md-20 w-xl-10 text-center"
          }, [_vm._v("[" + _vm._s(item.categoryText) + "]")]), _c('td', {
            staticClass: "w-xl-80 text-truncate text-center text-md-start"
          }, [_vm._v(_vm._s(item.subject))]), _c('td', {
            staticClass: "w-md-20 w-xl-10 text-center"
          }, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YY.MM.DD")))])]);
        })], 2)];
      }
    }], null, false, 418948791)
  }, 'story-list-card', _vm.attrs__riderListCard, false)) : _vm._e()], 1)], 1), (_vm$mini = _vm.mini) !== null && _vm$mini !== void 0 && _vm$mini.storyCodes.length ? _c('div', _vm._l(_vm.mini.storyCodes, function (code, index) {
    var _vm$user8;
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "tit-wrap tit-wrap--xs"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": "",
        "justify": "space-between",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h3', {
      staticClass: "txt txt--dark font-weight-bold"
    }, [_vm._v(_vm._s(code === null || code === void 0 ? void 0 : code.name))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', _vm._b({
      attrs: {
        "x-small": "",
        "to": `${_vm.$route.path}/story/${code.path}`
      }
    }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), (_vm$user8 = _vm.user) !== null && _vm$user8 !== void 0 && _vm$user8._id ? _c('story-list-card', _vm._b({
      staticClass: "row--sm",
      attrs: {
        "tag": "v-row"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref5) {
          var boards = _ref5.boards;
          return [_vm._l(boards, function (board) {
            return _c('v-col', {
              key: board._id,
              attrs: {
                "cols": "6",
                "lg": "3"
              }
            }, [_c('mini-daily-card', _vm._b({}, 'mini-daily-card', {
              board
            }, false))], 1);
          })];
        }
      }], null, true)
    }, 'story-list-card', _vm.additionalStoryListCard(code), false)) : _vm._e()], 1)], 1);
  }), 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }