var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "height": "auto",
      "hide-slider": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.query.sort,
      callback: function ($$v) {
        _vm.$set(_vm.query, "sort", $$v);
      },
      expression: "query.sort"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('tab-tertiary', _vm._b({
      key: item.text
    }, 'tab-tertiary', item, false), [_vm._v(" " + _vm._s(item.title) + " ")])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }