<template>
    <client-page>
        <div id="contents">
            <v-container>
                <div class="ui-components-wrap">
                    <h3>{breakpoint} : sm<small>(min-width:576px)</small>, md<small>(min-width:768px)</small>, lg<small>(min-width:1024px)</small>, xl<small>(min-width:1200px)</small></h3>
                    <h3>{size} : 2px씩 늘어납니다</h3>

                    <v-divider class="my-20px"></v-divider>
                    <!-- Colors -->
                    <h1 class="secondary--text">Colors</h1>
                    <br />
                    <v-row>
                        <v-col cols="6" md="4" lg="2">
                            <div class="pa-10px primary white--text">primary</div>
                            <div class="pa-10px primary lighten-5">ligthen-5</div>
                            <div class="pa-10px primary lighten-4">ligthen-4</div>
                            <div class="pa-10px primary lighten-3">ligthen-3</div>
                            <div class="pa-10px primary lighten-2 white--text">ligthen-2</div>
                            <div class="pa-10px primary lighten-1 white--text">ligthen-1</div>
                        </v-col>
                        <v-col cols="6" md="4" lg="2">
                            <div class="pa-10px secondary">secondary</div>
                            <div class="pa-10px secondary lighten-5">lighten-5</div>
                            <div class="pa-10px secondary lighten-4">lighten-4</div>
                            <div class="pa-10px secondary lighten-3">lighten-3</div>
                            <div class="pa-10px secondary lighten-2">lighten-2</div>
                            <div class="pa-10px secondary lighten-1">lighten-1</div>
                            <div class="pa-10px secondary darken-1">darken-1</div>
                            <div class="pa-10px secondary darken-2">darken-2</div>
                            <div class="pa-10px secondary darken-3">darken-3</div>
                            <div class="pa-10px secondary darken-4">darken-4</div>
                        </v-col>
                        <v-col cols="6" md="4" lg="2">
                            <div class="pa-10px accent1">accent1</div>
                            <div class="pa-10px accent2">accent2</div>
                        </v-col>
                        <v-col cols="6" md="4" lg="2">
                            <div class="pa-10px success">success</div>
                            <div class="pa-10px warning">warning</div>
                            <div class="pa-10px error">error</div>
                            <div class="pa-10px info">info</div>
                        </v-col>
                        <v-col cols="6" md="4" lg="2">
                            <div class="pa-10px grey">grey</div>
                            <div class="pa-10px grey lighten-5">lighten-5</div>
                            <div class="pa-10px grey lighten-4">lighten-4</div>
                            <div class="pa-10px grey lighten-3">lighten-3</div>
                            <div class="pa-10px grey lighten-2">lighten-2</div>
                            <div class="pa-10px grey lighten-1">lighten-1</div>
                            <div class="pa-10px grey darken-1 white--text">darken-1</div>
                            <div class="pa-10px grey darken-2 white--text">darken-2</div>
                            <div class="pa-10px grey darken-3 white--text">darken-3</div>
                            <div class="pa-10px grey darken-4 white--text">darken-4</div>
                        </v-col>
                    </v-row>
                    <v-divider class="my-20px"></v-divider>

                    <!-- Typography -->
                    <h1 class="secondary--text">Typography</h1>
                    <br />
                    <!-- S: Font Family -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Font Family</h2>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <p class="txt txt--lg txt--dark">한글 폰트</p>
                                </v-col>
                                <v-col cols="12">
                                    <p class="txt txt--lg txt--dark font-secondary">English Font (class="font-secondary")</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Font Family -->

                    <!-- S: Tit Wrap -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Tit Wrap</h2>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <div class="tit-wrap tit-wrap--xs">
                                        <h3 class="tit">tit-wrap--xs</h3>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="tit-wrap tit-wrap--sm">
                                        <h3 class="tit">tit-wrap--sm</h3>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="tit-wrap">
                                        <h3 class="tit">tit-wrap default</h3>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="tit-wrap tit-wrap--lg">
                                        <h3 class="tit">tit-wrap--lg</h3>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Tit Wrap -->

                    <!-- S: Tit -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Tit</h2>
                            <h3 class="tit tit--xxs">tit--xxs</h3>
                            <h3 class="tit tit--xs">tit--xs</h3>
                            <h3 class="tit tit--sm">tit--sm</h3>
                            <h3 class="tit">tit Default</h3>
                            <h3 class="tit tit--lg">tit--lg</h3>
                            <h3 class="tit tit--xl">tit--xl</h3>
                        </v-col>
                    </v-row>
                    <!-- E: Tit -->

                    <!-- S: Font Color -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Txt Color</h2>
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <p class="txt txt--lg">txt Default</p>
                                </v-col>
                                <v-col cols="auto">
                                    <p class="txt txt--lg txt--dark">txt--Dark</p>
                                </v-col>
                                <v-col cols="auto">
                                    <p class="txt txt--lg txt--light primary">txt--light</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Font Color -->
                    <!-- // Typography -->

                    <v-divider class="my-20px"></v-divider>

                    <!-- Button -->
                    <h1 class="secondary--text">Button</h1>

                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_primary }">btn_primary</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_secondary }">btn_secondary</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_tertiary }">btn_tertiary</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-bind="{ ...btn_quaternary }">btn_quaternary</v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Button Height</h2>
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-btn class="v-size--xx-small" v-bind="{ ...btn_primary }">xx-small</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small v-bind="{ ...btn_primary }">x-small</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn small v-bind="{ ...btn_primary }">small</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary }">Defalut</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn large v-bind="{ ...btn_primary }">large</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-large v-bind="{ ...btn_primary }">x-large</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn class="v-size--xx-large" v-bind="{ ...btn_primary }">xx-large</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Button Width Fixed</h2>
                            <p class="txt txt--lg">class="v-btn--width-fixed"</p>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <v-btn class="v-size--xx-small v-btn--width-fixed" v-bind="{ ...btn_primary }">xx-small</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn x-small class="v-btn--width-fixed" v-bind="{ ...btn_primary }">x-small</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn small class="v-btn--width-fixed" v-bind="{ ...btn_primary }">small</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_primary }">Defalut</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn large class="v-btn--width-fixed" v-bind="{ ...btn_primary }">large</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn x-large class="v-btn--width-fixed" v-bind="{ ...btn_primary }">x-large</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn class="v-size--xx-large v-btn--width-fixed" v-bind="{ ...btn_primary }">xx-large</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Button Txt</h2>
                            <p class="txt txt--lg">v-bind="{ ...btn_txt }"</p>
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" class="v-size--xx-small" to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" x-small to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" small to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" large to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" x-large to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" class="v-size--xx-large" to="/">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h2 class="grey--text">Button Icon</h2>
                            <p class="txt txt--lg">v-bind="{ ...btn_icon }"</p>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    Image Icon
                                    <v-btn v-bind="{ ...btn_icon }">
                                        <v-img max-width="20" src="/images/icon/icon-home.svg" />
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    v-icon
                                    <v-btn v-bind="{ ...btn_icon }">
                                        <v-icon>home</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">
                                            <v-btn class="v-size--xx-small" v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn x-small v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn small v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn large v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn x-large v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn class="v-size--xx-large" v-bind="{ ...btn_icon }">
                                                <v-icon>home</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- // Button -->

                    <v-divider class="my-20px"></v-divider>

                    <!-- v-chip -->
                    <h1 class="secondary--text">v-chip</h1>
                    <v-row>
                        <v-col>
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-chip v-bind="{ ...chip_primary }">chip_primary</v-chip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-chip v-bind="{ ...chip_primary2 }">chip_primary2</v-chip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-chip v-bind="{ ...chip_secondary }">chip_secondary</v-chip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-chip v-bind="{ ...chip_secondary2 }">chip_secondary2</v-chip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-chip v-bind="{ ...chip_secondary3 }">chip_secondary3</v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-divider class="my-20px"></v-divider>

                    <!-- Input -->
                    <h1 class="secondary--text">Input</h1>

                    <!-- S: Text Field -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">v-text-field</h2>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <v-text-field v-bind="{ ...attrs_input, loading }" label="attrs_input" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-bind="{ ...attrs_input_secondary, loading }" label="attrs_input_secondary" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Text Field -->

                    <!-- S: Textarea -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">v-textarea</h2>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <v-textarea v-bind="{ ...attrs_input, loading }" placeholder="attrs_input" />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-bind="{ ...attrs_input_secondary, loading }" placeholder="attrs_input_secondary" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Textarea -->

                    <!-- S: Select -->
                    <v-row>
                        <v-col>
                            <h2 class="grey--text">v-select</h2>
                            <v-row class="row--xxs">
                                <v-col cols="12">
                                    <v-select v-bind="{ ...attrs_input }" label="attrs_input" />
                                </v-col>
                                <v-col cols="12">
                                    <v-select v-bind="{ ...attrs_input_secondary }" label="attrs_input_secondary" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- E: Select -->

                    <!-- // Input -->

                    <v-divider class="my-20px" />

                    <!-- Radio -->
                    <h1 class="secondary--text">v-radio</h1>
                    <v-row>
                        <v-col>
                            <v-radio-group row>
                                <v-radio v-bind="{ ...radio_primary }" label="Radio Primary" value="a" />
                                <v-radio v-bind="{ ...radio_primary }" label="Radio Primary" value="b" />
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-divider class="my-20px" />

                    <!-- Checkbox -->
                    <h1 class="secondary--text">v-checkbox</h1>
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox v-bind="{ ...checkbox_primary }" label="checkbox_primary" value input-value="true"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-divider class="my-20px" />

                    <!-- Rating -->
                    <h1 class="secondary--text">v-rating</h1>
                    <v-rating v-bind="{ ...attrs_rating }" />

                    <v-divider class="my-20px" />

                    <!-- Switch -->
                    <h1 class="secondary--text">v-switch</h1>
                    <v-row>
                        <v-col cols="12">
                            <v-switch v-bind="{ ...attrs_switch }" label="attrs_switch" />
                        </v-col>
                    </v-row>

                    <v-divider class="my-20px" />

                    <!-- Tab -->
                    <h1 class="secondary--text">Tab</h1>
                    <br />
                    <v-row>
                        <v-col cols="12">
                            <!-- S: tab-primary -->
                            <v-tabs grow class="mb-40px mb-lg-60px">
                                <tab-primary>Tab Primary</tab-primary>
                                <tab-primary>Tab Primary</tab-primary>
                            </v-tabs>
                            <!-- E: tab-primary -->
                        </v-col>
                        <v-col cols="12">
                            <!-- S: tab-secondary -->
                            <v-tabs grow>
                                <tab-secondary>Tab Secondary</tab-secondary>
                                <tab-secondary>Tab Secondary</tab-secondary>
                                <tab-secondary>Tab Secondary</tab-secondary>
                                <tab-secondary>Tab Secondary</tab-secondary>
                            </v-tabs>
                            <!-- E: tab-secondary -->
                        </v-col>
                        <v-col cols="12">
                            <!-- S: tab-tertiary -->
                            <v-tabs hide-slider>
                                <tab-tertiary>Tab Tertiary</tab-tertiary>
                                <tab-tertiary>Tab Tertiary</tab-tertiary>
                                <tab-tertiary>Tab Tertiary</tab-tertiary>
                                <tab-tertiary>Tab Tertiary</tab-tertiary>
                            </v-tabs>
                            <!-- E: tab-tertiary -->
                        </v-col>
                    </v-row>
                    <!-- // Tab -->

                    <v-divider class="my-20px"></v-divider>
                    <div class="tit-wrap tit-wrap--lg">
                        <h1 class="secondary--text">SNB</h1>
                    </div>
                    <snb />

                    <v-divider class="my-20px"></v-divider>
                </div>
            </v-container>

            <v-container>
                <h1 class="secondary--text">Sub Visual</h1>
                <p class="txt txt--lg">client-page &gt; template#subHead</p>
            </v-container>

            <sub-visual-primary tit="sub-visual-priamry" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>

            <sub-visual-secondary tit="sub-visual-secondary" bg="/images/mypage/visual.jpg" mobileBg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-secondary>

            <sub-visual-secondary tit="sub-visual-secondary (tabs)" bg="/images/mypage/visual.jpg" mobileBg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
                <template #tab>
                    <v-tabs grow>
                        <tab-primary>TAB1</tab-primary>
                        <tab-primary>TAB2</tab-primary>
                        <tab-primary>TAB3</tab-primary>
                        <tab-primary>TAB4</tab-primary>
                        <tab-primary>TAB5</tab-primary>
                    </v-tabs>
                </template>
            </sub-visual-secondary>

            <sub-visual-tertiary tit="sub-visual-tertiary" bg="/images/mypage/visual.jpg" mobileBg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-tertiary>

            <v-container class="container--lg">
                <v-sheet height="200"></v-sheet>
            </v-container>

            <v-container><v-divider class="my-20px"></v-divider></v-container>

            <page-section>
                <template #tit>page-section</template>
                <v-sheet height="100px" color="secondary"></v-sheet>
            </page-section>

            <page-section containerSize="container--lg">
                <template #tit>page-section(containerSize="container--lg")</template>
                <v-sheet height="100px" color="secondary"></v-sheet>
            </page-section>

            <page-section-wide>
                <template #tit>page-section-wide</template>
                <v-sheet height="100px" color="secondary"></v-sheet>
            </page-section-wide>
        </div>
    </client-page>
</template>

<script>
import clientPage from "@/components/client/templates/client-page.vue";
import { attrs_input, attrs_input_secondary, btn_primary, btn_secondary, btn_tertiary, btn_quaternary, btn_icon, btn_txt, checkbox_primary, radio_primary, chip_primary, chip_primary2, chip_secondary, chip_secondary2, chip_secondary3, attrs_switch, attrs_rating } from "@/assets/variables";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import TabSecondary from "@/components/client/tab/tab-secondary.vue";
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";
import Snb from "@/components/client/list/snb.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import PageSectionWide from "@/components/client/templates/page-section-wide.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import SubVisualSecondary from "@/components/client/sub/sub-visual-secondary.vue";
import SubVisualTertiary from "@/components/client/sub/sub-visual-tertiary.vue";

export default {
    components: {
        clientPage,
        TabPrimary,
        TabSecondary,
        TabTertiary,
        Snb,
        PageSection,
        PageSectionWide,
        SubVisualPrimary,
        SubVisualSecondary,
        SubVisualTertiary,
    },
    props: {},
    mounted() {},
    methods: {},
    data: () => ({
        attrs_input,
        attrs_input_secondary,
        btn_primary,
        btn_secondary,
        btn_tertiary,
        btn_quaternary,
        btn_icon,
        btn_txt,
        checkbox_primary,
        radio_primary,
        chip_primary,
        chip_primary2,
        chip_secondary,
        chip_secondary2,
        chip_secondary3,
        attrs_switch,
        attrs_rating,
    }),
    computed: {
        // sub-visual sub-head path
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                { text: "HOME" },
                // { text: "Guide", to: { path: "/gudie" } },
                { text: "Guide" },
            ];
            if (this.activeItem) {
                items.push({
                    text: this.activeItem?.title,
                    to: this.activeItem?.to,
                });
            }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
