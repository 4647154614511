<template v-if="value">
    <dialog-basic :value="showsDialog" @input="(shows) => (this.showsDialog = shows)">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">운영시간 설정</v-card-title>
        </div>

        <ul class="schedule-settings-list">
            <!-- S: 영업시간 -->
            <li>
                <v-divider dark style="border-width: 0 0 2px" />
                <v-row align="center" class="my-0 row--xxs txt txt--sm">
                    <v-col cols="12" lg="2" class="txt--dark text-lg-center font-weight-medium">영업시간</v-col>

                    <v-col cols="12" lg="10">
                        <v-row align="center" class="row--xxs">
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="value.open.startsAt"/>
                            </v-col>
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="value.open.endsAt"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider />
            </li>
            <!-- E: 영업시간 -->

            <!-- S: 예약시간 -->
            <li>
                <v-divider dark style="border-width: 0 0 2px" />
                <v-row align="center" class="my-0 row--xxs txt txt--sm">
                    <v-col cols="12" lg="2" class="txt--dark text-lg-center font-weight-medium">예약시간</v-col>

                    <v-col cols="12" lg="10">
                        <!-- 추가/삭제 -->
                        <v-row v-for="(item, index) in value.reservationTime" :key="index" align="center" class="row--xxs">
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="item.startsAt"/>
                            </v-col>
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="item.endsAt"/>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-btn v-if="value.reservationTime.length > 1" v-bind="{ ...btn_icon }" @click="remove({index, type: 'reservationTime'})">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider />
                <v-btn v-bind="{ ...btn_txt }" x-small class="mt-12px" @click="add('reservationTime')">+ 예약시간 추가</v-btn>
            </li>
            <!-- E: 예약시간 -->

            <!-- S: 휴게시간 -->
            <li>
                <v-divider dark style="border-width: 0 0 2px" />
                <v-row align="center" class="my-0 row--xxs txt txt--sm">
                    <v-col cols="12" lg="2" class="txt--dark text-lg-center font-weight-medium">휴게시간</v-col>

                    <v-col v-if="value.breaks.length"  cols="12" lg="10">
                        <!-- 추가/삭제 -->
                        <v-row v-for="(item, index) in value.breaks" :key="index" align="center" class="row--xxs">
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="item.startsAt"/>
                            </v-col>
                            <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                            <v-col cols="10" lg="auto">
                                <v-time-field v-model="item.endsAt"/>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-btn v-bind="{ ...btn_icon }" @click="remove({index, type: 'breaks'})">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider />
                <v-btn v-bind="{ ...btn_txt }" x-small class="mt-12px" @click="add('breaks')">+ 휴게시간 추가</v-btn>
            </li>
            <!-- E: 휴게시간 -->

            <!-- S: 정기 휴무일 -->
            <li>
                <v-divider dark style="border-width: 0 0 2px" />
                <v-row align="center" class="my-0 row--xxs txt txt--sm">
                    <v-col cols="12" lg="2" class="txt--dark text-lg-center font-weight-medium">정기 휴무일</v-col>

                    <v-col cols="12" lg="10">
                        <v-btn-toggle v-model="value.holidays" multiple class="select-week-day">
                            <v-btn v-for="(holiday, index) in holidays" :key=index :value="holiday.value">{{ holiday.text }}</v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-divider />
            </li>
            <!-- E: 정기 휴무일 -->
        </ul>

        <v-card-actions class="btn-wrap px-0 pb-0">
            <v-row justify="center" class="row--xxs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </dialog-basic>
</template>

<script>
import api from "@/api";
import VTimeField from "@/components/plugins/vuetify/v-time-field.vue";
import { attrs_input, btn_icon, btn_txt, btn_primary, btn_secondary, initSchedule, initScheduleTime } from "@/assets/variables";

import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

export default {
    components: {
        DialogBasic,
        VTimeField
    },
    props: {
        user: { type: Object, default: () => ({}) },
        value: { type: Object, default: () => ({}) }, //form
    },
    data: () => ({
        attrs_input,
        btn_icon,
        btn_txt,
        btn_primary,
        btn_secondary,

        loading: false,
        showsDialog: false,
    }),
    computed:{
        holidays(){
            return [
                { text: "일", value: "0" },
                { text: "월", value: "1" },
                { text: "화", value: "2" },
                { text: "수", value: "3" },
                { text: "목", value: "4" },
                { text: "금", value: "5" },
                { text: "토", value: "6" },
            ]
        },
    },
    methods: {
        async init(){
            this.sync();
        },
        async save(){
            if(this.loading) return
            else this.loading = true;
            try{
                let { put, post } = api.v1.shop.schedules;
                let { schedule } = await (this.value._id ? put(this.value) : post(this.value));

                this.$emit("input", schedule);
                this.loading = false;
                this.showsDialog = false;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        add(type) {
            let previousTime = initScheduleTime();

            // 추가할 때마다, 이전 시간 + 1시간
            if(this.value[type].length){
                previousTime = this.value[type][this.value[type].length - 1];
                previousTime = {
                    startsAt: this.afterAnHour(previousTime.startsAt),
                    endsAt: this.afterAnHour(previousTime.endsAt)
                };
            }

            this.value[type].push(initScheduleTime(previousTime));
        },
        remove({ index, type}){
            this.value[type].splice(index, 1);
        },
        afterAnHour(prev){
            let [hour, minute] = prev.split(":");
            hour = (+hour + 1) % 24;
            return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        },
        sync() {
            this.form = initSchedule(this.value);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        showsDialog() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.schedule-settings-list {
    > li {
        margin-bottom: 34px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.select-week-day {
    margin: 0 -8px;
    .v-btn {
        width: 36px !important;
        height: 36px !important;
        min-width: initial !important;
        border-radius: 50% !important;
        border: none !important;
        background-color: var(--v-grey-lighten2) !important;
        color: #fff;
        font-weight: bold;
        margin: 0 8px;
        padding: 0;
        &--active {
            background-color: var(--v-secondary-base) !important;
        }
    }
}
</style>