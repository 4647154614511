var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rider-story-card py-18px border-b border-dark",
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--sm my-0",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "62"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/test-img.jpg",
      "alt": ""
    }
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v("silentfox11")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "1",
      "order-lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--xs"
  }, [_vm._v("35분 전")])]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12"
    }
  }, [_c('div', [_c('v-icon', {
    staticClass: "material-icons-outlined mr-4px",
    attrs: {
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('span', {
    staticClass: "txt txt--xs font-weight-medium primary--text"
  }, [_vm._v("1,234")])], 1)])], 1)], 1), _c('v-col', {
    staticClass: "border-lg-l--dark border-lg-r--dark",
    attrs: {
      "cols": "12",
      "lg": "8",
      "order-lg": "2"
    }
  }, [_c('h4', {
    staticClass: "txt txt--dark font-weight-bold mb-4px mb-lg-6px text-truncate"
  }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--dark text-truncate"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }