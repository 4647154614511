var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('div', {
    attrs: {
      "id": "map"
    }
  }, [_c('actions-current'), _vm.showsSearch ? _c('actions-search', _vm._b({
    on: {
      "search": _vm.search
    }
  }, 'actions-search', {
    lat: _vm.lat,
    lng: _vm.lng,
    lvl: _vm.lvl,
    queryLat: _vm.queryLat,
    queryLng: _vm.queryLng,
    queryLvl: _vm.queryLvl
  }, false)) : _vm._e(), _c('map-sheet-kakaomap', _vm._b({
    on: {
      "search": _vm.search
    }
  }, 'map-sheet-kakaomap', {
    lat: _vm.lat,
    lng: _vm.lng,
    lvl: _vm.lvl,
    maxLevel: 8,
    locations: _vm.locations
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }