<template>
    <story-page>
        <v-sheet class="pb-40px pb-lg-60px border-b border-dark">
            <div class="tit-wrap tit-wrap--lg">
                <v-btn v-bind="{ ...btn_txt }" to="/">
                    <h2 class="tit tit--xxs">타보고서</h2>
                    <v-icon class="ml-4px">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <slides-list></slides-list>
        </v-sheet>

        <v-sheet class="py-40px py-lg-60px border-b border-dark">
            <div class="tit-wrap tit-wrap--lg">
                <v-btn v-bind="{ ...btn_txt }" to="/">
                    <h2 class="tit tit--xxs">가보고서</h2>
                    <v-icon class="ml-4px">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <slides-list></slides-list>
        </v-sheet>

        <v-sheet class="pt-40px pt-lg-60px">
            <div class="tit-wrap tit-wrap--sm">
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto"><h2 class="tit tit--xxs">News</h2></v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_txt }" to="/" x-small>
                            더보기
                            <v-icon class="ml-4px">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-row class="row--xxs">
                <template v-for="n in 4">
                    <v-col cols="12" md="6" lg="3" :key="n">
                        <board-item />
                    </v-col>
                </template>
            </v-row>
        </v-sheet>
    </story-page>
</template>

<script>
import { btn_txt } from "@/assets/variables";
import StoryPage from "@/components/client/templates/story-page.vue";

import BoardItem from "@/components/client/story/main/common/board/board-item.vue";
import SlidesList from "@/components/client/story/main/article/slides/slides-list.vue";

export default {
    components: {
        StoryPage,
        BoardItem,
        SlidesList,
    },
    props: {},
    data: () => ({
        btn_txt,
    }),
};
</script>

<style lang="scss" scoped>
</style>