<template>
    <v-sheet>
        <div class="tit-wrap tit-wrap--sm">
            <v-row no-gutters justify="space-between">
                <v-col cols="auto">
                    <h3 class="tit tit--xxs">{{ category.name }}</h3>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_txt }" :to="path">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
            </v-row>
        </div>
        <template v-if="boards.length < 1 && !loading">
            <span> 게시글이 없습니다 </span>
        </template>
        <v-row class="row--xxs" v-if="0 < boards.length">
            <template v-for="value in boards">
                <v-col cols="12" md="6" lg="3" :key="value._id">
                    <board-item v-bind="{ path, value }" />
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { btn_txt } from "@/assets/variables";

import BoardItem from "./board/board-item.vue";

export default {
    components: {
        BoardItem,
    },
    props: {
        category: { type: Object, default: () => ({}) },
        limit: { type: [String, Number], default: 4 },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        btn_txt,

        loading: false,
    }),
    computed: {
        path() {
            return `/story/${this.category.code}`;
        },
        sort() {
            return JSON.stringify({ isNotice: -1, createdAt: -1, _id: -1 });
        }
    },
    methods: {
        async search() {
            if (!this.category.code) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                const { boards, summary } = await api.v1.boards.gets({
                    headers: { limit: this.limit, sort: this.sort },
                    params: { code: JSON.stringify({ $in : [...this.category.codes] }) },
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        category() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style lang="scss" scoped>
.rider-story-slide {
    // position: relative;
    border-top: 2px solid var(--v-primary-base);
    border-bottom: 2px solid var(--v-primary-base);
    height: 615px;
    .swiper-slide {
        border-bottom: 1px solid var(--v-primary-base);
        &:first-child {
            border-top: 1px solid var(--v-primary-base);
        }
    }
}
@media (min-width: 1024px) {
    .rider-story-slide {
        height: 496px;
        .swiper-slide {
            border-bottom: 1px solid var(--v-primary-base);
            &:first-child {
                border-top: 1px solid var(--v-primary-base);
            }
        }
    }
}
</style>
