<template>
    <v-sheet>
        <div class="tit-wrap tit-wrap--sm">
            <v-row no-gutters justify="space-between">
                <v-col cols="auto">
                    <h3 class="tit tit--xxs">{{ category.name }}</h3>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_txt }" :to="`/story/${category.code}`">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
            </v-row>
        </div>
        <template v-if="0 < boards.length">
            <riders-list v-bind="{ boards }" />
        </template>
        <template v-else-if="!loading">
            <span>게시글이 없습니다</span>
        </template>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { btn_txt } from "@/assets/variables";
import { initStoryCategory } from "@/store/story";

import RidersList from "./riders/riders-list.vue";

export default {
    components: {
        RidersList,
    },
    props: {
        category: { type: Object, default: initStoryCategory },
        limit: { type: [String, Number], default: 20 },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        btn_txt,

        loading: false,
    }),
    methods: {
        async search() {
            if (!this.category.code) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                const { boards, summary } = await api.v1.boards.gets({
                    headers: { limit: this.limit },
                    params: { code: JSON.stringify({ $in : [...this.category.codes] }) },
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        category() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style lang="scss" scoped>
</style>
