<template>
    <client-page>
        <template #subHead>
            <sub-visual-tertiary tit="Service" bg="/images/sub/store/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-tertiary>
        </template>

        <page-section containerSize="container--lg" class="page-section--first">
            <store-category-nav />
        </page-section>

        <page-section containerSize="container--lg">
            <store-hot/>
        </page-section>
        
        <page-section containerSize="container--lg" class="page-section--last">
            <store-best/>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary, btn_secondary, btn_txt } from "@/assets/variables";

import StoreHot from "@/components/client/sub/store/store-hot.vue";
import StoreBest from "@/components/client/sub/store/store-best.vue";
import StoreCategoryNav from '@/components/client/sub/store/store-category-nav.vue';
import StoreProfile from "@/components/client/sub/store/store-profile.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubVisualTertiary from "@/components/client/sub/sub-visual-tertiary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';

export default {
    components: {
        ClientPage,
        PageSection,
        StoreHot,
        SubVisualTertiary,
        VisualBreadcrumbs,
        StoreBest,
        StoreCategoryNav,
        StoreProfile,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_txt,

        exhibitions: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                { text: "HOME", to: "/" },
                { text: "Store", to: "/sub/store" },
            ];
            if (this.activeItem) {
                items.push({
                    text: this.activeItem?.title, 
                    to: this.activeItem?.to,
                });
            }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;
        max-width: var(--container-lg);
        height: 1px;
        background: var(--border-color);
    }
    &--first {
        padding-top: 0;
        ::v-deep() {
            .container {
                padding-top: var(--page-section-padding-y);
                padding-left: 0;
                padding-right: 0;
                max-width: calc(100% - var(--container-gutter) * 2);
                background-color: #fff !important;
            }
        }
    }
    &--last {
        &::after {
            display: none;
        }
    }
}
@media (min-width: 1200px) {
    .page-section {
        &--first {
            ::v-deep() {
                .container {
                    &--lg {
                        max-width: var(--container-lg);
                    }
                }
            }
        }
    }
}
</style>
