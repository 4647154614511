var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('story-page', [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm d-flex"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": "/sub/story/bicycle/riding"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("#라이딩")]), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "xl": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v("작성자")])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit font-size-16 font-weight-medium text-truncate"
  }, [_vm._v("게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--xs text-truncate"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm d-flex"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": "/sub/story/bicycle/tuning"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("#튜닝관리")]), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "xl": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v("작성자")])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit font-size-16 font-weight-medium text-truncate"
  }, [_vm._v("게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--xs text-truncate"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm d-flex"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": "/sub/story/bicycle/review"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("#제품후기")]), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "xl": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v("작성자")])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit font-size-16 font-weight-medium text-truncate"
  }, [_vm._v("게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--xs text-truncate"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm d-flex"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "to": "/sub/story/bicycle/impromptu"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("#번개/모임")]), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "xl": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": "20"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs ml-6px"
  }, [_vm._v("작성자")])], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit font-size-16 font-weight-medium text-truncate"
  }, [_vm._v("게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다. 게시글 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--xs text-truncate"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("visibility")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "material-icons-outlined",
    attrs: {
      "small": ""
    }
  }, [_vm._v("thumb_up")]), _c('p', {
    staticClass: "txt txt--xs font-weight-medium ml-4px"
  }, [_vm._v("1,234")])], 1)])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-40px my-md-70px my-lg-90px",
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("소식/정보")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "pb-30px pb-xl-0",
    attrs: {
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('h4', {
    staticClass: "tit tit--xxs mt-12px mb-6px line-height-15 text-truncate"
  }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--dark text-truncate-2"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('h4', {
    staticClass: "tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate"
  }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--dark text-truncate-2"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('h4', {
    staticClass: "tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate"
  }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--dark text-truncate-2"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/test-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('h4', {
    staticClass: "tit tit--xxs mt-8px mt-lg-12px mb-4px mb-lg-6px line-height-15 text-truncate"
  }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('p', {
    staticClass: "txt txt--dark text-truncate-2"
  }, [_vm._v("게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다. 게시물 내용이 옵니다.")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-40px my-md-70px my-lg-90px",
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("라이더 이야기")])]), _c('riders-story-slide')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }